import useMiddlewareContext from '@/middleware/hooks/useMiddlewareContext';
import { getEmployerOnboardingStatus } from '@/gql/services/payroll.service';
import {
  getFromLocalStorage,
  removeFromLocalStorage,
} from '@/utils/localStorage';
import LocalStorageKey from '@/constants/LocalStorage';
import { OnboardingStatus } from '@/gql/generated';
import CachePolicy from '@/gql/CachePolicy';
import { isOnboardingCompleted } from '@/modules/Onboarding/hooks/useEmployerOnboarding';
import { WhiteListedRoutes } from '@/constants/Routes';
import { setHasStartedNewOnboarding } from '@/helpers/accessControl/hasStartedNewOnboarding';

enum Route {
  ONBOARDING_WIZARD_PATH = '/onboarding/employer',
}

let hasCompleted;

export default async () => {
  const { redirect, store, route } = useMiddlewareContext();
  const { currentUser } = store.getters;

  if (!currentUser) {
    return;
  }

  const isAlreadyInOnboardingWizard = route.path.includes(
    Route.ONBOARDING_WIZARD_PATH
  );

  if (isAlreadyInOnboardingWizard || hasCompleted || isOnboardingCompleted()) {
    return;
  }

  const { data: onboardingForm } = await getEmployerOnboardingStatus(
    {
      userId: currentUser._id,
    },
    { fetchPolicy: CachePolicy.NETWORK_ONLY }
  );

  setHasStartedNewOnboarding(true);

  hasCompleted = onboardingForm?.status === OnboardingStatus.Completed;
  const key = `${currentUser._company._id}:${LocalStorageKey.SHOULD_USE_NEW_EMPLOYER_ONBOARDING}`;

  if (hasCompleted) {
    removeFromLocalStorage(key);
  }

  const isInTestGroup = getFromLocalStorage(key) === 'true';

  const isWhiteListedRoute = Object.values(WhiteListedRoutes).some(
    (path) => path === route.path
  );

  if (
    (!hasCompleted || isInTestGroup) &&
    !(isAlreadyInOnboardingWizard || isWhiteListedRoute)
  ) {
    redirect(Route.ONBOARDING_WIZARD_PATH);
  }
};
