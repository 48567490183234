<template>
  <div>
    <GuidanceInfoModal
      v-if="
        (!hasSeenAllPanels && showGuidanceInfoModal) ||
        overrideAndShowGuidanceInfoModal
      "
    />

    <GuidanceDetailInfoModal
      v-if="
        (!hasSeenModal && showGuidanceDetailsInfoModal) ||
        overrideAndShowGuidanceDetailsInfoModal
      "
    />

    <AILaunchPadModal
      v-if="showLaunchPad"
      @exit="handleExitFromLaunchPad"
      @start-chat="handleStartChat"
      @close="showLaunchPad = false"
    />
  </div>
</template>

<script>
import AILaunchPadModal from '@/components/AILaunchPadModal.vue';
import useAIGuidanceModal from '@/modules/AI/hooks/useAIGuidanceModal';
import useAIConversation from '@/modules/AI/hooks/useAIConversation';
import useAIGuidanceDetailsModal from '@/modules/AI/hooks/useAIGuidanceDetailsModal';
import { ref, useRouter } from '@nuxtjs/composition-api';
import GuidanceInfoModal from '@/components/modals/GuidanceInfoModal.vue';
import GuidanceDetailInfoModal from '@/components/modals/GuidanceDetailInfoModal.vue';

export default {
  name: 'AIUpsellModals',
  components: {
    GuidanceInfoModal,
    GuidanceDetailInfoModal,
    AILaunchPadModal,
  },
  setup() {
    const router = useRouter();
    const {
      showGuidanceInfoModal,
      hasSeenAllPanels,
      overrideAndShowGuidanceInfoModal,
    } = useAIGuidanceModal();
    const {
      showGuidanceDetailsInfoModal,
      overrideAndShowGuidanceDetailsInfoModal,
      hasSeenModal,
    } = useAIGuidanceDetailsModal();
    const { handleSendMessage, isLoading } = useAIConversation();

    const showLaunchPad = ref(false);

    const sendToMarketing = () => {
      showGuidanceInfoModal.value = false;
      router.push('/hr-intelligence');
    };

    const handleExitFromLaunchPad = () => {
      showLaunchPad.value = false;
      router.push('/dashboard');
    };

    const handleStartChat = (asst, prompt) => {
      handleSendMessage(prompt, asst);
      showLaunchPad.value = false;
    };

    return {
      showGuidanceInfoModal,
      overrideAndShowGuidanceInfoModal,
      hasSeenAllPanels,
      showLaunchPad,
      handleExitFromLaunchPad,
      handleStartChat,
      isLoading,
      sendToMarketing,
      showGuidanceDetailsInfoModal,
      overrideAndShowGuidanceDetailsInfoModal,
      hasSeenModal,
    };
  },
};
</script>
