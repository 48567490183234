import FeatureFlags from '@/constants/FeatureFlags';
import { useApolloQuery } from '@/gql/apolloWrapper';
import {
  BillingInfo,
  PayrollPlanModel,
  ProductKey,
  SubscriptionModel,
  useGetBillingInfoQuery,
  useGetEligibleAddonsQuery,
} from '@/gql/generated';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import useCurrentPlan from '@/hooks/useCurrentPlan/useCurrentPlan';
import bam from '@/lib/bam';
import usePayrollOnboardingStatus from '@/modules/OnboardingWizard/hooks/usePayrollOnboardingStatus';
import isAdmin from '@/utils/isAdmin';
import { getLaunchDarklyFlag } from '@/utils/launchDarklyFlags';
import { computed, ref, Ref } from '@nuxtjs/composition-api';

let init;

const isLoading = ref(false);
const billingInfo = ref<BillingInfo>();
const currentTier: Ref<number | undefined> = ref();
const eligibleAddOns = ref<PayrollPlanModel[]>([]);
const isPayrollTierFeatureEnabled = ref(false);
const isPayrollCompany = ref(false);

const setupHook = (force) => {
  const { companyId } = useCurrentCompany();
  const { tierNumber } = useCurrentPlan();
  const { hasPayroll } = usePayrollOnboardingStatus();
  isPayrollCompany.value = hasPayroll.value;
  currentTier.value = tierNumber.value;
  if (isAdmin.value) {
    const { onResult } = useApolloQuery(
      useGetBillingInfoQuery,
      { input: { companyId: companyId.value } },
      { pending: isLoading },
      { force }
    );

    onResult(({ getBillingInfo: res }) => {
      billingInfo.value = res as BillingInfo;
    });

    const { onResult: onEligibleAddonsResult } = useApolloQuery(
      useGetEligibleAddonsQuery,
      {
        input: {
          companyId: companyId.value,
        },
      },
      {
        pending: isLoading,
      },
      { force }
    );

    onEligibleAddonsResult(({ getEligibleAddons: res }) => {
      eligibleAddOns.value = res?.payroll;
    });

    (async () => {
      isLoading.value = true;
      try {
        isPayrollTierFeatureEnabled.value = !!(await getLaunchDarklyFlag(
          FeatureFlags.OFFER_NEW_PAYROLL_PLANS
        ));
      } catch (error) {
        console.error('Error fetching feature flag:', error);
      } finally {
        isLoading.value = false;
      }
    })();
  }
};

const isSubActive = (sub: SubscriptionModel, productKey: ProductKey) =>
  sub.plan.productKey === productKey && sub.active;

const isPayrollLegacy = computed(() =>
  billingInfo.value?.addonSubscriptions.some((addon) =>
    isSubActive(addon, ProductKey.Payroll)
  )
);

const isPayrollBasic = computed(() =>
  billingInfo.value?.addonSubscriptions.some((addon) =>
    isSubActive(addon, ProductKey.PayrollBasic)
  )
);
const isPayrollPremium = computed(() =>
  billingInfo.value?.addonSubscriptions.some((addon) =>
    isSubActive(addon, ProductKey.PayrollPremium)
  )
);

const currentPayrollTier = computed(() => {
  if (isPayrollPremium.value) {
    return ProductKey.PayrollPremium;
  }
  if (isPayrollBasic.value) {
    return ProductKey.PayrollBasic;
  }

  return 'LEGACY';
});
const hasPayrollAddOn = computed(() =>
  billingInfo.value?.addonSubscriptions.some(
    (addon) =>
      isSubActive(addon, ProductKey.PayrollPremium) ||
      isSubActive(addon, ProductKey.PayrollBasic)
  )
);

const payrollAddOnPrice = computed(() => {
  const addOnPayrollSub = billingInfo.value?.addonSubscriptions.find(
    (addon) =>
      isSubActive(addon, ProductKey.PayrollPremium) ||
      isSubActive(addon, ProductKey.PayrollBasic)
  );

  return addOnPayrollSub?.plan.price;
});

const hasTimeAndAttendanceAddOn = computed(() =>
  billingInfo.value?.addonSubscriptions.some((addon) =>
    isSubActive(addon, ProductKey.TimeAttendance)
  )
);

const isEligibleForPayrollBasic = computed(
  () => currentTier.value && currentTier.value <= 3
);

const isElite = computed(
  () =>
    billingInfo.value?.coreSubscription?.plan.productKey === ProductKey.Elite ||
    billingInfo.value?.addonSubscriptions.some(
      (sub) => sub.plan.productKey === ProductKey.Elite
    )
);

const payrollTrackEvent = (event: string, data?: object) => {
  let payrollCustomerType = 'null';

  if (isPayrollBasic.value || isPayrollPremium.value) {
    payrollCustomerType = 'modern';
  } else if (isPayrollCompany.value) {
    payrollCustomerType = 'legacy';
  }

  bam.trackEvent(event, {
    ...data,
    payrollTier: currentPayrollTier.value,
    payrollCustomerType,
  });
};

const cycleEndDate = computed(
  () => billingInfo.value?.coreSubscription?.periodEndsAt
);

const useCompanyBillingInfo = (force = false) => {
  if (!init || force) {
    setupHook(force);
  }

  return {
    isLoading,
    billingInfo,
    eligibleAddOns,
    isPayrollTierFeatureEnabled,

    // computed
    isPayrollLegacy,
    isPayrollBasic,
    isPayrollPremium,
    currentPayrollTier,
    hasPayrollAddOn,
    isEligibleForPayrollBasic,
    isElite,
    cycleEndDate,
    payrollAddOnPrice,
    hasTimeAndAttendanceAddOn,

    // Methods
    payrollTrackEvent,
  };
};

export default useCompanyBillingInfo;
