import gql from 'graphql-tag';

export const ONBOARDING_FRAGMENT = gql`
  fragment OnboardingParts on CoreOnboardingData {
    discussionTopics
    discussionTopicDetails {
      topic
      detail
    }
    companyName
    companyLegalName
    companyType
    fein
    noFeinOrDoesNotKnow
    address {
      line1
      line2
      city
      state
      zip: zipCode
    }
    incorporatedInOtherState
    incorporatedState
    isOwner
    primaryContact
    willHaveAdditionalContact
    numOfEmployees
    numOfContractors
    needsHelpConvertingContractors
    hasRemoteEmployees
    remoteWorkerStates
    nextAction
    hasScheduledCall
    callEventId
    progress
  }
`;

export const BANK_FRAGMENT = gql`
  fragment BankAccountParts on BankAccount {
    id
    institutionName
    mask
    subType
    name
  }
`;

export const COMPANY_FRAGMENT = gql`
  fragment CompanyParts on Company {
    id
    checkId
    taxDocYears
    bankAccounts {
      ...BankAccountParts
    }
    status
    taxExemptStatus
    companyBenefits {
      id
      name
      effectiveStart
      effectiveEnd
    }
    deputyConnection {
      hasAccessToken
      canCancel
    }
    checkIndustry
    tosVersion
    signatoryEmail
    needsPayScheduleAssistance
    excludedStaffIds
    startDate
    paydays {
      approvalDeadline
      impactedByHoliday
      payday
    }
    paySchedules {
      id
      default
      firstPayday
      firstPeriodEnd
      name
      payFrequency
    }
    processingPeriod
    goals {
      id
      status
    }
    benefitOnboarding {
      status
    }
    checkOnboarding {
      id
      blockingSteps
      remainingSteps
      status
    }
    defaultPayScheduleDays {
      approvalDeadline
      impactedByWeekendOrHoliday
      payday
      periodEnd
      periodStart
    }
  }
  ${BANK_FRAGMENT}
`;

export const EMPLOYEE_WITH_PAYROLL_ITEMS_FRAGMENT = gql`
  fragment EmployeePayrollItemsParts on Employee {
    id
    checkOnboarding {
      id
      status
    }
    bankAccounts {
      ...BankAccountParts
    }
    paymentMethodPreference
    payrollItems {
      id
      netPay
      note
      grossPay
      noteVisible
      paymentMethod
      payroll {
        id
        payday
        periodEnd
        periodStart
        status
      }
    }
  }
  ${BANK_FRAGMENT}
`;

export const CONTRACTOR_WITH_PAYMENTS_FRAGMENT = gql`
  fragment ContractorPaymentParts on Contractor {
    id
    checkOnboarding {
      id
      status
    }
    bankAccounts {
      ...BankAccountParts
    }
    payments {
      id
      basePayRate
      checkNumber
      netPay
      grossPay
      note
      noteVisible
      payType
      paymentMethod
      version
      payroll {
        id
        payday
        periodEnd
        periodStart
        status
      }
    }
    paymentMethodPreference
  }
  ${BANK_FRAGMENT}
`;

export const TAX_DOCUMENTS_FRAGMENT = gql`
  fragment TaxDocumentParts on TaxDocument {
    id
    label
    quarter
    year
    label
    filedOn
    description
    jurisdiction
    employeeId
    contractorId
  }
`;

export const COMPANY_TAX_DOCUMENTS_FRAGMENT = gql`
  fragment CompanyTaxDocumentParts on Company {
    id
    taxDocuments {
      ...TaxDocumentParts
    }
  }
  ${TAX_DOCUMENTS_FRAGMENT}
`;

export const TOTALS_FRAGMENT = gql`
  fragment TotalParts on Totals {
    cashRequirement
    companyBenefits
    companyTaxes
    contractorGross
    contractorNet
    contractorReimbursements
    employeeBenefits
    employeeGross
    employeeNet
    employeeReimbursements
    employeeTaxes
    liability
    postTaxDeductions
  }
`;

export const ITEM_FRAGMENT = gql`
  fragment ItemParts on PayrollItem {
    id
    basePayRate
    hasDeletedWorkerRole
    grossPay
    employee {
      id
      payRate
      payType
      paymentMethodPreference
      remainingOnboardingSteps
      profile {
        id
        employeeType
        firstName
        lastName
        title
      }
    }
    earnings {
      id
      amount
      description
      minutes
      decimal
      source
      type
      workerRoleId
      subtype
    }
    reimbursements {
      id
      amount
    }
    benefits {
      benefit
      companyContributionAmount
      employeeContributionAmount
      description
    }
    checkNumber
    netPay
    note
    paymentMethod
    ptoBalanceHours
    sickBalanceHours
    reimbursements {
      amount
    }
    postTaxDeductions {
      amount
      description
      type
    }
    noteVisible
    taxes {
      amount
      description
      payer
      remittable
    }
    version
    payroll {
      id
      version
      totals {
        ...TotalParts
      }
    }
  }
  ${TOTALS_FRAGMENT}
`;

export const CONTRACTOR_FRAGMENT = gql`
  fragment ContractorParts on Contractor {
    id
    checkOnboarding {
      id
      blockingSteps
      status
    }
    payRate
    paymentMethodPreference
    payments {
      id
      basePayRate
      note
      noteVisible
      payType
      paymentMethod
      version
      entries {
        id
        amount
        minutes
        decimal
        type
        workerRoleId
      }
      payroll {
        id
        version
      }
    }
    remainingOnboardingSteps
    profile {
      id
      firstName
      lastName
      title
      employeeType
      email
      contractorType
      contractorBusinessName
    }
  }
`;
export const CONTRACTOR_MINIMAL_FRAGMENT = gql`
  fragment ContractorMinimalParts on Contractor {
    id
    checkOnboarding {
      id
      blockingSteps
      status
    }
    payRate
    paymentMethodPreference
    remainingOnboardingSteps
    profile {
      id
      firstName
      lastName
      title
      employeeType
      email
      contractorType
      contractorBusinessName
    }
  }
`;

export const PAYMENT_FRAGMENT = gql`
  fragment PaymentParts on ContractorPayment {
    id
    grossPay
    hasDeletedWorkerRole
    basePayRate
    checkNumber
    contractor {
      ...ContractorParts
    }
    entries {
      id
      amount
      minutes
      workerRoleId
      type
    }
    note
    noteVisible
    payType
    paymentMethod
    grossPay
    version
    payroll {
      id
      version
    }
  }
  ${CONTRACTOR_FRAGMENT}
`;
export const PAYMENT_MINIMAL_FRAGMENT = gql`
  fragment PaymentMinimalParts on ContractorPayment {
    id
    hasDeletedWorkerRole
    basePayRate
    checkNumber
    contractor {
      ...ContractorMinimalParts
    }
    entries {
      id
      amount
      minutes
      workerRoleId
      type
    }
    note
    noteVisible
    payType
    paymentMethod
    grossPay
    version
    payroll {
      id
      version
    }
  }
  ${CONTRACTOR_MINIMAL_FRAGMENT}
`;

export const PAGINATION_FRAGMENT = gql`
  fragment PaginationParts on PayrollPage {
    limit
    nextPage
    page
    prevPage
    totalCount
  }
`;
export const PAGINATION_ITEMS_FRAGMENT = gql`
  fragment PaginationItemParts on PayrollItemPage {
    limit
    nextPage
    page
    prevPage
    totalCount
  }
`;

export const PAGINATION_PAYMENTS_FRAGMENT = gql`
  fragment PaginationPaymentParts on ContractorPaymentPage {
    limit
    nextPage
    page
    prevPage
    totalCount
  }
`;

export const PAYROLL_PREVIEW_FRAGMENT = gql`
  fragment PayrollPreviewParts on Payroll {
    id
    approvalDeadline
    deletedAt
    draftLiability
    withdrawDate
    needsChecksEntered
    payday
    periodEnd
    periodStart
    quickbooksSyncAttempt {
      id
      status
      attemptedAt
    }
    status
    statusChangedAt
    totals {
      ...TotalParts
    }
    type
    version
    offCycleOptions {
      forceSupplementalWithholding
      applyBenefits
      applyPostTaxDeductions
    }
  }
  ${TOTALS_FRAGMENT}
`;

export const PAYROLL_WITH_ITEMS_FRAGMENT = gql`
  fragment PayrollWithItemsParts on Payroll {
    id
    approvedAt
    items {
      ...ItemParts
    }
    contractorPayments {
      ...PaymentParts
    }
    totals {
      ...TotalParts
    }
  }
  ${ITEM_FRAGMENT}
  ${PAYMENT_FRAGMENT}
  ${TOTALS_FRAGMENT}
`;

export const PAYROLL_FRAGMENT = gql`
  fragment PayrollParts on Payroll {
    id
    approvalDeadline
    deletedAt
    draftLiability
    withdrawDate
    items {
      ...ItemParts
    }
    contractorPayments {
      ...PaymentParts
    }
    needsChecksEntered
    payday
    periodEnd
    periodStart
    quickbooksSyncAttempt {
      id
      status
      attemptedAt
    }
    status
    statusChangedAt
    totals {
      ...TotalParts
    }
    type
    version
    offCycleOptions {
      forceSupplementalWithholding
      applyBenefits
      applyPostTaxDeductions
    }
  }
  ${ITEM_FRAGMENT}
  ${PAYMENT_FRAGMENT}
  ${TOTALS_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`;
export const PAYROLL_WITHOUT_NESTED_PAYMENTS_FRAGMENT = gql`
  fragment PayrollWithoutNestedPaymentsParts on Payroll {
    id
    approvalDeadline
    approvedAt
    deletedAt
    draftLiability
    withdrawDate
    items {
      ...ItemParts
    }
    contractorPayments {
      ...PaymentMinimalParts
    }
    needsChecksEntered
    payday
    periodEnd
    periodStart
    quickbooksSyncAttempt {
      id
      status
      attemptedAt
    }
    status
    statusChangedAt
    totals {
      ...TotalParts
    }
    type
    version
    offCycleOptions {
      forceSupplementalWithholding
      applyBenefits
      applyPostTaxDeductions
    }
  }
  ${ITEM_FRAGMENT}
  ${PAYMENT_MINIMAL_FRAGMENT}
  ${TOTALS_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`;

export const PAYROLL_INFO_FRAGMENT = gql`
  fragment PayrollInfoParts on Payroll {
    id
    approvalDeadline
    deletedAt
    draftLiability
    withdrawDate
    needsChecksEntered
    payday
    periodEnd
    periodStart
    quickbooksSyncAttempt {
      id
      status
      attemptedAt
    }
    status
    statusChangedAt
    totals {
      ...TotalParts
    }
    type
    version
    offCycleOptions {
      forceSupplementalWithholding
      applyBenefits
      applyPostTaxDeductions
    }
  }
  ${ITEM_FRAGMENT}
  ${TOTALS_FRAGMENT}
`;
export const PAYROLL_ITEMS_PAGINATED_FRAGMENT = gql`
  fragment PayrollItemsPaginatedParts on Payroll {
    id
    itemsPage(
      orderBy: $itemOrderBy
      limit: $itemLimit
      page: $itemPage
      advancedWhere: $itemAdvancedWhere
    ) {
      ...PaginationItemParts
      results {
        ...ItemParts
      }
    }
  }
  ${ITEM_FRAGMENT}
  ${PAGINATION_FRAGMENT}
  ${PAGINATION_ITEMS_FRAGMENT}
`;
export const PAYROLL_PAYMENTS_PAGINATED_FRAGMENT = gql`
  fragment PayrollPaymentsPaginatedParts on Payroll {
    id
    contractorPaymentsPage(
      orderBy: $paymentOrderBy
      limit: $paymentLimit
      page: $paymentPage
      advancedWhere: $paymentAdvancedWhere
    ) {
      ...PaginationPaymentParts
      results {
        ...PaymentMinimalParts
      }
    }
  }
  ${PAYMENT_MINIMAL_FRAGMENT}
  ${PAGINATION_FRAGMENT}
  ${PAGINATION_PAYMENTS_FRAGMENT}
`;

export const PAYROLL_LIST_FRAGMENT = gql`
  fragment PayrollListParts on Payroll {
    id
    approvalDeadline
    deletedAt
    draftLiability
    employeeCount
    contractorCount
    hasEmployeesWithMissingSSN
    withdrawDate
    needsChecksEntered
    payday
    periodEnd
    periodStart
    status
    statusChangedAt
    totals {
      ...TotalParts
    }
    type
    version
    offCycleOptions {
      forceSupplementalWithholding
      applyBenefits
      applyPostTaxDeductions
    }
  }
  ${TOTALS_FRAGMENT}
`;

export const ADDRESS_FRAGMENT = gql`
  fragment AddressParts on Address {
    id
    city
    country
    line1
    line2
    state
    zip
  }
`;

export const PROFILE_ADDRESS_FRAGMENT = gql`
  fragment ProfileAddressParts on ProfileAddress {
    id
    city
    country
    line1
    line2
    state
    zip
  }
`;

export const CORE_ADDRESS_FRAGMENT = gql`
  fragment CoreAddressParts on CoreAddress {
    city
    line1
    line2
    state
    zipCode
  }
`;

export const COMPANY_STATUS_FRAGMENT = gql`
  fragment CompanyStatusParts on Company {
    id
    status
    tosVersion
    excludedStaffIds
    checkIndustry
    benefitOnboarding {
      status
    }
    checkId
    benefitOnboarding {
      status
      targetEffectiveStart
    }
    checkOnboarding {
      id
      blockingSteps
      remainingSteps
      status
    }
    deputyConnection {
      hasAccessToken
      installId
      validatedAt
      edition
      canCancel
    }
  }
`;

/**
 * Define All client queries here
 */
export const GET_COMPANY_STATUS = gql`
  query GetCompanyStatus($id: ID!) {
    getCompany(id: $id) {
      ...CompanyStatusParts
    }
  }
  ${COMPANY_STATUS_FRAGMENT}
`;

export const GET_COMPANY_ONBOARDING_STATUS = gql`
  query GetCompanyOnboardingStatus($id: ID!) {
    getCompany(id: $id) {
      ...CompanyStatusParts
      employees {
        id
        checkId
        benefitOnboarding {
          status
        }
      }
      contractors {
        id
        checkId
        benefitOnboarding {
          status
        }
      }
    }
  }
  ${COMPANY_STATUS_FRAGMENT}
`;

export const GET_COMPANY = gql`
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      ...CompanyParts
    }
  }
  ${COMPANY_FRAGMENT}
`;

export const GET_COMPANY_HAS_RUN_PAYROLL = gql`
  query GetCompanyHasRunPayroll($id: ID!) {
    getCompany(id: $id) {
      status
      hasRunPayroll
    }
  }
`;

export const GET_COMPANY_LIFECYCLE_INFO = gql`
  query GetCompanyLifecycleInfo($id: ID!) {
    getCompany(id: $id) {
      ...CompanyParts
    }
    listPayroll(companyId: $id) {
      totalCount
    }
  }
  ${COMPANY_FRAGMENT}
`;

export const GET_COMPANY_PAYSCHEDULES = gql`
  query GetCompanyPaySchedules($id: ID!) {
    getCompany(id: $id) {
      ...CompanyParts
    }
    getCoreCompany(id: $id) {
      id
      payDayOfWeek
      payDayOfMonth
    }
  }
  ${COMPANY_FRAGMENT}
`;

export const GET_COMPANY_ESTIMATED_BENEFITS_COST = gql`
  query GetCompanyEstimatedBenefitsCost($zip: String!) {
    getCompanyEstimatedBenefitsCost(zip: $zip) {
      monthlyPremium
    }
  }
`;

export const CONTRACTOR_PROFILE_FRAGMENT = gql`
  fragment ContractorProfileParts on Contractor {
    id
    bankAccounts {
      ...BankAccountParts
    }
    checkId
    checkOnboarding {
      id
      remainingSteps
      status
    }
    createdAt
    payRate
    paymentMethodPreference
    payType
    profile {
      id
      contractorBusinessName
      firstName
      lastName
      title
      homeAddress {
        ...ProfileAddressParts
      }
    }
  }
  ${PROFILE_ADDRESS_FRAGMENT}
  ${BANK_FRAGMENT}
`;

export const EMPLOYEE_PROFILE_FRAGMENT = gql`
  fragment EmployeeProfileParts on Employee {
    id
    bankAccounts {
      ...BankAccountParts
    }
    checkId
    checkOnboarding {
      id
      remainingSteps
      status
    }
    createdAt
    payRate
    paymentMethodPreference
    payType
    profile {
      id
      contractorBusinessName
      firstName
      lastName
      title
      homeAddress {
        ...ProfileAddressParts
      }
    }
    workplaces {
      id
      active
      address {
        ...AddressParts
      }
      default
      name
    }
  }
  ${ADDRESS_FRAGMENT}
  ${PROFILE_ADDRESS_FRAGMENT}
  ${BANK_FRAGMENT}
`;

export const GET_COMPANY_STAFF = gql`
  query GetCompanyStaff($id: ID!) {
    getCompany(id: $id) {
      id
      contractors {
        ...ContractorProfileParts
      }
      employees {
        ...EmployeeProfileParts
      }
    }
  }
  ${CONTRACTOR_PROFILE_FRAGMENT}
  ${EMPLOYEE_PROFILE_FRAGMENT}
`;

export const GET_FULL_STAFF = gql`
  query GetFullStaff($id: ID!) {
    getCompany(id: $id) {
      id
      contractors {
        id
        checkId
        paymentMethodPreference
        checkOnboarding {
          remainingSteps
          status
        }
        createdAt
        workplaces {
          id
        }
      }
      employees {
        id
        checkId
        paymentMethodPreference
        checkOnboarding {
          remainingSteps
          status
        }
        createdAt
        workplaces {
          id
        }
      }
    }
    getCoreCompany(id: $id) {
      id
      employees {
        id
        active
        employment {
          id
          contractorBusinessName
          isContractor
          contractorType
          employeeType
          payRate
          payType
          # Getting a date error here
          startDate
          title
        }
        profile {
          id
          firstName
          lastName
          # Getting a null error here
          dob
          phone
          # Email causes ResolutionError
          email
          address {
            # Can we use the same address format as either core as payroll?
            # This gives us 'address1'. Should be either address or line1 to conform to current style
            # This also gives us zipCode instead of zip like the other two interfaces
            city
            line1
            line2
            state
            zip: zipCode
          }
        }
      }
    }
  }
`;

export const GET_COMPANY_POLICIES = gql`
  query GetCompanyPolicies($id: ID!) {
    getCoreCompany(id: $id) {
      id
      policies {
        id
        name
        icon
        archivedAt
      }
      documents {
        id
        name
      }
    }
  }
`;

export const GET_TAX_DOCUMENTS = gql`
  query TaxDocuments($id: ID!, $data: CompanyTaxDocumentsInput!) {
    getCompany(id: $id) {
      id
      taxDocuments(data: $data) {
        ...TaxDocumentParts
        companyId
        contractorId
        employeeId
      }
    }
  }
  ${TAX_DOCUMENTS_FRAGMENT}
`;
export const LIST_PAYROLL = gql`
  query ListPayroll(
    $companyId: ID
    $orderBy: PayrollOrderByInput
    $limit: Int
    $page: Int
    $advancedWhere: PayrollAdvancedWhereInput
  ) {
    listPayroll(
      companyId: $companyId
      orderBy: $orderBy
      limit: $limit
      page: $page
      advancedWhere: $advancedWhere
    ) {
      ...PaginationParts
      results {
        ...PayrollListParts
      }
    }
  }
  ${PAYROLL_LIST_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`;

export const LIST_PAYROLL_COUNT = gql`
  query ListPayrollCount($companyId: ID) {
    listPayroll(companyId: $companyId) {
      totalCount
    }
  }
`;
export const COMPANY_PAYROLL_COUNT = gql`
  query CompanyPayrollCount($companyId: ID!) {
    getCoreCompany(id: $companyId) {
      countPayroll(companyId: $companyId)
    }
  }
`;

export const GET_PAYROLL = gql`
  query getPayroll($id: ID!) {
    getPayroll(id: $id) {
      ...PayrollWithoutNestedPaymentsParts
    }
  }
  ${PAYROLL_WITHOUT_NESTED_PAYMENTS_FRAGMENT}
`;

export const GET_PAYROLL_INFO = gql`
  query GetPayrollInfo($id: ID!) {
    getPayroll(id: $id) {
      ...PayrollInfoParts
    }
  }
  ${PAYROLL_INFO_FRAGMENT}
`;
export const GET_PAYROLL_ITEMS_PAGINATED = gql`
  query GetPayrollItemsPaginated(
    $companyId: ID!
    $payrollId: ID!
    $itemOrderBy: PayrollItemOrderByInput
    $itemLimit: Int
    $itemPage: Int
    $itemAdvancedWhere: PayrollItemAdvancedWhereInput
  ) {
    getCoreCompany(id: $companyId) {
      getPayrollWithCompanyRoles(id: $payrollId) {
        ...PayrollItemsPaginatedParts
      }
    }
  }
  ${PAYROLL_ITEMS_PAGINATED_FRAGMENT}
`;
export const GET_PAYROLL_PAYMENTS_PAGINATED = gql`
  query GetPayrollPaymentsPaginated(
    $companyId: ID!
    $payrollId: ID!
    $paymentOrderBy: ContractorPaymentOrderByInput
    $paymentLimit: Int
    $paymentPage: Int
    $paymentAdvancedWhere: ContractorPaymentAdvancedWhereInput
  ) {
    getCoreCompany(id: $companyId) {
      getPayrollWithCompanyRoles(id: $payrollId) {
        ...PayrollPaymentsPaginatedParts
      }
    }
  }
  ${PAYROLL_PAYMENTS_PAGINATED_FRAGMENT}
`;
export const GET_EMPLOYEE = gql`
  query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      id
    }
  }
`;

export const GET_EMPLOYEE_TAX_DOCUMENTS = gql`
  query GetEmployeeTaxDocs($id: ID!) {
    getEmployee(id: $id) {
      id
      taxDocuments {
        ...TaxDocumentParts
      }
    }
  }
  ${TAX_DOCUMENTS_FRAGMENT}
`;

export const EMPLOYEE_STATUS_FRAGMENT = gql`
  fragment EmployeeStatusParts on Employee {
    id
    remainingOnboardingSteps
    checkOnboarding {
      id
      remainingSteps
      blockingSteps
    }
    checkId
    deputyId
    benefitOnboarding {
      status
    }
    createdAt
    paymentMethodPreference
  }
`;

export const GET_EMPLOYEE_STATUS = gql`
  query GetEmployeeStatus($id: ID!) {
    getEmployee(id: $id) {
      ...EmployeeStatusParts
    }
  }
  ${EMPLOYEE_STATUS_FRAGMENT}
`;

export const GET_CONTRACTOR_TAX_DOCUMENTS = gql`
  query GetContractorTaxDocs($id: ID!) {
    getContractor(id: $id) {
      id
      taxDocuments {
        ...TaxDocumentParts
      }
    }
  }
  ${TAX_DOCUMENTS_FRAGMENT}
`;

export const CONTRACTOR_STATUS_FRAGMENT = gql`
  fragment ContractorStatusParts on Contractor {
    id
    checkOnboarding {
      id
      remainingSteps
      blockingSteps
    }
    checkId
    createdAt
    paymentMethodPreference
  }
`;

export const GET_CONTRACTOR_STATUS = gql`
  query GetContractorStatus($id: ID!) {
    getContractor(id: $id) {
      ...ContractorStatusParts
    }
  }
  ${CONTRACTOR_STATUS_FRAGMENT}
`;

export const GET_WORKER_STATUS = gql`
  query GetWorkerStatus($id: ID!) {
    getEmployee(id: $id) {
      ...EmployeeStatusParts
    }
    getContractor(id: $id) {
      ...ContractorStatusParts
    }
  }
  ${EMPLOYEE_STATUS_FRAGMENT}
  ${CONTRACTOR_STATUS_FRAGMENT}
`;

export const GET_EMPLOYEE_PAYROLL_ITEMS = gql`
  query GetEmployeePayrollItems($id: ID!) {
    getEmployee(id: $id) {
      ...EmployeePayrollItemsParts
    }
  }
  ${EMPLOYEE_WITH_PAYROLL_ITEMS_FRAGMENT}
`;

export const GET_CONTRACTOR_PAYMENTS = gql`
  query GetContractorPayments($id: ID!) {
    getContractor(id: $id) {
      ...ContractorPaymentParts
    }
  }
  ${CONTRACTOR_WITH_PAYMENTS_FRAGMENT}
`;

export const LIST_WORKPLACE = gql`
  query listWorkplace($companyId: ID!) {
    listWorkplace(companyId: $companyId) {
      id
      address {
        ...AddressParts
      }
      default
      name
      active
    }
  }
  ${ADDRESS_FRAGMENT}
`;

export const GET_DEFAULT_PAY_SCHEDULE_DAYS = gql`
  query GetCompanyDefaultPayScheduleDays($id: ID!) {
    getCompany(id: $id) {
      id
      defaultPayScheduleDays(input: { limit: 100 }) {
        approvalDeadline
        impactedByWeekendOrHoliday
        payday
        periodEnd
        periodStart
      }
    }
  }
`;

export const CORE_USER_FRAGMENT = gql`
  fragment UserParts on CoreUser {
    id
    profile {
      id
      firstName
      lastName
      phone
      dob
      email
      avatarUrl
      address {
        ...CoreAddressParts
      }
    }
    employment {
      id
      payType
      employeeType
      payRate
      startDate
      isContractor
      title
      stateWorksIn
      contractorBusinessName
      contractorType
      workerRoles {
        id
        isPrimary
        payRate
        deletedAt
        payType
        companyRole {
          id
          title
        }
      }
    }
  }
  ${CORE_ADDRESS_FRAGMENT}
`;

export const GET_USER_EDIT_INFO = gql`
  query GetUserEditInfo($companyId: ID!, $userId: ID!) {
    getCoreUserById(id: $userId) {
      ...UserParts
    }
    getEmployee(id: $userId) {
      ...EmployeeStatusParts
      workplaces {
        id
      }
    }
    getContractor(id: $userId) {
      ...ContractorStatusParts
      workplaces {
        id
      }
    }
    getCompany(id: $companyId) {
      id
      excludedStaffIds
      checkId
      status
    }
  }
  ${CORE_USER_FRAGMENT}
  ${EMPLOYEE_STATUS_FRAGMENT}
  ${CONTRACTOR_STATUS_FRAGMENT}
`;

export const GET_COMPANY_ROLES = gql`
  query GetCompanyRoles($companyId: ID!) {
    getCoreCompany(id: $companyId) {
      id
      companyRoles {
        id
        title
        deletedAt
        createdAt
        workerRoles {
          id
          deletedAt
        }
      }
    }
  }
`;

export const GET_USER_ROLES = gql`
  query GetUserRoles($userId: ID!, $showDeleted: Boolean = false) {
    getCoreUserById(id: $userId) {
      id
      employment {
        id
        workerRoles(showDeleted: $showDeleted) {
          id
          isPrimary
          payRate
          deletedAt
          payType
          companyRole {
            id
            title
          }
        }
      }
    }
  }
`;

export const GET_MFA_ENROLLMENTS = gql`
  query GetMFAEnrollments($includeUncomfirmed: Boolean) {
    getMyMfaEnrollments(includeUnconfirmed: $includeUncomfirmed) {
      mfaType
    }
  }
`;

export const GET_TRAINING_USER_HISTORY = gql`
  query GetTrainingUserHistory(
    $data: UserQueryInput!
    $filter: UserTrainingFilterInput!
  ) {
    user(data: $data) {
      id
      training {
        history(filter: $filter) {
          id
          bundleId
          topicId
          bundleName
          completedAt
          enrolledAt
          startedAt
          name
          certificateDownloadUrl
          topics {
            id
          }
        }
      }
    }
  }
`;

/**
 * Define All client queries here
 */
export const GET_PAYROLL_ONBOARDING_INFO = gql`
  query GetPayrollOnboardingInfo($id: ID!) {
    getCompany(id: $id) {
      id
      checkIndustry
      processedPayrollsThisYear
      signatoryEmail
      signatoryFirstName
      signatoryLastName
      signatoryTitle
      onboardingOffersEmployeeBenefits
      onboardingHasGarnishmentsOrDeductions
      onboardingGarnishmentsOrDeductions
      onboardingGarnishmentsOrDeductionsOther
    }
    getCoreCompany(id: $id) {
      id
      name
      dba
      fein
      numOfFullTimeHourlyEmployees
      numOfFullTimeSalaryEmployees
      numOfPartTimeEmployees
      numOfContractors
      phone
      website
      address {
        line1
        line2
        city
        state
        zip: zipCode
      }
    }
  }
`;

export const GET_PAYROLL_FEIN_STATUS = gql`
  query GetPayrollFeinStatus($id: ID!) {
    getCompany(id: $id) {
      id
      feinVerification {
        id
        legalName
        federalEin
        status
        company
      }
      feinVerificationAttempts
    }
  }
`;

export const COMPANY_GOALS_FRAGMENT = gql`
  fragment CompanyGoalParts on Goal {
    category
    id
    name
    label
    required
    requirements {
      checkId
      completedAt
      documentOptions {
        id
        label
        name
      }
      id
      requirementType
      requirementTypeExt
      status
      uploadedFiles {
        id
        name
        type
      }
      requirementName
      errors {
        type
        label
        id
        description
      }
    }
    status
  }
`;

export const GET_TIMESHEETS = gql`
  query GetTimesheets($payrollId: ID!) {
    payroll(id: $payrollId) {
      id
      itemsPage {
        results {
          id
          timesheetHours {
            totalHours
            overtimeHours
            regularHours
            holidayHours
            sickHours
            ptoHours
          }
          employee {
            id
            profile {
              id
              firstName
              lastName
              title
              employeeType
            }
            payRate
            payType
            user {
              profile {
                id
                avatarUrl
              }
            }
          }
        }
      }
      contractorPaymentsPage {
        results {
          id
          timesheetHours {
            totalHours
            overtimeHours
            regularHours
            holidayHours
            sickHours
            ptoHours
          }
          contractor {
            id
            profile {
              id
              firstName
              lastName
              title
              employeeType
            }
            payRate
            payType
            user {
              profile {
                id
                avatarUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_TIMESHEETS_BY_ROLE = gql`
  query GetTimesheetsByRole($payrollId: ID!) {
    payroll(id: $payrollId) {
      id
      itemsPage {
        results {
          id
          timesheetHoursByRole {
            role {
              title
              payRate
            }
            totalHours
            overtimeHours
            regularHours
            holidayHours
            sickHours
            ptoHours
          }
          employee {
            id
            profile {
              id
              firstName
              lastName
              title
              employeeType
            }
            payRate
            payType
            user {
              profile {
                id
                avatarUrl
              }
            }
          }
        }
      }
      contractorPaymentsPage {
        results {
          id
          timesheetHoursByRole {
            role {
              title
              payRate
            }
            totalHours
            overtimeHours
            regularHours
            holidayHours
            sickHours
            ptoHours
          }
          contractor {
            id
            profile {
              id
              firstName
              lastName
              title
              employeeType
            }
            payRate
            payType
            user {
              profile {
                id
                avatarUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_COMPANY_GOALS = gql`
  query GetCompanyGoals($id: ID!) {
    getCompany(id: $id) {
      id
      goals {
        ...CompanyGoalParts
      }
    }
  }
  ${COMPANY_GOALS_FRAGMENT}
`;

export const GET_PAYROLL_TASKS = gql`
  query GetPayrollTasks($id: ID!) {
    getCompany(id: $id) {
      id
      goals {
        id
        status
        required
      }
    }
  }
`;

export const GET_PAYROLL_PAY_ITEMS = gql`
  query GetPayrollPayItems($companyId: ID!, $id: ID!) {
    getCoreCompany(id: $companyId) {
      id
      getPayroll(id: $id) {
        approvalDeadline
        contractorPayments {
          basePayRate
          checkNumber
          grossPay
          contractor {
            checkOnboarding {
              blockingSteps
              id
              status
            }
            id
            profile {
              contractorBusinessName
              contractorType
              employeeType
              firstName
              id
              lastName
              title
            }
            remainingOnboardingSteps
          }
          grossPay
          hasDeletedWorkerRole
          id
          payType
          payroll {
            id
            version
          }
          version
        }
        deletedAt
        draftLiability
        id
        items {
          grossPay
          checkNumber
          employee {
            id
            checkOnboarding {
              blockingSteps
              id
              status
            }
            payRate
            payType
            profile {
              employeeType
              firstName
              id
              lastName
              title
            }
            remainingOnboardingSteps
          }
          hasDeletedWorkerRole
          id
          netPay
          payroll {
            id
            version
          }
          sickBalanceHours
          version
        }
        status
        statusChangedAt
        type
        version
      }
    }
  }
`;

export const GET_PAYROLL_ITEM = gql`
  query GetPayrollItem($companyId: ID!, $payrollId: ID!, $id: ID!) {
    getCoreCompany(id: $companyId) {
      id
      getPayrollWithCompanyRoles(id: $payrollId) {
        id
        payrollItem(id: $id) {
          ...ItemParts
        }
      }
    }
  }
  ${ITEM_FRAGMENT}
`;

export const GET_CONTRACTOR_PAYMENT = gql`
  query GetContractorPayment($companyId: ID!, $payrollId: ID!, $id: ID!) {
    getCoreCompany(id: $companyId) {
      id
      getPayrollWithCompanyRoles(id: $payrollId) {
        id
        contractorPayment(id: $id) {
          ...PaymentParts
        }
      }
    }
  }
  ${PAYMENT_MINIMAL_FRAGMENT}
`;

export const CORE_TASK_FRAGMENT = gql`
  fragment CoreTaskParts on CoreTask {
    id
    completedAt
    completionStatus
    label
    name
    startedAt
    startedBy
    copy {
      cta
      estimated
      details {
        title
        items
      }
      confirmCta
      denyCta
    }
  }
`;

export const CORE_GOAL_FRAGMENT = gql`
  fragment CoreGoalParts on CoreGoal {
    id
    completedAt
    completionStatus
    label
    name
    taskNames
    updatedAt
    copy {
      description
      estimated
    }
  }
`;
export const GET_MY_COMPANY_GOALS = gql`
  query GetMyCompanyGoals($id: ID!) {
    getMyCompanyGoals {
      ...CoreGoalParts
      tasks {
        ...CoreTaskParts
      }
    }
    getCompany(id: $id) {
      id
      status
      onboardingOffersEmployeeBenefits
      onboardingHasGarnishmentsOrDeductions
      onboardingHasComplexScenario
      onboardingHasComplexTimeAttendanceScenario
      pastPayrollUsage
      total {
        missingEmployeeDetails
        onboardedEmployees
        payrollEmployees
      }
    }
  }
`;

export const GET_CORE_COMPANY_EMPLOYEES_FOR_ONBOARDING = gql`
  query GetCoreCompanyEmployeesForOnboarding($id: ID!) {
    coreCompany(id: $id) {
      id
      employees(active: true) {
        id
        profile {
          id
          firstName
          lastName
          email
          phone
        }
        employment {
          id
          isContractor
        }
        employee {
          id
          checkId
        }
        contractor {
          id
          checkId
        }
      }
    }
  }
`;

export const GET_COMPANY_PAYROLL_HISTORY = gql`
  query GetCompanyPayrollHistory($id: ID!) {
    getCompany(id: $id) {
      id
      pastPayrollUsage
    }
  }
`;

export const GET_PAYROLL_SIGNATORY_INFO = gql`
  query GetPayrollSignatoryInfo($id: ID!) {
    payrollCompany(id: $id) {
      id
      signatoryFirstName
      signatoryLastName
      signatoryTitle
      signatoryEmail
    }
  }
`;

export const GET_COMPANY_ADDRESSES = gql`
  query GetCompanyAddresses($id: ID!) {
    coreCompany(id: $id) {
      id
      address {
        line1
        line2
        city
        state
        zip: zipCode
      }
      mailingAddress {
        line1
        line2
        city
        state
        zip: zipCode
      }
      legalAddress {
        line1
        line2
        city
        state
        zip: zipCode
      }
    }
    listWorkplace(companyId: $id) {
      id
      default
      address {
        city
        country
        line1
        line2
        state
        zip
      }
    }
  }
`;

export const GET_BAMBEE_PLUS_INFO = gql`
  query GetBambeePlusInfo($data: PurchaseBambeePlusInput!, $companyId: ID!) {
    getBambeePlusInfo(input: $data) {
      id
      eligible
      status
      price
      onboardingDueDate
      hasExtendedOnboardingDeadline
      lateFeeImplementationPrice
    }
    coreCompany(id: $companyId) {
      id
      legalAddress {
        line1
        line2
        city
        state
        zip: zipCode
      }
      employees {
        id
        employee {
          id
          checkId
        }
      }
    }
    payrollCompany(id: $companyId) {
      id
      feinVerification {
        id
        status
      }
      pastPayrollUsage
      signatoryEmail
      signatoryFirstName
      signatoryLastName
      signatoryTitle
    }
    getMyCompanyGoals {
      ...CoreGoalParts
      tasks {
        ...CoreTaskParts
      }
    }
  }
  ${CORE_GOAL_FRAGMENT}
  ${CORE_TASK_FRAGMENT}
`;

export const GET_BASIC_COMPANY_INFO = gql`
  query GetBasicCompanyInfo($id: ID!) {
    coreCompany(id: $id) {
      id
      name
      phone
      industry
    }
  }
`;

export const GET_BAMBEE_PLUS_DEADLINE = gql`
  query GetBambeePlusDeadline($data: PurchaseBambeePlusInput!) {
    getBambeePlusInfo(input: $data) {
      id
      eligible
      status
      onboardingDueDate
      lateFeeImplementationPrice
    }
  }
`;

export const GET_DEPUTY_TOS_INFO = gql`
  query GetDeputyTosInfo($id: ID!) {
    getCompany(id: $id) {
      id
      deputyTosAcceptedAt
    }
  }
`;

export const GET_SIMPLE_POLICY_LIST = gql`
  query GetSimplePolicyList($companyId: ID!) {
    getCoreCompany(id: $companyId) {
      policies {
        id
        isCPD
        name
      }
    }
  }
`;

export const TOPIC_FRAGMENT = gql`
  fragment CourseTopicParts on CourseTopic {
    id
    price
    name
    description
    duration
    imageUrl
    states
    tags
    key
  }
`;

export const GET_TRAINING_COMPANY = gql`
  query GetTrainingCompany($data: GetCompanyInput!) {
    company(data: $data) {
      id
      availableProducts {
        id
        topics {
          ...CourseTopicParts
        }
        bundles {
          id
          global
          name
          price
          topics {
            ...CourseTopicParts
          }
        }
      }
      purchasedProducts {
        id
        bundles {
          id
          name
          description
          totalSeats
          unfilledSeats
          topics {
            id
            name
          }
        }
        topics {
          id
          name
          totalSeats
          unfilledSeats
          counts {
            id
            completed
            inProgress
            notStarted
          }
        }
      }
    }
  }
  ${TOPIC_FRAGMENT}
`;

export const GET_BILLING_HISTORY = gql`
  query GetBillingHistory($companyId: String!) {
    getBillingHistory(companyId: $companyId) {
      id
      amount
      attemptCount
      createdAt
      hostedUrl
      lastAttemptError
      pdfUrl
      statementType
      status
      productKey
      serviceKey
      modifiedAt
      stripeSubscriptionId
    }
  }
`;

export const GET_TRAINING_REQUESTS = gql`
  query GetTrainingRequests($data: RequestFilterInput!) {
    requests(data: $data) {
      approvalCriteria
      approverRoles
      companyId
      createdAt
      decisions {
        createdAt
        id
        requestId
        result
        role
        updatedAt
        userId
      }
      description
      expiresAt
      id
      rejectionCriteria
      requestedAt
      resourceId
      resourceType
      status
      updatedAt
      userId
      user {
        id
        profile {
          id
          avatarUrl
          firstName
          lastName
        }
        employment {
          id
          title
        }
      }
    }
  }
`;

export const GET_MY_TRAINING_REQUESTS = gql`
  query GetMyTrainingRequests($data: MyRequestFilterInput!) {
    myRequests(data: $data) {
      approvalCriteria
      approverRoles
      companyId
      createdAt
      decisions {
        createdAt
        id
        requestId
        result
        role
        updatedAt
        userId
      }
      description
      expiresAt
      id
      rejectionCriteria
      requestedAt
      resourceId
      resourceType
      status
      updatedAt
      userId
      user {
        id
        profile {
          id
          avatarUrl
          firstName
          lastName
        }
        employment {
          id
          title
        }
      }
    }
  }
`;

export const GET_TRAINING_RECOMMENDATIONS = gql`
  query GetTrainingRecommendations($data: RecommendationFilterInput!) {
    recommendations(data: $data) {
      id
      targets
      resourceType
      resourceId
      status
      scope
    }
  }
`;

export const GET_PENDING_HOTLINE_CALLS = gql`
  query GetPendingHotlineCalls {
    pendingHotlineCall {
      id
      status
      summary
    }
  }
`;

export const GET_SUBSCRIPTION_INFO = gql`
  query GetCompanySubscriptions($data: GetCompanySubscriptionsInput!) {
    companySubscriptions(data: $data) {
      productCode
      status
      stripePriceId
      stripeId
      name
      isDrp
    }
  }
`;

export const GET_PRICING_PACKAGES = gql`
  query GetPricingPackages($data: PricingPackageFilterInput!) {
    getPricingPackages(input: $data) {
      packages {
        plans {
          annual
          billingPeriod
          chatOnly
          price
          priceId
          setupFee
          setupFeeDiscount
          maxSize
          minSize
          monthlyPrice
          name
          tierNumber
          value
          status
        }
        type
      }
    }
  }
`;

export const GET_HOTLINE_CALLS = gql`
  query GetHotlineCalls {
    hotlineCalls {
      id
      createdAt
      callPlacedAt
      callDuration
      status
      summary
      agent {
        id
        avatarUrl
        name
      }
    }
  }
`;

export const GET_UPGRADE_OPTIONS = gql`
  query GetUpgradeOptions($companyId: String!) {
    coreUpgradeOptions(input: { companyId: $companyId }) {
      next {
        tax
        total
      }
      plan {
        maxSize
        minSize
        monthlyPrice
        price
        priceId
        productKey
        tierNumber
        billingPeriod
        value
      }
      prorationDate
      upcoming {
        remainingAmount
        tax
        total
        unusedAmount
        totalDue
      }
    }
  }
`;

export const GET_LATEST_PRICING_PACKAGES_FOR_TIER = gql`
  query getLatestPricingPackagesForTier(
    $tierNumber: Float!
    $getLatestPricingPackagesForTierInput: ActivePlanFilters!
  ) {
    getLatestPricingPackagesForTier(
      tierNumber: $tierNumber
      input: $getLatestPricingPackagesForTierInput
    ) {
      packages {
        type
        plans {
          name
          billingPeriod
          monthlyPrice
          productKey
          priceId
          value
          billingFeature
        }
      }
    }
  }
`;

// Only including count here for now, but we should expand to include all relevant info
export const GET_GENERAL_PAYROLL_COMPANY = gql`
  query GetGeneralPayrollCompany($companyId: ID!) {
    coreCompany(id: $companyId) {
      id
      employees {
        id
        active
        employee {
          id
          checkId
          payType
        }
        contractor {
          id
          checkId
          payType
        }
      }
    }
    listWorkplace(companyId: $companyId) {
      id
    }
    getCompany(id: $companyId) {
      id
      total {
        onboardedEmployees
        missingEmployeeDetails
        payrollEmployees
      }
    }
  }
`;

export const GET_CANCELLATION_REASONS = gql`
  query GetCancellationReasons {
    activeCancellationReasonGroups {
      code
      externalText
      reasons {
        code
        active
        externalText
      }
    }
  }
`;

export const PLAN_FRAGMENT = gql`
  fragment PlanParts on CorePlanModel {
    price
    priceId
    tierNumber
    value
    billingPeriod
    productKey
    monthlyPrice
    minSize
    maxSize
    name
    pause
  }
`;
export const GET_CANCELLATION_OFFERS = gql`
  query CancellationOffers {
    cancellationOffers {
      trialExtension
      downgradePlans {
        ...PlanParts
      }
      pausePlan {
        ...PlanParts
      }
      storagePlan {
        ...PlanParts
      }
    }
  }
  ${PLAN_FRAGMENT}
`;

export const GET_BILLING_INFO = gql`
  query GetBillingInfo($input: GetBillingInfoInput!) {
    getBillingInfo(input: $input) {
      addonSubscriptions {
        id
        active
        quantity
        periodEndsAt
        plan {
          annual
          value
          name
          productKey
          price
          volumeBased
          billingPeriod
          serviceKey
        }
      }
      coreSubscription {
        id
        cancellationRequested
        cancelsAt
        active
        trialEndsAt
        trialing
        periodEndsAt
        scheduledPlanChange {
          id
          effectiveOn
          newPlan {
            billingPeriod
            name
            productKey
          }
        }
        plan {
          maxSize
          minSize
          price
          productKey
          billingPeriod
          name
          employeeSizeMapping
        }
      }
      pause {
        id
        isPaused
        reactivatesOn
      }
    }
  }
`;

export const GET_UPCOMING_BILLING_CHANGES = gql`
  query GetNonCoreSubscriptionBillingChanges(
    $data: GetCompanyUpcomingBillingChangesInput!
  ) {
    getNonCoreSubscriptionBillingChanges(data: $data) {
      serviceKey
      productKey
      minSize
      maxSize
      upcomingMonthlyPrice
      upcomingYearlyPrice
    }
  }
`;

export const GET_BILLING_INFO_PAYMENT_METHODS = gql`
  query GetBillingInfoPaymentMethods($input: GetBillingInfoInput!) {
    getBillingInfo(input: $input) {
      id
      paymentMethods {
        id
        default
        expirationDate
        name
        nextAction
        status
        type
      }
    }
  }
`;

export const GET_PRODUCT_INTEREST = gql`
  query GetProductInterest($input: GetProductInterestInput!) {
    productInterest(input: $input) {
      id
      productKey
      productSubKey
    }
  }
`;

export const IS_EVENT_SCHEDULED_FOR_USER = gql`
  query IsEventScheduledForUser($email: String!, $eventSlug: String!) {
    isEventScheduledForUser(input: { email: $email, eventSlug: $eventSlug }) {
      email
      eventSlug
      isScheduled
    }
  }
`;

export const GET_EMPLOYER_ONBOARDING = gql`
  query FindOnboarding($data: CoreOnboardingKeys!, $id: ID!) {
    findOnboarding(data: $data) {
      id
      version
      status
      data {
        ...OnboardingParts
      }
    }
    coreCompany(id: $id) {
      id
      whatBroughtYouToBambee
    }
  }
  ${ONBOARDING_FRAGMENT}
`;

export const GET_EMPLOYER_ONBOARDING_STATUS = gql`
  query FindOnboardingStatus($data: CoreOnboardingKeys!) {
    findOnboarding(data: $data) {
      id
      status
      data {
        id
        progress
        callEventId
      }
    }
  }
`;

export const PRODUCT_FRAGMENT = gql`
  fragment ProductParts on Product {
    id
    name
    price
    key
    subscriptionPrice
    activeSubscription
    creditBalance
    tier
  }
`;

export const GET_COMPANY_PRODUCTS = gql`
  query getCompanyProducts($companyId: String!) {
    getCompanyProducts(data: { companyId: $companyId }) {
      ...ProductParts
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_AVAILABLE_PRODUCTS = gql`
  query GetAvailableProducts($data: GetAvailableProductsInput!) {
    getAvailableProducts(data: $data) {
      monthlyAllowance
      billingPeriod
      id
      price
      key
      name
      subscriptionPrice
      tier
      discountedPrice
    }
  }
`;

export const GET_COMPANY_CREDITS = gql`
  query GetCompanyCredits($data: GetCompanyCreditsInputV2!) {
    getCompanyCreditsV2(data: $data) {
      productKey
      consumedAt
    }
  }
`;

export const GET_ELIGIBLE_ADDONS = gql`
  query GetEligibleAddons($input: EligibleAddonsInput!) {
    getEligibleAddons(input: $input) {
      payroll {
        serviceKey
        productKey
        name
        value
        priceId
        price
        billingPeriod
        status
        minSize
        maxSize
        unitPriceId
        unitPrice
        version
      }
    }
  }
`;

export const GET_PAYROLL_STATUS = gql`
  query PayrollStatus($payrollCompanyId: ID!) {
    payrollCompany(id: $payrollCompanyId) {
      status
    }
  }
`;
