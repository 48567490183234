import useMiddlewareContext from './hooks/useMiddlewareContext';
import usePlanAccess from '@/hooks/usePlanAccess/usePlanAccess';

export default () => {
  const { route, redirect } = useMiddlewareContext();
  const { isBambeeLite } = usePlanAccess();

  // redirect each of these routes to /upgrade/[nameofpage]
  const paywalledRoutes = [
    '/benefits',
    // '/payroll',
    '/time-and-attendance',
    // '/report-cards',
    // '/requests',
  ];

  if (isBambeeLite.value) {
    paywalledRoutes.forEach((paywalledRoute) => {
      if (route.path === paywalledRoute) {
        redirect(`/upgrade${paywalledRoute}`);
      }
    });
  }
};
