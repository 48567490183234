<template>
  <header class="bg-white border-b border-base-300 flex items-center px-8 py-4">
    <BambeeLogo class="flex-shrink-0 lg:hidden mr-4" />
    <component
      :is="breadcrumbComponent"
      tag="h1"
      :variant="$screen.lg ? 'medium' : 'small'"
      :weight="$screen.lg ? 'regular' : 'regular'"
    >
      <portal-target name="crumbs" />
    </component>
    <WithLaunchDarklyFeatureFlag
      v-slot="{ flagValue, initialized }"
      :flag="isHrRequestInLeftNav"
    >
      <HeaderUserMenu v-if="flagValue" class="ml-auto" />
      <HeaderAdvisorPanel v-else-if="initialized" class="ml-auto" />
    </WithLaunchDarklyFeatureFlag>
    <HamburgerButton
      class="lg:hidden ml-3 relative z-10"
      :open="isNavOpen"
      @click="$emit('toggle-nav')"
    />
    <NavOverlay :is-nav-open="isNavOpen" />
  </header>
</template>

<script>
import BambeeLogo from '@/components/BambeeLogo/BambeeLogo.vue';
import NavOverlay from '@/components/default-layout/NavOverlay';
import FeatureFlags from '@/constants/FeatureFlags';
import { HamburgerButton, TypeBody, TypeDisplay } from '@bambeehr/pollen';
import { WithLaunchDarklyFeatureFlag } from '@bambeehr/vue-launch-darkly';

const HeaderAdvisorPanel = () =>
  import('@/components/default-layout/HeaderAdvisorPanel');

const HeaderUserMenu = () =>
  import('@/components/default-layout/HeaderUserMenu');

export default {
  components: {
    BambeeLogo,
    HamburgerButton,
    HeaderAdvisorPanel,
    HeaderUserMenu,
    NavOverlay,
    WithLaunchDarklyFeatureFlag,
  },
  props: {
    isNavOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    breadcrumbComponent() {
      return this.$screen.lg ? TypeDisplay : TypeBody;
    },
    isHrRequestInLeftNav() {
      return FeatureFlags.DISPLAY_HR_REQUESTS_IN_LEFT_NAV;
    },
  },
};
</script>
