import FeatureFlags from '@/constants/FeatureFlags';
import launchDarkly from '@bambeehr/vue-launch-darkly';
import { computed } from '@nuxtjs/composition-api';

const useHrGuidanceFeatureFlag = () => {
  const isHrGuidanceEnabled = computed<boolean>(
    () => launchDarkly.getFlags()[FeatureFlags.ENABLE_HR_GUIDANCE]
  );

  return {
    isHrGuidanceEnabled,
  };
};

export default useHrGuidanceFeatureFlag;
