





























import {
  ref,
  defineComponent,
  onMounted,
  computed,
  PropType,
  watch,
} from '@nuxtjs/composition-api';
import { BaseIcon } from '@bambeehr/pollen';

export default defineComponent({
  name: 'Accordion',
  components: {
    BaseIcon,
  },
  props: {
    // In order to allow for truly dynamic header slots we need to allow the consumer to set the icon's placement
    // This can't be done via slots because then it would be the burden of the consumer to animate the element (since adding in margin or padding would animate with the spacing, which looks weird)
    iconClasses: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    icon: {
      type: String as PropType<string>,
      required: false,
      default: 'chevronRight',
    },
    headerText: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    bodyText: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    reverseIcon: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    expanded: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const body = ref<HTMLElement | null>(null);
    const bodyHeight = ref<number>(0);
    const isExpanded = ref<boolean>(props.expanded);

    watch(
      () => props.expanded,
      (newValue) => {
        if (isExpanded.value !== newValue) {
          isExpanded.value = newValue;
        }
      }
    );

    const stateHeight = computed<number>(() =>
      isExpanded.value ? bodyHeight.value : 0
    );

    const formattedIconClasses = computed<string>(() => {
      const baseClasses = props.reverseIcon ? 'right-1' : 'left-1';
      const rotateClass = isExpanded.value ? 'rotate-90' : 'rotate-0';

      return `${baseClasses} h-4 w-4 ${props.iconClasses} ${rotateClass}`;
    });

    const bodyTransitionClasses = computed<string>(
      () => `opacity-${isExpanded.value ? '100' : '0'}`
    );

    onMounted(() => {
      const resizeObserver = new ResizeObserver(function (
        observerEntry: ResizeObserverEntry[]
      ) {
        bodyHeight.value = observerEntry[0]?.contentRect?.height || 0;
      });

      resizeObserver.observe(body.value as HTMLElement);
    });

    function toggleExpandedState() {
      isExpanded.value = !isExpanded.value;
      emit('input', isExpanded.value);
    }

    return {
      body,
      bodyHeight,
      formattedIconClasses,
      isExpanded,
      stateHeight,
      toggleExpandedState,
      bodyTransitionClasses,
    };
  },
});
