






























































































































































import {
  ref,
  reactive,
  computed,
  watch,
  defineComponent,
  useStore,
  useRouter,
} from '@nuxtjs/composition-api';
import {
  TypeOverline,
  AnnularThrobber,
  TypeBody,
  ModalDialog,
  BaseButton,
  TextInput,
} from '@bambeehr/pollen';
import { PayrollStaffResponse } from '@/modules/payroll/components/StaffDrawer/types/staffDrawer';
import { PayTypes } from '@/modules/payroll/constants/payroll';
import StaffTile from '@/modules/payroll/components/StaffTile/StaffTile.vue';
import getUnifiedStaffDrawerList from '@/modules/payroll/components/StaffDrawer/getUnifiedStaffDrawerList';
import EmptyDashboardList from '@/modules/payroll/components/DashboardTile/EmptyDashboardList';
import useNotifications from '@bambeehr/use-notifications';
import usePayrollService from '@/modules/payroll/hooks/usePayrollService';
import GroupSelector from '@/modules/payroll/components/GroupSelector/GroupSelector.vue';
import { useGetFullStaffQuery } from '@/gql/generated';
import CachePolicy from '@/gql/CachePolicy';
import { useApolloQuery } from '@/gql/apolloWrapper';
import usePayrollOnboardingStatus from '@/modules/OnboardingWizard/hooks/usePayrollOnboardingStatus';
import useAddStaffWizard from '@/modules/StaffManagement/components/AddStaffWizard/useAddStaffWizard';
import AnnouncementBanner from '@/components/banners/AnnouncementBanner';
import FinishLine from '@/modules/payroll/assets/FinishLine';
import usePlanAccess from '@/hooks/usePlanAccess/usePlanAccess';

// We're getting null in this list on occassion, we're investigating the root cause in BAMBO-2006
export const cleanList = (list) => list.filter((i) => !!(i?.id || i?._id));

export default defineComponent({
  name: 'WorkerPay',
  components: {
    AnnouncementBanner,
    AnnularThrobber,
    EmptyDashboardList,
    TypeBody,
    TypeOverline,
    StaffTile,
    ModalDialog,
    BaseButton,
    GroupSelector,
    TextInput,
    FinishLine,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { companyId } = store.getters;
    const { addSuccess, addError } = useNotifications();
    const isLoading = ref(true);
    const { showWizard } = useAddStaffWizard();
    const { isOnboarding } = usePayrollOnboardingStatus();
    const { hasLiteOrLower } = usePlanAccess();

    const staffList = ref<any[]>([]);
    const { sendStaffOnboardingInvite } = usePayrollService();

    const getStaff = (shouldUseCache: boolean = true, callback?: Function) => {
      const { onResult: onFullStaffResult } = useApolloQuery(
        useGetFullStaffQuery,
        {
          id: companyId,
        },
        {
          pending: shouldUseCache ? isLoading : undefined,
        },
        undefined,
        {
          fetchPolicy: shouldUseCache
            ? CachePolicy.CACHE_FIRST
            : CachePolicy.NETWORK_ONLY,
        }
      );

      onFullStaffResult(({ getCompany, getCoreCompany }) => {
        staffList.value = getUnifiedStaffDrawerList(
          cleanList(getCoreCompany.employees.filter((i) => i.active)),
          [
            ...cleanList(getCompany?.employees || []),
            ...cleanList(getCompany?.contractors || []),
          ]
        );

        if (callback) {
          callback();
        }
      });
    };

    getStaff();

    const staffFilter = ref('');

    const companyStaff = reactive<PayrollStaffResponse>({
      employees: [],
      contractors: [],
    });

    const setFlags = (item) => {
      const itemId = item.id || item._id;
      const isContractor = item.profile.contractor;

      const isEmailEligible = item?.checkOnboarding?.remainingSteps?.length;

      return {
        ...item,
        includesPayrollInfo: true,
        isContractor,
        isEmailEligible,
        checkOnboarding: item.checkOnboarding,
        checkId: item.checkId,
        paymentMethodPreference: item.paymentMethodPreference,
      };
    };

    const getFullName = (profile) =>
      profile.contractor_business_name?.toLowerCase() ||
      `${profile.first_name?.toLowerCase()} ${profile.last_name?.toLowerCase()}`;

    const sortStaff = (a, b) =>
      getFullName(a.profile)
        .toLowerCase()
        .localeCompare(getFullName(b.profile).toLowerCase());

    const staffMatchesFilter = (staff) =>
      getFullName(staff.profile).includes(
        staffFilter.value.trim().toLowerCase()
      ) ||
      staff.profile?.role
        ?.toLowerCase()
        ?.includes(staffFilter.value.trim().toLowerCase());

    const salaryEmployeeList = computed(() =>
      staffList.value
        .filter(
          (s) =>
            !s.profile.contractor &&
            s.profile.type.toLowerCase() === PayTypes.SALARY.toLowerCase()
        )
        .filter(staffMatchesFilter)
        .map((i) => setFlags(i))
        .sort(sortStaff)
    );
    const hourlyEmployeeList = computed(() =>
      staffList.value
        .filter(
          (s) =>
            !s.profile.contractor &&
            // Negative check against SALARY bc owners don't have a paytype
            // on init so we default to hourly
            s.profile.type.toLowerCase() !== PayTypes.SALARY.toLowerCase()
        )
        .filter(staffMatchesFilter)
        .map((i) => setFlags(i))
        .sort(sortStaff)
    );
    const contractorList = computed(() =>
      staffList.value
        .filter((s) => s.profile.contractor)
        .filter(staffMatchesFilter)
        .map((i) => setFlags(i))
        .sort(sortStaff)
    );

    const hasNoStaff = computed(
      () =>
        !salaryEmployeeList.value.length &&
        !hourlyEmployeeList.value.length &&
        !contractorList.value.length
    );

    const emailList = reactive({
      contractorIds: [] as string[],
      employeeIds: [] as string[],
    });

    const nonOnboardedStaffList = computed(() =>
      [contractorList.value, salaryEmployeeList.value, hourlyEmployeeList.value]
        .flat()
        .filter((s) => s.isEmailEligible)
    );

    const allStaffAreOnboarded = computed(
      () => !nonOnboardedStaffList.value.length
    );

    const staffIsSelected = (staffId: string, isContractor: boolean): boolean =>
      (isContractor ? emailList.contractorIds : emailList.employeeIds).includes(
        staffId
      );

    const toggleSelectedStaff = ({
      isContractor,
      isSelected,
      id,
    }: {
      isContractor: boolean;
      isSelected: boolean;
      id: string;
    }) => {
      const list = isContractor
        ? emailList.contractorIds
        : emailList.employeeIds;

      if (isSelected) {
        list.push(id);

        return;
      }

      list.splice(list.indexOf(id), 1);
    };

    const resetEmailList = () => {
      emailList.contractorIds = [];
      emailList.employeeIds = [];
    };
    const totalCount = computed(() => nonOnboardedStaffList.value.length);
    const selectedCount = computed(
      () => emailList.contractorIds.length + emailList.employeeIds.length
    );
    const partialStaffSelected = computed(
      () => totalCount.value !== selectedCount.value
    );

    const selectAll = () => {
      if (
        (!!selectedCount.value && partialStaffSelected.value) ||
        !selectedCount.value
      ) {
        Object.assign(emailList, {
          contractorIds: nonOnboardedStaffList.value
            .filter((s) => s.isContractor)
            .map((s) => s.id),
          employeeIds: nonOnboardedStaffList.value
            .filter((s) => !s.isContractor)
            .map((s) => s.id),
        });
      } else {
        resetEmailList();
      }
    };

    const showEmailConfirmation = ref(false);
    const toggleEmailConfirmation = () => {
      showEmailConfirmation.value = !showEmailConfirmation.value;
    };

    const sendEmailInvite = () => {
      const { data, error } = sendStaffOnboardingInvite(emailList, {});

      const unwatchSendInvite = watch([data, error], ([res, err]) => {
        if (err) {
          addError(err);
        }

        if (res) {
          addSuccess('Invitations sent.');
          resetEmailList();
        }
        unwatchSendInvite();
      });

      showEmailConfirmation.value = false;
    };

    const emailConfirmationActions = {
      primary: {
        label: 'Send Invites',
        handler: sendEmailInvite,
      },
      secondary: {
        label: 'Cancel',
        handler: toggleEmailConfirmation,
      },
    };

    const editWorker = (workerId: string) => {
      router.push(`/employee/edit/${workerId}?return=PAYROLL`);
    };

    const sendToAddStaffWizard = () => {
      showWizard();
      router.push('/employee');
    };

    return {
      editWorker,
      allStaffAreOnboarded,
      companyId,
      companyStaff,
      contractorList,
      emailConfirmationActions,
      emailList,
      hasNoStaff,
      hourlyEmployeeList,
      isLoading,
      nonOnboardedStaffList,
      partialStaffSelected,
      salaryEmployeeList,
      selectAll,
      selectedCount,
      sendEmailInvite,
      showEmailConfirmation,
      staffFilter,
      staffIsSelected,
      toggleEmailConfirmation,
      toggleSelectedStaff,
      totalCount,
      sendToAddStaffWizard,
      isOnboarding,
      hasLiteOrLower,
    };
  },
});
