const threadIdToken = '[[threadId]]';

const APIRoutes = {
  HRI: `/core/ai/assistant/v1/chat`,
  STATUS: `/core/ai/assistant/v1/chat/${threadIdToken}/status`,
  CHAT: `/core/ai/assistant/v1/chat/${threadIdToken}`,
};

interface HRIInput {
  content?: string;
  threadId?: string;
  assistant?: string;
  files?: File[];
  skipRun?: boolean;
  hidden?: boolean;
}

interface MessageContent {
  type: string;
  text: {
    value: string;
    annotations: any[];
  };
}

interface MessageMetadata {
  assistant?: string;
  assistantId?: string;
  hidden?: string;
}

interface Artifact {
  correctiveAction?: {
    employeeId?: string;
    employeeName?: string;
    html?: string;
    isFinal?: boolean;
    status?: string;
    type?: string;
    id?: string;
    title?: string;
    caseId?: string;
  };
  jobDescription?: {
    employeeId?: string;
    html?: string;
    id?: string;
    title?: string;
    assignments: string[];
    caseId?: string;
  };
  policy?: {
    id?: string;
    html?: string;
    title?: string;
    confidence: number;
    caseId?: string;
  };
  incidents?: {
    id?: string;
    html?: string;
    title: string;
    caseId?: string;
  };
}

export enum MessageRole {
  Assistant = 'assistant',
  User = 'user',
}

export interface Message {
  id: string;
  content: MessageContent[];
  role: MessageRole;
  metadata: MessageMetadata;
}

export interface HRIResponse {
  threadId: string;
  assistant: string;
  assistantId: string;
  runId: string;
  next: {
    message: string;
    context: string;
    options: string[];
    sample: string;
    acceptFileUpload?: boolean;
  };
  messages: Message[];
  artifact?: Artifact;
}

export interface ChatStatus {
  loadingMessages: string[];
  updatedAt: Date;
  threadId?: string;
}

export const getChatStatus = async (axios, threadId: string) => {
  const { data } = await axios.get(
    APIRoutes.STATUS.replace(threadIdToken, threadId)
  );

  return {
    ...data,
    updatedAt: new Date(),
  };
};

export const getChat = async (axios, threadId: string) => {
  const { data } = await axios.get(
    APIRoutes.CHAT.replace(threadIdToken, threadId)
  );

  return data;
};

export const sendMessage = async (
  axios,
  input: HRIInput
): Promise<HRIResponse> => {
  const { data } = await axios.post(APIRoutes.HRI, input, {
    // Extended timeout to account for long running or multiple network requests with openAi
    timeout: 300000, // 5 minutes in milliseconds
  });

  return data;
};

export default null;
