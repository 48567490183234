const { constants } = require('@bambeehr/pollen');

const { inset, spacing, transitionDuration, borderRadius, ...rest } =
  constants.theme.default;

// Exclude some styles from purging
const hrScoreColors = ['error', 'warning', 'gingerrr', 'success', 'mosaic'];
const safelistHrScore = hrScoreColors.flatMap((color) => [
  `border-${color}`,
  `text-${color}`,
]);

// List of custom colors to safelist
const customColors = [
  'lite',
  'elite',
  'dedicated',
  'payrollBasic',
  'payrollPremium',
];

// Safelist color utilities
const safelistColors = customColors.flatMap((color) => [
  `bg-${color}`,
  `border-${color}`,
  `text-${color}`,
]);

module.exports = {
  important: true,
  theme: {
    ...rest,
    extend: {
      inset,
      borderWidth: {
        '.5': '2px',
      },
      spacing: {
        ...spacing,
        18: '4.5rem',
      },
      fontFamily: {
        poppins: "'Poppins','sans serif'",
      },
      borderRadius,
      transitionDuration,
      minHeight: (theme) => ({
        ...theme('spacing'),
      }),
      maxWidth: {
        xxs: '19rem',
      },
      colors: {
        lite: '#681CBA',
        dedicated: '#4E1976',
        guidance: '#BB54C7',
        elite: '#FF484B',
        payrollBasic: '#008685',
        payrollPremium: '#dbb275',
        globalPurple: '#610BEF',
        bolt: '#8a85f9',
        'bolt-dark': '#5A53C8',
      },
      backgroundImage: {
        'ai-gradient':
          'linear-gradient(90deg, #C686FF 0%, #6E85F7 50%, #C686FF 100%)',
        'lite-gradient': 'linear-gradient(30deg, #681CBA, #681CBA)',
        'guidance-gradient':
          'linear-gradient(30deg, #C176E7, #6362DA, #9855D9, #BB54C7, #C554DC)',
        'guidance-gradient-2':
          'linear-gradient(0deg, rgba(182, 115, 247, 0.50) 0%, rgba(182, 115, 247, 0.50) 100%), linear-gradient(0deg, rgba(68, 17, 68, 0.50) 0%, rgba(68, 17, 68, 0.50) 100%), conic-gradient(from 177deg at 50% 50%, #C176E7 0deg, #6362DA 84.59999978542328deg, #9855D9 145.80000042915344deg, #BB54C7 217.80000686645508deg, #DC5489 334.80000257492065deg)',
        'elite-gradient':
          'linear-gradient(30deg, #FF484B, #FE7381, #F35285, #B549F3, #32115D)',
        'elite-gradient-2':
          'linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%), linear-gradient(215deg, #FE565E -12.15%, #FE8692 38.02%, #F352DB 110.99%, #B549F3 166.86%, #32115D 210.18%)',
        'hri-gradient':
          'linear-gradient(30deg, #681CBA, #6362DA, #9855D9, #BB54C7, #B549F3, #F352DB, #FE8692, #FE565E)',
      },
    },
  },
  variants: {
    extend: {
      translate: ['group-hover'],
    },
  },
  plugins: [],
  purge: {
    // Learn more on https://tailwindcss.com/docs/controlling-file-size/#removing-unused-css
    enabled: process.env.NODE_ENV === 'production',
    content: [
      './src/modules/**/*.vue',
      './src/components/**/*.vue',
      './src/layouts/**/*.vue',
      './src/modules/**/*.vue',
      './src/pages/**/*.vue',
      './src/plugins/**/*.js',
    ],
    safelist: [
      // Dynamically assigned / HR Score
      ...safelistHrScore,
      // Custom colors
      ...safelistColors,
    ],
  },
};
