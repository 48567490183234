import { CallKinds } from '@/constants/calendly';
import serializeObject from '@/utils/serializeObject';

export type valueof<T> = T[keyof T];

const PRIMARY_COLOR = '68478d';

export type CallKindsKeys = keyof typeof CallKinds;

export interface GenerateCalendlyParametersOptions {
  userId: string;
  companyId: string;
  callId?: string;
  userName: string;
  companyName: string;
  email: string;
  phone?: string;
  callKind?: typeof CallKinds[CallKindsKeys];
  source?: string;
  sfRecordId?: string;
  answers?: string[]; // Need this array [company name, guest phone #] to pre-populate fields
}

export function generateCalendlyParameters({
  userId,
  companyId,
  callId,
  userName,
  companyName,
  email,
  phone,
  callKind,
  source,
  sfRecordId,
  answers, // Need this array [company name, guest phone #] to pre-populate fields
}: GenerateCalendlyParametersOptions): string {
  const standardOverrides = {
    hide_event_type_details: 1,
    hide_landing_page_details: 1,
    hide_gdpr_banner: 1,
  };

  const utmContent = [
    companyId,
    userId,
    source,
    callKind,
    userId, // In this context, This field {scheduled_by_user_id} will always match userId
    '', // related previous Calendly event UUID, none in this context
    sfRecordId,
  ].join('|');

  /**
   * https://calendly.com/business-managers/my-business-manager?primary_color=68478d&name=James%20Lee&email=james%2B1157%40x.com&utm_campaign=James%20Lee's%20Company&utm_content=63bf1492cb903977b12e29bb%7C63bf1492cb9039b8462e29b6%7CvdXyRKT4P%7Cself-service-hr-strategy&utm_medium=3232342342&utm_term=www%7Clocal&hide_event_type_details=1&hide_landing_page_details=1&hide_gdpr_banner=1&embed_domain=localhost%3A3004&embed_type=Inline
   */
  const params = {
    email,
    name: userName,
    primary_color: PRIMARY_COLOR,
    utm_campaign: '',
    utm_content: utmContent,
    utm_medium: phone,
    utm_term: `app|${process.env.ENV}`,
    ...standardOverrides,
  };

  if (answers && answers.length) {
    answers.forEach((a, index) => {
      params[`a${index + 1}`] = a;
    });
  }

  return serializeObject(params);
}
