































































import { defineComponent, useRouter } from '@nuxtjs/composition-api';
import GlowModal from '@/components/GlowModal.vue';
import FancyButton from './FancyButton.vue';
import useAIGuidanceModal from '@/modules/AI/hooks/useAIGuidanceModal';
import { HrGuidanceInfoModalEvents } from '@/modules/AI/constants/events';
import bam from '@/lib/bam';

export default defineComponent({
  name: 'AIInfoModal',
  components: {
    GlowModal,
    FancyButton,
  },
  props: {
    currentCopy: {
      type: Object,
      required: true,
    },
    totalPanelCount: {
      type: Number,
      required: true,
    },
    currentProgress: {
      type: Number,
      required: true,
    },
  },
  emits: ['primary', 'secondary', 'close'],
  setup(_, { emit }) {
    const handlePrimary = () => {
      emit('primary');
    };
    const handleSecondary = () => {
      emit('secondary');
    };

    return {
      handlePrimary,
      handleSecondary,
    };
  },
});
