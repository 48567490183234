export enum Step {
  // Old
  DISCUSSION_TOPICS = 'DISCUSSION_TOPICS',
  ABOUT_COMPANY = 'ABOUT_COMPANY',
  BUSINESS_LOCATION = 'BUSINESS_LOCATION',
  ABOUT_YOU = 'ABOUT_YOU',
  ABOUT_EMPLOYEES = 'ABOUT_EMPLOYEES',
  SELECT_FIRST_ACTION = 'SELECT_FIRST_ACTION',
  SCHEDULE_CALL = 'SCHEDULE_CALL',
  REQUEST_DETAILS = 'REQUEST_DETAILS',

  // New
  ASSESSMENT = 'ASSESSMENT',
  OWNER_CHECK = 'OWNER_CHECK',
  ADD_PIN = 'ADD_PIN',
  ADD_OWNER = 'ADD_OWNER',
  // The below tabs should be expanded into as many tabs as needed to show all of the education pages, they should each be given proper names and enums
  ACKNOWLEDGE_1 = 'ACKNOWLEDGE_1',
  ACKNOWLEDGE_2 = 'ACKNOWLEDGE_2',
  ACKNOWLEDGE_3 = 'ACKNOWLEDGE_3',
  ACKNOWLEDGE_4 = 'ACKNOWLEDGE_4',
  ACKNOWLEDGE_5 = 'ACKNOWLEDGE_5',
  HANDS_FREE = 'HANDS_FREE',
  ACTIVATE_1 = 'ACTIVATE_1',
  ACTIVATE_2 = 'ACTIVATE_2',
  ACTIVATE_3 = 'ACTIVATE_3',
  ACTIVATE_4 = 'ACTIVATE_4',
  ACTIVATE_5 = 'ACTIVATE_5',
  ACTIVATE_6 = 'ACTIVATE_6',
  SET_UP_1 = 'SET_UP_1',
  SET_UP_2 = 'SET_UP_2',
  SET_UP_3 = 'SET_UP_3',
  SET_UP_4 = 'SET_UP_4',
  SET_UP_EMPLOYEES = 'SET_UP_EMPLOYEES',
  ADD_DOCUMENTS = 'ADD_DOCUMENTS',
  // The below tabs should be expanded into as many tabs as needed to show all of the permissions pages, they should each be given proper names and enums
  PERMISSIONS = 'PERMISSIONS',
  CASE_MANAGEMENT = 'CASE_MANAGEMENT',
  // Transitions
  TRANSITION_TO_SETUP = 'TRANSITION_TO_SETUP',
  TRANSITION_TO_EMPLOYEE = 'TRANSITION_TO_EMPLOYEE',
  TRANSITION_TO_FILES = 'TRANSITION_TO_FILES',

  // Non-mvp
  SET_UP_MANAGERS = 'SET_UP_MANAGERS',
  SET_UP_EXECUTIVES = 'SET_UP_EXECUTIVES',
  SET_UP_OVERSIGHT = 'SET_UP_OVERSIGHT',
  SUMMARY = 'SUMMARY',
}

export enum ParentStep {
  ASSESSMENT = 'Assessment',
  ACKNOWLEDGE = 'Acknowledge',
  SET_UP = 'Set Up',
  ACTIVATE = 'Activate',
  ADD_ON = 'Add On',
}

const defaultRoute = [
  Step.ASSESSMENT,
  Step.ACKNOWLEDGE_1,
  Step.ACKNOWLEDGE_2,
  Step.ACKNOWLEDGE_3,
  Step.ACKNOWLEDGE_4,
  Step.ACKNOWLEDGE_5,
  Step.TRANSITION_TO_SETUP,
  Step.SET_UP_1,
  Step.SET_UP_2,
  Step.SET_UP_3,
  Step.SET_UP_4,
  Step.TRANSITION_TO_EMPLOYEE,
  Step.SET_UP_EMPLOYEES,
  Step.TRANSITION_TO_FILES,
  Step.ADD_DOCUMENTS,
  Step.ACTIVATE_1,
  Step.ACTIVATE_2,
  Step.ACTIVATE_3,
  Step.ACTIVATE_4,
  Step.ACTIVATE_5,
  Step.ACTIVATE_6,
];

export const journeys = {
  ER: {
    // If we need different flows for our various plans, they are broken out here:
    LITE: defaultRoute,
    DEDICATED: defaultRoute,
    ELITE: [...defaultRoute, Step.CASE_MANAGEMENT],
  },
  EE: {},
};

export const pathDefaults = {
  [Step.ASSESSMENT]: {
    slug: 'overview',
    hideBack: true,
    id: Step.ASSESSMENT,
    label: 'Acknowledge',
    isFullPage: true,
    progressOrder: 1,
  },
  [Step.ACKNOWLEDGE_1]: {
    slug: 'expert-hr-guidance',
    hideBack: true,
    id: Step.ACKNOWLEDGE_1,
    label: 'Acknowledge',
    isFullPage: true,
    progressOrder: 2,
    parentId: Step.ASSESSMENT,
  },
  [Step.ACKNOWLEDGE_2]: {
    slug: 'bionic-hr',
    hideBack: true,
    id: Step.ACKNOWLEDGE_2,
    label: 'Acknowledge 2',
    isFullPage: true,
    progressOrder: 3,
    parentId: Step.ASSESSMENT,
  },
  [Step.ACKNOWLEDGE_3]: {
    slug: 'neural-compliance',
    hideBack: true,
    id: Step.ACKNOWLEDGE_3,
    label: 'Acknowledge 3',
    isFullPage: true,
    progressOrder: 4,
    parentId: Step.ASSESSMENT,
  },
  [Step.ACKNOWLEDGE_4]: {
    slug: 'hands-on-hr',
    hideBack: true,
    id: Step.ACKNOWLEDGE_4,
    label: 'Acknowledge 4',
    isFullPage: true,
    progressOrder: 5,
    parentId: Step.ASSESSMENT,
  },
  [Step.ACKNOWLEDGE_5]: {
    slug: 'cognitive-employee-support',
    hideBack: true,
    id: Step.ACKNOWLEDGE_5,
    label: 'Acknowledge 5',
    isFullPage: true,
    progressOrder: 6,
    parentId: Step.ASSESSMENT,
  },
  [Step.TRANSITION_TO_SETUP]: {
    slug: 'transition-to-setup',
    hideBack: true,
    id: Step.TRANSITION_TO_SETUP,
    label: 'Set-up',
    isFullPage: true,
    progressOrder: 7,
  },
  [Step.SET_UP_1]: {
    slug: 'enable-bionic-hr',
    hideBack: true,
    id: Step.SET_UP_1,
    label: 'Set-up',
    progressOrder: 8,
    nextLabel: 'Skip',
    nextButtonProps: {
      level: '2',
    },
    parentId: Step.TRANSITION_TO_SETUP,
  },
  [Step.SET_UP_2]: {
    slug: 'enable-neural-compliance',
    hideBack: true,
    id: Step.SET_UP_2,
    label: 'Set-up 2',
    progressOrder: 9,
    parentId: Step.TRANSITION_TO_SETUP,
    nextLabel: 'Skip',
    nextButtonProps: {
      level: '2',
    },
  },
  [Step.SET_UP_3]: {
    slug: 'enable-hands-on-hr',
    hideBack: true,
    id: Step.SET_UP_3,
    label: 'Set-up 3',
    progressOrder: 10,
    parentId: Step.TRANSITION_TO_SETUP,
    nextLabel: 'Skip',
    nextButtonProps: {
      level: '2',
    },
  },
  [Step.SET_UP_4]: {
    slug: 'enable-cognitive-employee-support',
    hideBack: true,
    id: Step.SET_UP_4,
    label: 'Set-up 4',
    progressOrder: 11,
    parentId: Step.TRANSITION_TO_SETUP,
    nextLabel: 'Skip',
    nextButtonProps: {
      level: '2',
    },
  },
  [Step.TRANSITION_TO_EMPLOYEE]: {
    slug: 'transition-to-employee',
    hideBack: true,
    id: Step.TRANSITION_TO_EMPLOYEE,
    label: 'Transition to Employee',
    isFullPage: true,
    progressOrder: 12,
    parentId: Step.TRANSITION_TO_SETUP,
  },
  [Step.SET_UP_EMPLOYEES]: {
    slug: 'set-up-employees',
    hideBack: true,
    id: Step.SET_UP_EMPLOYEES,
    label: 'Set-up Employees',
    progressOrder: 13,
    parentId: Step.TRANSITION_TO_SETUP,
  },
  [Step.TRANSITION_TO_FILES]: {
    slug: 'transition-to-files',
    hideBack: true,
    id: Step.TRANSITION_TO_FILES,
    label: 'Transition to Files',
    isFullPage: true,
    progressOrder: 14,
    parentId: Step.TRANSITION_TO_SETUP,
  },
  [Step.ADD_DOCUMENTS]: {
    slug: 'add-documents',
    hideBack: true,
    id: Step.ADD_DOCUMENTS,
    label: 'Add Documents',
    progressOrder: 15,
    parentId: Step.TRANSITION_TO_SETUP,
  },
  [Step.ACTIVATE_1]: {
    slug: 'enable-manager-coaching',
    hideBack: true,
    id: Step.ACTIVATE_1,
    label: 'Activate',
    isFullPage: true,
    nextLabel: 'Skip',
    nextButtonProps: {
      level: '2',
    },
    progressOrder: 16,
  },
  [Step.ACTIVATE_2]: {
    slug: 'enable-employee-issue-tracking',
    hideBack: true,
    id: Step.ACTIVATE_2,
    label: 'Activate 2',
    nextLabel: 'Skip',
    isFullPage: true,
    nextButtonProps: {
      level: '2',
    },
    progressOrder: 17,
    parentId: Step.ACTIVATE_1,
  },
  [Step.ACTIVATE_3]: {
    slug: 'enable-neural-intelligence-and-compliance-tracking',
    hideBack: true,
    id: Step.ACTIVATE_3,
    label: 'Activate 3',
    nextLabel: 'Skip',
    isFullPage: true,
    nextButtonProps: {
      level: '2',
    },
    progressOrder: 18,
    parentId: Step.ACTIVATE_1,
  },
  [Step.ACTIVATE_4]: {
    slug: 'let-bambee-handle-hr-for-you',
    hideBack: true,
    id: Step.ACTIVATE_4,
    label: 'Activate 4',
    nextLabel: 'Skip',
    isFullPage: true,
    nextButtonProps: {
      level: '2',
    },
    progressOrder: 19,
    parentId: Step.ACTIVATE_1,
  },
  [Step.ACTIVATE_5]: {
    slug: 'enable-hr-action-plans',
    hideBack: true,
    id: Step.ACTIVATE_5,
    label: 'Activate 5',
    nextLabel: 'Skip',
    isFullPage: true,
    nextButtonProps: {
      level: '2',
    },
    progressOrder: 20,
    parentId: Step.ACTIVATE_1,
  },
  [Step.ACTIVATE_6]: {
    slug: 'enable-bambee-company-rollout',
    hideBack: true,
    id: Step.ACTIVATE_6,
    label: 'Activate 6',
    nextLabel: 'Skip',
    isFullPage: true,
    nextButtonProps: {
      level: '2',
    },
    progressOrder: 21,
    parentId: Step.ACTIVATE_1,
  },
  [Step.CASE_MANAGEMENT]: {
    slug: 'case-management',
    hideBack: true,
    id: Step.CASE_MANAGEMENT,
    label: 'Add-on',
    isFullPage: true,
    progressOrder: 22,
    nextLabel: 'Skip',
    nextButtonProps: {
      level: '2',
    },
  },
};
