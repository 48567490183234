


































































































































































































import GlowModal from '@/components/GlowModal.vue';
import PlanUpgradeModalTab from '@/components/modals/PlanUpgrade/PlanUpgradeModalTab.vue';
import usePlanUpgrade, {
  PlanKeys,
  PlanLabels,
} from '@/hooks/usePlanUpgrade/usePlanUpgrade';
import {
  TypeBody,
  BaseDivider,
  BaseButton,
  BlockSelector,
  BaseIcon,
  BaseLink,
  AnnularThrobber,
} from '@bambeehr/pollen';
import {
  ref,
  computed,
  ComputedRef,
  defineComponent,
} from '@nuxtjs/composition-api';
import { formatCurrency } from '@/utils/currency';
import bam from '@/lib/bam';
import HrGuidanceEvents from '@/modules/HrGuidance/constants/events';
import useCurrentUser from '@/hooks/useCurrentUser';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import useCurrentPlan from '@/hooks/useCurrentPlan/useCurrentPlan';
import usePlanAccess from '@/hooks/usePlanAccess/usePlanAccess';
import { BillingFeature } from '@/gql/generated';

const planInfoConfig = {
  [PlanKeys.LITE]: {
    planName: PlanLabels.LITE,
    key: PlanKeys.LITE,
    textColorClass: 'text-lite',
    textColorGradientClass: 'text-gradient-lite',
    planHeader:
      '<b>Dedicated HR Manager</b> + Fast HR Direction & Lite-Best Practice Support',
    title: 'Quick HR Clarity. Essential Guidance. Plus, plan the next step.',
    featuresHeader:
      'Gain Clarity, Best Practice Guidance & Staff Platform Access',
    features: [
      '<span class="text-base-900 font-semibold">10-minute Essential Guidance</span> calls with your Dedicated HR Manager, plus platform access for quick advice.',
      '<span class="text-base-900 font-semibold">Basic Access to Guidance Direct.</span> Our powerful HR Manager platform at your fingertips',
      '<span class="text-base-900 font-semibold">Gather employee insights,</span> track feedback and manage staff with simple, yet powerful HR tools',
      '<span class="text-base-900 font-semibold">Access core HR Policies</span> + equip managers with tools for corrective action & performance management',
    ],
    blurb:
      '<b>Bambee Lite delivers more</b> than competitors charging more than $199+—at a fraction of the cost. Nearly everything in their plans is included in our starter option, giving you expert-backed HR support without the hefty price tag. Confidence, compliance, and clarity—affordably.',
  },
  // TEOTODO: update this once Guidance is available
  [PlanKeys.GUIDANCE]: {
    planName: PlanLabels.GUIDANCE,
    key: PlanKeys.GUIDANCE,
    textColorClass: 'text-guidance',
    textColorGradientClass: 'text-gradient-guidance',
    planHeader: 'Dedicated HR Manager + <b>Comprehensive Human Resources</b>.',
    title:
      'Comprehensive Guidance, HR Action Plans, Powerful HR Technology, only on Bambee.',
    featuresHeader:
      'Guidance, Staff Platform & HR Intelligence to Boost Compliance.',
    features: [
      '<span class="text-base-900 font-semibold">Dedicated HR Manager</span> guides HR issues with seven-step method for world-class guidance, every time.',
      '<span class="text-base-900 font-semibold">Direct Guidance Pro.</span> The powerful platform Bambee HR Managers use for guidance, now available to you, for real-time advice',
      '<span class="text-base-900 font-semibold">HR Action Plans simplify complexity.</span> Step-by-step to meet our guidance excellence standard.',
      '<span class="text-base-900 font-semibold">Craft custom HR policies to your goals.</span> Turn into action with HR Practice Plans to boost performance.',
      '<span class="text-base-900 font-semibold">HR Intelligence for accuracy and speed</span> - North America’s most powerful AI-driven HR technology, seamlessly built-in.',
      '<span class="text-base-900 font-semibold">Policy Radar.</span> Scans company activity, finds policy gaps, recommends fix to you & HR Manager',
    ],
    blurb:
      '<b>Competitive Advantage. Bambee delivers what competitors can’t.</b> Others offer template libraries—generic, one-size-fits-all policies stamped with their brand. <b>Bambee builds HR around you.</b> With HR Intelligence, the most powerful HR AI in North America, every solution is crafted to fit your culture, your goals, your business.',
  },

  [PlanKeys.ELITE]: {
    planName: PlanLabels.ELITE,
    key: PlanKeys.ELITE,
    textColorClass: 'text-elite',
    textColorGradientClass: 'text-gradient-elite',
    planHeader:
      'Dedicated HR Manager + <b>Employee Insights & Proactive HR Execution.</b>',
    title:
      'Full Service HR Execution. We Don’t Just Tell You What to Do—We Do It for You + All Guidance Plan Benefits.',
    featuresHeader:
      'All Comprehensive Guidance Plan benefits Included. Plus benefits exclusive to Elite:',
    features: [
      '<span class="text-base-900 font-semibold">Delegate HR tasks, like terminations</span> and tough conversations — Bambee handles HR Action Plans, delivering corrective action and more',
      '<span class="text-base-900 font-semibold">Craft HR policies and custom handbooks</span> with dynamic updates—plus, we roll out policies for you to ensure compliance and success.',
      '<span class="text-base-900 font-semibold">Bambee manages employee talks</span> and coaching, while managers get fast, on-demand HR help and support',
      '<span class="text-base-900 font-semibold">Bambee tracks sentiment, issues,  resolves them proactively</span> — no waiting. Preventing disputes, boosting performance, + Full report.',
      '<span class="text-base-900 font-semibold">Your HR Manager directly mediates conflicts,</span> drives resolutions, and provides a written completion report.',
      '<span class="text-base-900 font-semibold">Solve big strategic issues</span> with HR Series—set goal, string three calls together. <br>Limited on other plans.',
      '<span class="text-base-900 font-semibold">HR Guidance Direct Max — HR management made easy.</span> Get direct comprehensive guidance, action plans in real time. Then delegate HR implementation to Bambee—all built into the platform.',
      '<span class="text-base-900 font-semibold">HR Intelligence, North America’s most powerful HR AI,</span> powers self-writing corrective actions, Policy Radar to detect policy gaps—all seamlessly integrated.',
    ],
    blurb:
      '<b>Bambee is the first and only HR company to offer this level of service.</b> With Elite, we don’t just guide—we execute. From handling HR tasks, managing employee issues and enforcing policies, <b>Elite delivers pro-active, preventative, full-service HR execution</b> powered by HR Intelligence the most advanced HR AI in North America. No HR company on the market even comes close.',
  },
};

export default defineComponent({
  name: 'PlanUpgradeModal',
  components: {
    GlowModal,
    PlanUpgradeModalTab,
    TypeBody,
    BaseDivider,
    BaseButton,
    BlockSelector,
    BaseIcon,
    BaseLink,
    AnnularThrobber,
  },
  props: {
    threadId: {
      type: String,
      required: false,
      default: '',
    },
    doNotRefreshPage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['elite-purchased'], // TODO: update. This shouldn't be so specific. maybe we emit something like "purchase" with an argument of elite
  setup(props, { emit }) {
    const isLoading = ref(false);
    const showDetails = ref(false);

    const {
      mapPlanToPricePoints,
      mapPlanToMonthlyPrice,
      subscribeToPlan,
      subscribeToEliteAddon,
    } = usePlanUpgrade();
    const { currentUser } = useCurrentUser();
    const { companyId } = useCurrentCompany();
    const { currentPlan } = useCurrentPlan();
    const { currentPlan: currentPlanKey } = usePlanAccess();

    // TEOTODO: make this more dynamic in the future, but default to viewing Elite by defautl for now
    const currentTab = ref();
    const annualSelected = ref(false);

    // @ts-ignore
    const sortedTabs: ComputedRef<PlanKeys[]> = computed(() => {
      const order = [PlanKeys.LITE, PlanKeys.GUIDANCE, PlanKeys.ELITE];

      const tabs = Object.keys(mapPlanToPricePoints.value ?? {})
        .filter((key) => order.includes(key as PlanKeys))
        .sort((a, b) => {
          return order.indexOf(a as PlanKeys) - order.indexOf(b as PlanKeys);
        });
      currentTab.value = tabs[tabs.length - 1];

      return tabs;
    });

    const currentBillingFeature = computed(() => {
      return mapPlanToMonthlyPrice.value[currentTab.value].billingFeature;
    });

    const viewingCurrentlySubscribedPlan = computed(() => {
      return planInfoConfig[currentTab.value].key === currentPlanKey.value;
    });

    const handleAnnualSelected = (e) => {
      annualSelected.value = e;
    };

    const onUpgradeClicked = () => {
      isLoading.value = true;

      // current paradigm is to always subscribe to monthly and then have a
      // BM reach out to upgrade them to annual if they are interested
      if (annualSelected.value) {
        bam.trackEvent(HrGuidanceEvents.ANNUAL_PLAN_REQUESTED, {
          companyId: companyId.value,
          userId: currentUser.value?._id,
          currentPlanId: currentPlan.value?.value,
          newPlanId: mapPlanToMonthlyPrice.value[currentTab.value].planId,
        });
      }
      try {
        if (
          mapPlanToMonthlyPrice.value[currentTab.value].billingFeature ===
          BillingFeature.Addon
        ) {
          subscribeToEliteAddon(props.doNotRefreshPage as boolean);
          emit('elite-purchased');
        } else {
          subscribeToPlan(
            `${mapPlanToMonthlyPrice.value[currentTab.value].planId}`,
            props.threadId as string
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    const toggleDetails = (show) => {
      showDetails.value = show;
    };

    return {
      mapPlanToPricePoints,
      annualSelected,
      handleAnnualSelected,
      currentTab,
      currentBillingFeature,
      sortedTabs,
      planInfoConfig,
      isLoading,
      formatCurrency,
      onUpgradeClicked,
      showDetails,
      toggleDetails,
      viewingCurrentlySubscribedPlan,
      BillingFeature,
    };
  },
});
