import { ProductKey } from '@/gql/generated';

// How should the product or feature be displayed?
export enum Vis {
  SHOW = 'SHOW',
  HIDE = 'HIDE',
  UPSELL = 'UPSELL',
  UPCHARGE = 'UPCHARGE',
}

// Products which can be sold a la carte
export enum Product {
  PAYROLL = 'PAYROLL',
  TIME_AND_ATTENDANCE = 'TIME_AND_ATTENDANCE',
  BENEFITS = 'BENEFITS',
  TRAINING = 'TRAINING',
  NECTAR = 'NECTAR',
  MANAGED_SERVICES = 'MANAGED_SERVICES',
}

// Grouping of all upsellable things
export const Upsell = {
  Package: ProductKey,
  Product,
};

export interface Feature {
  display: Vis;
  upsell?: Product | ProductKey;
  hideForT0?: boolean;
}

// This generics approach may not end up working when we get this information from GQL
// Will need to cross that bridge when we come to it.
export interface PlanConfig<T> {
  dashboard?: T;
  hrRequests?: T;
  chat?: T;
  staff?: T;
  policies?: T;
  cabinet?: T;
  training?: T;
  reportCards?: T;
  voices?: T;
  payroll?: T;
  timeAndAttendance?: T;
  benefits?: T;
  insurance?: T;
  hotline?: T;
  conversations?: T;
  jobDescriptions?: T;
  myTeam?: T;
  managedServices?: T;
}

export interface Config {
  [ProductKey.Pause]: PlanConfig<Feature>;
  [ProductKey.Storage]: PlanConfig<Feature>;
  [ProductKey.Lite]: PlanConfig<Feature>;
  [ProductKey.Core]: PlanConfig<Feature>;
  [ProductKey.Plus]: PlanConfig<Feature>;
  [ProductKey.Elite]: PlanConfig<Feature>;
}

// Default config.
export const config: Config = {
  [ProductKey.Pause]: {
    // -- Navigation -- //
    dashboard: {
      display: Vis.SHOW,
    },
    hrRequests: {
      display: Vis.UPSELL,
      upsell: Upsell.Package.Core,
    },
    // currently called "conversations"
    chat: {
      display: Vis.HIDE,
    },
    staff: {
      display: Vis.UPSELL,
      upsell: Upsell.Package.Core,
    },
    policies: {
      display: Vis.UPSELL,
      upsell: Upsell.Package.Core,
    },
    cabinet: {
      display: Vis.SHOW,
    },
    training: {
      display: Vis.HIDE,
    },
    reportCards: {
      display: Vis.UPSELL,
      upsell: Upsell.Package.Core,
    },
    voices: {
      display: Vis.UPSELL,
      upsell: Upsell.Package.Plus,
    },
    payroll: {
      display: Vis.UPSELL,
      upsell: Upsell.Package.Plus,
    },
    timeAndAttendance: {
      display: Vis.UPSELL,
      upsell: Upsell.Package.Plus,
    },
    benefits: {
      display: Vis.UPSELL,
      upsell: Upsell.Package.Core,
    },
    insurance: {
      display: Vis.HIDE,
    },

    // -- New Features -- //
    // dashboard type view for hotline customers
    hotline: {
      display: Vis.HIDE,
    },
    // phone / chat history
    conversations: {
      display: Vis.HIDE,
    },

    // -- Non-navigation features -- //
    jobDescriptions: {
      display: Vis.HIDE,
    },
    myTeam: {
      display: Vis.HIDE,
    },
  },
  [ProductKey.Storage]: {
    // -- Navigation -- //
    dashboard: {
      display: Vis.SHOW,
    },
    hrRequests: {
      display: Vis.UPSELL,
      upsell: Upsell.Package.Core,
    },
    // currently called "conversations"
    chat: {
      display: Vis.HIDE,
    },
    staff: {
      display: Vis.UPSELL,
      upsell: Upsell.Package.Core,
    },
    policies: {
      display: Vis.HIDE,
    },
    cabinet: {
      display: Vis.SHOW,
    },
    training: {
      display: Vis.HIDE,
    },
    reportCards: {
      display: Vis.UPSELL,
      upsell: Upsell.Package.Core,
    },
    voices: {
      display: Vis.UPSELL,
      upsell: Upsell.Package.Plus,
    },
    payroll: {
      display: Vis.UPSELL,
      upsell: Upsell.Package.Plus,
    },
    timeAndAttendance: {
      display: Vis.UPSELL,
      upsell: Upsell.Package.Plus,
    },
    benefits: {
      display: Vis.UPSELL,
      upsell: Upsell.Package.Plus,
    },
    insurance: {
      display: Vis.HIDE,
    },

    // -- New Features -- //
    // dashboard type view for hotline customers
    hotline: {
      display: Vis.HIDE,
    },
    // phone / chat history
    conversations: {
      display: Vis.HIDE,
    },

    // -- Non-navigation features -- //
    jobDescriptions: {
      display: Vis.HIDE,
    },
    myTeam: {
      display: Vis.HIDE,
    },
  },
  [ProductKey.Lite]: {
    // -- Navigation -- //
    dashboard: {
      display: Vis.SHOW,
    },
    hrRequests: {
      display: Vis.SHOW,
    },
    // currently called "conversations"
    chat: {
      display: Vis.HIDE,
    },
    staff: {
      display: Vis.UPSELL,
      upsell: Upsell.Package.Core,
    },
    policies: {
      display: Vis.UPSELL,
      upsell: Upsell.Package.Core,
    },
    cabinet: {
      display: Vis.SHOW,
    },
    training: {
      display: Vis.UPSELL,
      upsell: Upsell.Package.Core,
    },
    reportCards: {
      display: Vis.SHOW,
    },
    voices: {
      display: Vis.UPSELL,
      upsell: Upsell.Package.Plus,
    },
    payroll: {
      display: Vis.UPSELL,
      upsell: Upsell.Package.Plus,
    },
    timeAndAttendance: {
      display: Vis.UPSELL,
      upsell: Upsell.Package.Plus,
    },
    benefits: {
      display: Vis.UPSELL,
      upsell: Upsell.Package.Core,
    },
    insurance: {
      display: Vis.HIDE,
    },
    managedServices: {
      display: Vis.HIDE,
    },

    // -- New Features -- //
    // dashboard type view for hotline customers
    // TODO: Deprecate completely
    hotline: {
      display: Vis.HIDE,
    },
    // phone / chat history
    conversations: {
      display: Vis.HIDE,
    },

    // -- Non-navigation features -- //
    jobDescriptions: {
      display: Vis.HIDE,
    },
    myTeam: {
      display: Vis.SHOW,
    },
  },
  [ProductKey.Core]: {
    // -- Navigation -- //
    dashboard: {
      display: Vis.SHOW,
    },
    hrRequests: {
      display: Vis.SHOW,
    },
    // currently called "conversations"
    chat: {
      display: Vis.SHOW,
    },
    staff: {
      display: Vis.SHOW,
    },
    policies: {
      display: Vis.SHOW,
    },
    cabinet: {
      display: Vis.SHOW,
    },
    training: {
      display: Vis.SHOW,
    },
    reportCards: {
      display: Vis.SHOW,
    },
    voices: {
      display: Vis.SHOW,
    },
    payroll: {
      display: Vis.SHOW,
    },
    timeAndAttendance: {
      display: Vis.SHOW,
    },
    benefits: {
      display: Vis.SHOW,
    },
    insurance: {
      display: Vis.SHOW,
    },
    managedServices: {
      display: Vis.UPSELL,
      upsell: Upsell.Product.MANAGED_SERVICES,
      hideForT0: true,
    },

    // -- New Features -- //
    // dashboard type view for hotline customers
    hotline: {
      display: Vis.HIDE,
    },
    // phone / chat history
    conversations: {
      display: Vis.SHOW,
    },

    // -- Non-navigation features -- //
    jobDescriptions: {
      display: Vis.SHOW,
    },
    myTeam: {
      display: Vis.SHOW,
    },
  },
  [ProductKey.Plus]: {
    // -- Navigation -- //
    dashboard: {
      display: Vis.SHOW,
    },
    hrRequests: {
      display: Vis.SHOW,
    },
    // currently called "conversations"
    chat: {
      display: Vis.SHOW,
    },
    staff: {
      display: Vis.SHOW,
    },
    policies: {
      display: Vis.SHOW,
    },
    cabinet: {
      display: Vis.SHOW,
    },
    training: {
      display: Vis.SHOW,
    },
    reportCards: {
      display: Vis.SHOW,
    },
    voices: {
      display: Vis.SHOW,
    },
    payroll: {
      display: Vis.SHOW,
    },
    timeAndAttendance: {
      display: Vis.SHOW,
    },
    benefits: {
      display: Vis.SHOW,
    },
    insurance: {
      display: Vis.SHOW,
    },
    managedServices: {
      display: Vis.UPSELL,
      upsell: Upsell.Product.MANAGED_SERVICES,
      hideForT0: true,
    },

    // -- New Features -- //
    // dashboard type view for hotline customers
    hotline: {
      display: Vis.HIDE,
    },
    // phone / chat history
    conversations: {
      display: Vis.HIDE,
    },

    // -- Non-navigation features -- //
    jobDescriptions: {
      display: Vis.SHOW,
    },
    myTeam: {
      display: Vis.SHOW,
    },
  },
  [ProductKey.Elite]: {
    // -- Navigation -- //
    dashboard: {
      display: Vis.SHOW,
    },
    hrRequests: {
      display: Vis.SHOW,
    },
    // currently called "conversations"
    chat: {
      display: Vis.SHOW,
    },
    staff: {
      display: Vis.SHOW,
    },
    policies: {
      display: Vis.SHOW,
    },
    cabinet: {
      display: Vis.SHOW,
    },
    training: {
      display: Vis.SHOW,
    },
    reportCards: {
      display: Vis.SHOW,
    },
    voices: {
      display: Vis.SHOW,
    },
    payroll: {
      display: Vis.SHOW,
    },
    timeAndAttendance: {
      display: Vis.SHOW,
    },
    benefits: {
      display: Vis.SHOW,
    },
    insurance: {
      display: Vis.SHOW,
    },
    managedServices: {
      display: Vis.SHOW,
      hideForT0: true,
    },

    // -- New Features -- //
    // dashboard type view for hotline customers
    hotline: {
      display: Vis.HIDE,
    },
    // phone / chat history
    conversations: {
      display: Vis.HIDE,
    },

    // -- Non-navigation features -- //
    jobDescriptions: {
      display: Vis.SHOW,
    },
    myTeam: {
      display: Vis.SHOW,
    },
  },
};
