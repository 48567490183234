<template>
  <div class=" w-full flex flex-col justify-center pb-0.5 cursor-pointer transition-all" :class="{
    [currentTab.borderColor]: selected,
    'bg-base-200': !selected,
  }" @click="$emit('tab-clicked', planKey)">
    <div class="flex justify-between space-x-2 p-3 bg-white -mt-px">
      <div class="flex items-center text-20">
        <component :is="currentTab.icon" />
      </div>
      <TypeBody variant="text-medium" weight="regular" :class="{
        [currentTab.textColorClass]: selected,
      }">
        {{ currentTab.title }}
      </TypeBody>
      <!-- this is used for spacing so that the icon is left aligned but text is centered -->
      <div class="flex items-center text-20" style="visibility: hidden">
        <component :is="currentTab.icon" />
      </div>
    </div>
  </div>
</template>

<script>
import { BaseIcon, TypeBody } from '@bambeehr/pollen';
import { computed } from '@nuxtjs/composition-api';
import ElitePlanIcon from '@/components/Icons/ElitePlanIcon.vue';
import GuidancePlanIcon from '@/components/Icons/GuidancePlanIcon.vue';
import LitePlanIcon from '@/components/Icons/LitePlanIcon.vue';
import { PlanKeys, PlanLabels } from '@/hooks/usePlanUpgrade/usePlanUpgrade';


const planNameToTabInfo = {
  [PlanKeys.LITE]: {
    icon: LitePlanIcon,
    title: PlanLabels.LITE,
    textColorClass: 'text-gradient-lite',
    borderColor: 'bg-lite-gradient',
  },
  // TEOTODO: remove entry for core eventually

  [PlanKeys.GUIDANCE]: {
    icon: GuidancePlanIcon,
    title: PlanLabels.GUIDANCE,
    textColorClass: 'text-gradient-guidance',
    borderColor: 'bg-guidance-gradient',
  },
  [PlanKeys.ELITE]: {
    icon: ElitePlanIcon,
    title: PlanLabels.ELITE,
    textColorClass: 'text-gradient-elite',
    borderColor: 'bg-elite-gradient',
  },
};

export default {
  name: 'PlanUpgradeModalTab',
  components: {
    BaseIcon,
    TypeBody,
  },
  props: {
    planKey: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ['tab-clicked'],
  setup(props) {
    const currentTab = computed(() => planNameToTabInfo[props.planKey]);

    return {
      currentTab
    };
  },
};
</script>

<style scoped></style>
