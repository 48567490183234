import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';

import {
  CoreUser,
  JobDescription,
  useGetEmployeeJobDescriptionQuery,
  useGetJobDescriptionsQuery,
  useGetStaffJobDescriptionsQuery,
  useSendJobDescriptionToEmployeeMutation,
} from '@/gql/generated';

import {
  computed,
  reactive,
  ref,
  shallowRef,
  useRoute,
} from '@nuxtjs/composition-api';

let employeeId: any;

let refetchEmployeeJobDescription: () => void;
let refetchStaffJobDescriptions: () => void;

let sendJDToEmployee: (args) => void;

const staffJobDescriptions = shallowRef<Partial<CoreUser[]>>();
const currentEmployeeJobDescription = ref<Partial<JobDescription> | null>();

const employeeAcknowledgedJobDescription = ref(false);

const assignJobDescriptionToEmployee = (jobDescriptionId: string) => {
  if (!employeeId.value) {
    return;
  }

  sendJDToEmployee({
    data: {
      jobDescriptionId,
      userId: employeeId.value,
    },
  });
};

const allStaffJobDescriptions = computed(() => {
  return (
    staffJobDescriptions.value
      ?.filter(
        (employee) =>
          employee?.active && employee.employment?.title !== 'business_owner'
      )
      .flatMap((employee) => {
        const workerRoles = employee?.employment?.workerRoles ?? [];

        // there could be multiple job descriptions for an employee
        // if a value is found for the employeeJobDescription, it is considered thea active job description
        // other the value will be null
        const workerRole = workerRoles.find(
          (workerRole) => workerRole.employeeJobDescription
        );

        return {
          employee,
          employeeJobDescription: workerRole?.employeeJobDescription ?? null,
        };
      }) ?? []
  );
});

// if a job description is not signed, it is considered inactive
const inactiveJobDescriptions = computed(
  () =>
    allStaffJobDescriptions.value.filter(
      (staffJD) => !staffJD.employeeJobDescription?.signedAt
    ) ?? []
);

const staffJobDescriptionsLoading = ref(false);
const pagination = reactive({
  page: 1,
  limit: 9,
  totalPages: 1,
  totalCount: 0,
});

const fetchStaffJobDescriptions = (input: {
  page: number;
  limit: number;
  force?: boolean;
  prefetch?: boolean;
}) => {
  const { onResult: onStaffJobDescriptionsResult } = useApolloQuery(
    // @ts-ignore
    useGetStaffJobDescriptionsQuery,
    {
      page: input.page,
      limit: input.limit,
    },
    // we still want a loading indication in the case that the network request is triggered while attempting to prefetch
    !input?.prefetch
      ? {
          pending: staffJobDescriptionsLoading,
        }
      : undefined,
    {
      force: input?.force,
    }
  );

  // only do something with the result if not prefetching
  if (!input.prefetch) {
    onStaffJobDescriptionsResult(({ getMyCoreUser }) => {
      if (getMyCoreUser.company) {
        const {
          totalPages,
          totalCount,
          result: users,
        } = getMyCoreUser.company.paginatedEmployees;

        staffJobDescriptions.value = users as CoreUser[];
        pagination.totalPages = totalPages;
        pagination.totalCount = totalCount;
      }
    });
  }
};

const useJobDescriptions = (userId?: string) => {
  fetchStaffJobDescriptions({
    page: pagination.page,
    limit: pagination.limit,
    force: true,
  });
  const {
    mutate: sendJobDescriptionToEmployee,
    onDone: sendJobDescriptionToEmployeeDone,
  } = useApolloMutation(useSendJobDescriptionToEmployeeMutation);
  sendJDToEmployee = sendJobDescriptionToEmployee;

  currentEmployeeJobDescription.value = null;
  const route = useRoute();
  const computedEmployeeId = computed(
    () => userId ?? (route.value.params.id as string)
  );

  employeeId = computedEmployeeId;

  const {
    result: allJobDescriptions,
    loading: allJobDescriptionsLoading,
    refetch: refetchJobDescriptions,
  } = useApolloQuery(
    // @ts-ignore - generated type is causing issues
    useGetJobDescriptionsQuery,
    undefined,
    undefined,
    undefined,
    undefined
  );

  const {
    loading: employeeJobDescriptionLoading,
    onResult,
    refetch: refetchEmployeeJd,
  } = useApolloQuery(
    useGetEmployeeJobDescriptionQuery,
    {
      userId: employeeId.value,
    },
    undefined,
    {
      // BE is throwing errors if JD is not found
      handleErrors: false,
    },

    {
      enabled: !!employeeId.value,
    }
  );

  refetchEmployeeJobDescription = refetchEmployeeJd;
  refetchStaffJobDescriptions = () => {
    fetchStaffJobDescriptions({
      page: pagination.page,
      limit: pagination.limit,
      force: true,
    });
  };

  onResult(({ getEmployeeJobDescription }) => {
    if (getEmployeeJobDescription) {
      employeeAcknowledgedJobDescription.value =
        getEmployeeJobDescription?.signedAt || false;
      // @ts-ignore
      currentEmployeeJobDescription.value =
        getEmployeeJobDescription.jobDescription;
    }
  });

  sendJobDescriptionToEmployeeDone(() => {
    refetchEmployeeJobDescription();
    refetchStaffJobDescriptions();
  });

  return {
    currentEmployeeJobDescription,
    employeeJobDescriptionLoading,
    allJobDescriptions,
    allJobDescriptionsLoading,
    staffJobDescriptionsLoading,
    allStaffJobDescriptions,
    inactiveJobDescriptions,
    employeeAcknowledgedJobDescription,
    refetchEmployeeJobDescription,
    refetchStaffJobDescriptions,
    refetchJobDescriptions,
    assignJobDescriptionToEmployee,
    sendJobDescriptionToEmployee,
    pagination,
    fetchStaffJobDescriptions,
  };
};

export default useJobDescriptions;
