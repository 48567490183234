import bam from '@/lib/bam';
import { HrGuidanceDetailsInfoModalEvents } from '@/modules/AI/constants/events';
import { computed, ref, useRouter } from '@nuxtjs/composition-api';
import useState from '@/hooks/useState/useState';

const GUIDANCE_DETAILS_INFO_MODAL_PROGRESS = 'aiGuidanceDetailsInfoModal';

let isInitialized = false;

const showGuidanceDetailsInfoModal = ref(false);
const overrideAndShowGuidanceDetailsInfoModal = ref(false);

const {
  hydrateFromLocalStorage: hydrateGuidanceDetailsInfoModalProgress,
  state: aiGuidanceDetailsInfoModalState,
} = useState(
  {
    seen: false,
  },
  GUIDANCE_DETAILS_INFO_MODAL_PROGRESS
);

export const hrGuidanceDetailsCopyPanels = [
  {
    img: '',
    preText: `Your HR Guidance, Organized and Actionable`,
    title: `Case Details is Your Central Hub for HR Resolution`,
    body: `
    <p><span class="font-semibold">Every HR guidance request you make now has a dedicated Case Details page—your go-to hub for managing HR issues from start to finish.</span> Here, you’ll find a full breakdown of your case, including the issue diagnosis, expert guidance, best-practice summary, and next steps. Whether you need clear recommendations, a structured action plan, or full execution support, it’s all in one place.</p>

    <p>When you’re ready to take action, you can delegate implementation directly to Bambee—ensuring your HR challenges aren’t just identified but fully resolved. Now live for all plans.</p>
    `,
    // secondaryBtnLabel: 'Learn More',
    primaryBtnLabel: 'See How it Works',
    primaryBtnHandler: () => {
      aiGuidanceDetailsInfoModalState.seen = true;
      showGuidanceDetailsInfoModal.value = false;
      overrideAndShowGuidanceDetailsInfoModal.value = false;
      bam.trackEvent(HrGuidanceDetailsInfoModalEvents.FINISH);
    },
  },
];

const hasSeenModal = computed(() => aiGuidanceDetailsInfoModalState.seen);
const currentCopy = computed(() => hrGuidanceDetailsCopyPanels[0]);

const closeGuidanceDetailsInfoModal = () => {
  showGuidanceDetailsInfoModal.value = false;
  overrideAndShowGuidanceDetailsInfoModal.value = false;
  bam.trackEvent(HrGuidanceDetailsInfoModalEvents.EXIT);
};

const forceShowGuidanceDetailsInfoModal = () => {
  overrideAndShowGuidanceDetailsInfoModal.value = true;
  bam.trackEvent(HrGuidanceDetailsInfoModalEvents.RE_OPEN);
};

const setupHook = () => {
  hydrateGuidanceDetailsInfoModalProgress();
};

const useAIGuidanceDetailsModal = () => {
  const router = useRouter();
  if (!isInitialized) {
    setupHook();
    isInitialized = true;
  }

  const goToInformationPage = () => {
    router.push('/hr-intelligence/hr-guidance');
  };

  return {
    showGuidanceDetailsInfoModal,
    overrideAndShowGuidanceDetailsInfoModal,
    closeGuidanceDetailsInfoModal,
    aiGuidanceDetailsInfoModalState,
    currentCopy,
    hasSeenModal,
    forceShowGuidanceDetailsInfoModal,
    goToInformationPage,
  };
};

export default useAIGuidanceDetailsModal;
