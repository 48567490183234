import { BaseButton, BaseTag, UserAvatar } from '@bambeehr/pollen';
import { useRouter } from '@nuxtjs/composition-api';
import { formatDate } from '@/utils/date';
import CatalogRecommendedTag from './CatalogRecommendedTag';

import {
  CourseTopic,
  BundleResponse,
  CoreRequest,
  RequestStatus,
  CoreRecommendation,
} from '@/gql/generated';

export const getContentHeader = () => ({
  image: {
    label: '',
    width: 70,
    visible: false,
  },
  name: {
    label: 'Name',
    sortable: true,
    allowOverflow: true,
    bold: true,
    priority: true,
  },
  description: {
    label: 'Description',
    visible: false,
  },
  recommended: {
    label: '',
  },
  duration: {
    label: 'Avg. Duration',
    sortable: true,
    alignment: 'center',
  },
  link: {
    label: '',
    width: 90,
  },
  purchase: {
    label: '',
    width: 90,
  },
});

export const getRequestHeader = (isOwnerAdmin: boolean) => ({
  name: {
    label: 'Name',
    sortable: true,
    priority: true,
    allowOverflow: true,
    bold: true,
  },
  ...(isOwnerAdmin
    ? {
        requester: {
          label: 'Staff Member',
          sortable: true,
        },
        requesterRole: {
          label: 'Role',
          sortable: true,
        },
        requestedDate: {
          label: 'Requested Date',
          sortable: true,
        },
        action: {
          label: '',
          width: 90,
        },
        denyAction: {
          label: '',
          width: 90,
        },
      }
    : {
        requestedDate: {
          label: 'Requested Date',
          sortable: true,
        },
        status: {
          label: 'Status',
          sortable: true,
        },
        action: {
          label: '',
          width: 90,
        },
      }),
});

// Client side logic for determining if a course should be recommended
export const shouldRecommendContent = (
  content: CourseTopic | BundleResponse
) => {
  // TODO implement recommendation logic on the FE (if any)
  return false;
};

export const isContentBundle = (content: CourseTopic | BundleResponse) =>
  !!(content as BundleResponse)?.topics?.length;

export const mapContentToRow = (
  content: CourseTopic | BundleResponse,
  requests: CoreRequest[],
  recommendations: CoreRecommendation[],
  isOwnerAdmin: boolean,
  triggerPurchaseModal: Function,
  handleRequest: Function,
  goTo: Function,
  isBambeeLite?: boolean,
  toggleModal?: Function
) => {
  const { id, name, price, imageUrl, description } = content;

  const isBundle = isContentBundle(content);
  const isRecommended = recommendations.some((r) => r.resourceId === id);

  const matchingRequest = requests.find(
    (request) => request.resourceId === content.id
  );

  const hasRequested = matchingRequest?.status === RequestStatus.Pending;
  const wasApproved = matchingRequest?.status === RequestStatus.Approved;

  let eeBtnText;
  switch (matchingRequest?.status) {
    case RequestStatus.Approved:
      eeBtnText = 'Approved';
      break;

    case RequestStatus.Pending:
      eeBtnText = 'Requested';
      break;

    default:
      eeBtnText = 'Request';
  }

  const totalDuration =
    (content as CourseTopic)?.duration ||
    (content as BundleResponse)?.topics?.reduce(
      (acc, topic) => acc + (topic?.duration ?? 0),
      0
    );

  let isProcessing;

  return {
    id,
    meta: {
      isBundle,
      isRecommended,
    },
    image: imageUrl || '',
    name,
    description: description || '',
    duration: (totalDuration && `${totalDuration} MIN`) || '',
    recommended: isRecommended ? { component: CatalogRecommendedTag } : '',
    link: {
      component: BaseButton,
      attrs: {
        label: 'Learn More',
        variant: 'inverted-primary',
        size: 'small',
      },
      handlers: {
        click() {
          const baseUrl = isOwnerAdmin ? '/training' : '/my-training';
          goTo(`${baseUrl}/catalog/${id}`);
        },
      },
    },
    purchase: isOwnerAdmin
      ? {
          component: BaseButton,
          attrs: {
            label: 'Enroll Staff',
            variant: 'primary',
            size: 'small',
          },
          handlers: {
            click(event) {
              event.stopPropagation();

              if (isBambeeLite) {
                toggleModal?.();
              } else {
                triggerPurchaseModal?.(isBundle, {
                  id,
                  name,
                  price,
                });
              }
            },
          },
        }
      : {
          component: BaseButton,
          attrs: {
            label: eeBtnText,
            disabled: hasRequested || wasApproved,
            variant: 'primary',
            size: 'small',
          },
          handlers: {
            click(event) {
              event.stopPropagation();

              if (isProcessing) {
                return;
              }

              isProcessing = true;
              handleRequest?.(isBundle, {
                id,
                name,
                price,
              });
            },
          },
        },
  };
};

export const getStatusTagInfo = (requestStatus: RequestStatus) => {
  switch (requestStatus) {
    case RequestStatus.Approved:
      return {
        label: 'Approved',
        color: 'evergreen',
      };
    case RequestStatus.Rejected:
      return {
        label: 'Denied',
        color: 'rose',
      };
    case RequestStatus.Pending:
    default:
      return {
        label: 'Requested',
        color: 'gray',
      };
  }
};

export interface TrainingRequestHelpers {
  contentId: string;
  requestId: string;
}

export type TrainingRequest =
  | (CourseTopic & CoreRequest & TrainingRequestHelpers)
  | (BundleResponse & CoreRequest & TrainingRequestHelpers);

export const getEERequestAction = (
  request: TrainingRequest,
  cancelHandler: Function
) => {
  const router = useRouter();

  switch (request.status) {
    case RequestStatus.Pending:
      return {
        component: BaseButton,
        attrs: {
          label: 'Cancel',
          size: 'small',
          buttonType: 'tertiary',
          level: '2',
        },
        handlers: {
          click() {
            cancelHandler([request.requestId], request.name);
          },
        },
      };

    // We need to provide the client with the course ID before we can directly link them
    // Coming after the MVP
    // case RequestStatus.Approved:
    //   return {
    //     component: BaseButton,
    //     attrs: {
    //       label: 'View',
    //       size: 'small',
    //       level: '2',
    //     },
    //     handlers: {
    //       click() {
    //         console.log('view', request);
    //         router.push(`/my-training/${request.contentId}`);
    //       },
    //     },
    //   };

    default:
      return '';
  }
};

export const mapRequestToRow = (
  request: TrainingRequest,
  isOwnerAdmin: boolean,
  approveHandler: (
    idsToApprove: string[],
    isBundle,
    content: CourseTopic | BundleResponse
  ) => void,
  denyHandler: (idsToReject: string[], contentName: string) => void,
  cancelHandler: (idsToCancel: string[], contentName: string) => void
) => {
  const { id, name, requestedAt, status, requestId, user } = request;

  let contextualInfo = {};

  const requestedDate = formatDate(requestedAt[0], '');

  const requesterName = `${user?.profile?.firstName} ${user?.profile?.lastName}`;
  const requesterTitle = user?.employment?.title;
  const requestorAvatar = user?.profile?.avatarUrl;

  if (isOwnerAdmin) {
    contextualInfo = {
      requester: {
        component: UserAvatar,
        label: requesterName,
        attrs: {
          name: requesterName,
          image: requestorAvatar,
        },
      },
      requesterRole: requesterTitle,
      requestedDate,
      action: {
        component: BaseButton,
        attrs: {
          label: 'Approve',
          size: 'small',
        },
        handlers: {
          click() {
            const isBundle = isContentBundle(request);
            approveHandler([requestId], isBundle, request);
          },
        },
      },
      denyAction: {
        component: BaseButton,
        attrs: {
          label: 'Decline',
          buttonType: 'tertiary',
          size: 'small',
          level: '3',
        },
        handlers: {
          click() {
            denyHandler([requestId], name);
          },
        },
      },
    };
  } else {
    const tagAttrs = {
      ...getStatusTagInfo(status),
      size: 'small',
      light: true,
    };

    contextualInfo = {
      requestedDate,
      status: {
        component: BaseTag,
        attrs: tagAttrs,
      },
      action: getEERequestAction(request, cancelHandler),
    };
  }

  const isBundle = !!(request as BundleResponse)?.topics?.length;

  return {
    id,
    meta: {
      isBundle,
    },
    name,
    ...contextualInfo,
  };
};

export const mapActionRes = (res: { [key: string]: boolean }) =>
  Object.keys(res).filter((key) => res[key]);

// Actions will not be supported in the MVP.
// Leaving here so we can easily enable when ready.
export const requestActions = [
  {
    id: 'PRIMARY',
    attrs: {
      label: 'Approve',
      buttonType: 'primary',
    },
    handler: (idModel) => {
      // TODO implement approve handler when BE mutation is ready
      console.log('approve', mapActionRes(idModel));
    },
  },
  {
    id: 'SECONDARY',
    attrs: {
      label: 'Decline',
      buttonType: 'tertiary',
      level: '3',
    },
    handler: (idModel) => {
      // TODO implement deny handler when BE mutation is ready
      console.log('deny', mapActionRes(idModel));
    },
  },
];

export const mapRequestsToTrainingItem = (
  trainingItems: (CourseTopic | BundleResponse)[],
  requests: CoreRequest[]
): TrainingRequest[] =>
  requests.map((request) => {
    const item = trainingItems.find((i) => i.id === request.resourceId);

    return {
      ...request,
      ...item,
      requestId: request.id,
      contentId: item?.id,
    } as TrainingRequest;
  });

export const getColCount = (screen) => {
  switch (screen.breakpoint) {
    case 'xs':
      return 1;
    case 'sm':
      return 2;
    case 'md':
    case 'lg':
      return 3;

    case 'xl':
    case '2xl':
    default:
      return 4;
  }
};
