import bam from '@/lib/bam';
import { HrGuidanceInfoModalEvents } from '@/modules/AI/constants/events';
import { computed, ref, useRouter } from '@nuxtjs/composition-api';
import useState from '@/hooks/useState/useState';

const GUIDANCE_INFO_MODAL_PROGRESS = 'aiGuidanceInfoModal';

let isInitialized = false;

const showGuidanceInfoModal = ref(false);
const overrideAndShowGuidanceInfoModal = ref(false);

const {
  hydrateFromLocalStorage: hydrateGuidanceInfoModalProgress,
  state: aiGuidanceInfoModalState,
} = useState(
  {
    progress: 0,
    done: false,
  },
  GUIDANCE_INFO_MODAL_PROGRESS
);

const incrementProgress = () => {
  aiGuidanceInfoModalState.progress += 1;
};

export const hrGuidanceCopyPanels = [
  {
    img: '/assets/images/ai/guidance-modal-panel-1.png',
    preText: `Introducing HR Guidance Direct™, Our HR Manager's Secret Weapon, Now Yours.`,
    title: `Clear HR Advice and Powerful HR Guidance, now.`,
    body: `
    <p><span class="font-semibold">Bambee redefined HR Guidance by making dedicated HR support accessible to small business.</span> Then, we expanded access even further with Bambee LITE ($9/month) for Elite for full-service, action-driven HR. Now, we're at it against this time with technology. The same AI-driven HR platform our Dedicated HR managers use is now at your fingertips, HR Guidance Direct™—powered by HR Intelligence.  Backed by millions of HR touch points, it delivers instant, expert-backed answers, 24/7—no waiting, no guesswork. Bambee is giving you direct access and <span class="font-semibold">this isn't just automation—it's the most intelligent HR Guidance, built for you.</span></p>
    `,
    secondaryBtnLabel: 'Learn More',
    primaryBtnLabel: 'See How it Works',
    primaryBtnHandler: () => {
      incrementProgress();
      bam.trackEvent(HrGuidanceInfoModalEvents.GO_TO_SECOND_PANEL);
    },
  },
  {
    img: '/assets/images/ai/guidance-modal-panel-2.png',
    preText: `HR Guidance Direct is The Right Guidance, Instantly for Every Business.`,
    title: `Start Conversations and Solve Complex Problems.`,
    body: `
        <p><span class="font-semibold">Ask HR Guidance Direct™</span> anything—just like you would with your HR manager. <span class="font-semibold">HR Guidance Basic, Pro, or Max</span> delivers advice in real time. With Basic, it works with you to fully diagnose and run risk assessments, then delivers a clear action plan for you to follow. With Pro, it assigns the implementation to your Bambee HR Manager. HR Guidance Direct Max is only available with Bambee Elite.</span>
        <p>Your plan determines your service level. Lite customers receive clear best practices for each situation. Guidance customers gather details, help diagnose issues, and receive comprehensive, nuanced guidance. Plus, Guidance now drafts an HR Action Plan to guide execution step by step. With Bambee Elite, your HR Manager executes your HR Action Plan for you, with your direction—literally giving you hours back in your day.</p>

    `,
    secondaryBtnLabel: 'Learn More',
    primaryBtnLabel: `What's an HR Action Plan?`,
    primaryBtnHandler: () => {
      incrementProgress();
      bam.trackEvent(HrGuidanceInfoModalEvents.GO_TO_THIRD_PANEL);
    },
  },
  {
    img: '/assets/images/ai/guidance-modal-panel-3.png',
    preText: `<div class="flex items-center gap-2"><div class="font-bold"><i>New</i></div> HR Action Plans + Your Dedicated HR Manager, Always Within Reach</div>`,
    title: `HR Action Plans, Make Implementing the Guidance a Breeze.`,
    body: `
      <p>HR Guidance Direct™ <span class="font-semibold">Pro</span>, available under Bambee Guidance & Elite Plans, provides custom HR Action Plans—step-by-step checklists and roadmaps designed to help you implement HR guidance seamlessly within your company.</p>
      <p>When you need extra assurance, your dedicated HR Manager is always available to review, refine, or assist. Simply schedule a call directly with your HR Manager. Now, with HR Guidance Direct™ Max—exclusively available under Elite Plans—your Dedicated HR Manager will manage implementation for you.</p>

      <div class="italic text-10">
        <div class="font-semibold">A Note from Our Technologists</div>
        HR Guidance Direct™ leverages millions of HR interactions from Bambee's rich dataset to deliver best-in-class guidance and outcome-driven recommendations. For fast-changing regulations, such as local minimum wages, accuracy is a priority, but updates are ongoing. If ever in doubt, reach out—we're always improving.
      </div>  
      `,
    secondaryBtnLabel: 'Learn More',
    primaryBtnLabel: `How can your HR Manager help?`,
    primaryBtnHandler: () => {
      incrementProgress();
      bam.trackEvent(HrGuidanceInfoModalEvents.GO_TO_FOURTH_PANEL);
    },
  },
  {
    img: '/assets/images/ai/guidance-modal-panel-4.png',
    preText: `<div class="flex items-center gap-2"><div class="font-bold"><i>New</i></div> HR Action Plan Execution – Done for You with Managed Implementation</div>`,
    title: `Now HR Managers can Implement Your Plans for <span class="italic">You.</span>`,
    body: `<p>Elite Plans include HR Guidance Direct™ <span class="font-semibold">Max</span>. You don’t just get comprehensive, expert-backed HR guidance with step-by-step HR Action Plans—you get full execution. Bambee’s HR Managers implement your HR Action Plan directly within your company, handling employee communication, policy rollouts, and compliance steps on your behalf. No more guessing, no more delays—just fast, professional HR action, fully managed for you.</p>`,
    secondaryBtnLabel: 'Learn More',
    primaryBtnLabel: `Try it out`,
    primaryBtnHandler: () => {
      aiGuidanceInfoModalState.done = true;
      overrideAndShowGuidanceInfoModal.value = false;
      bam.trackEvent(HrGuidanceInfoModalEvents.FINISH);
    },
  },
];

const hasSeenAllPanels = computed(() => aiGuidanceInfoModalState.done);
const totalPanelCount = computed(() => hrGuidanceCopyPanels.length);
const currentProgress = computed(() => aiGuidanceInfoModalState.progress);
const currentCopy = computed(() => hrGuidanceCopyPanels[currentProgress.value]);

const closeGuidanceInfoModal = () => {
  showGuidanceInfoModal.value = false;
  overrideAndShowGuidanceInfoModal.value = false;
  bam.trackEvent(HrGuidanceInfoModalEvents.EXIT);
};

const forceShowGuidanceInfoModal = () => {
  aiGuidanceInfoModalState.progress = 0;
  overrideAndShowGuidanceInfoModal.value = true;
  bam.trackEvent(HrGuidanceInfoModalEvents.RE_OPEN);
};

const setupHook = () => {
  hydrateGuidanceInfoModalProgress();
};

const useAIGuidanceModal = () => {
  if (!isInitialized) {
    setupHook();
    isInitialized = true;
  }

  return {
    showGuidanceInfoModal,
    overrideAndShowGuidanceInfoModal,
    closeGuidanceInfoModal,
    aiGuidanceInfoModalState,
    currentCopy,
    hasSeenAllPanels,
    forceShowGuidanceInfoModal,
    totalPanelCount,
    currentProgress,
  };
};

export default useAIGuidanceModal;
