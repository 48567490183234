<template>
  <div>
    <div
      v-if="tileConfigs.length"
      class="progress-tile-container flex flex-col gap-3"
    >
      <ProgressTile
        v-for="config in tileConfigs"
        :id="config.id"
        :key="config.id"
        data-qa="progress-tile"
        class="payroll-progress-tile"
        :payroll-start-date="config.periodStart"
        :payroll-end-date="config.periodEnd"
        :breakdown="config.breakdown"
        :primary-btn="config.primaryBtn"
        :secondary-btn="config.secondaryBtn"
        :status-text="config.statusText"
        :is-callout="config.isCallout"
        :description="config.description"
        :banner="config.banner"
        :tag="config.tag"
        :disabled="config.disabled"
      />
    </div>
    <EmptyDashboardList v-if="emptyMsg && !tileConfigs.length">
      {{ emptyMsg }}
    </EmptyDashboardList>
  </div>
</template>

<script>
import { useApolloMutation } from '@/gql/apolloWrapper';
import EmptyDashboardList from '@/modules/payroll/components/DashboardTile/EmptyDashboardList';
import ProgressTile from '@/modules/payroll/components/ProgressTile/ProgressTile';
import {
  PayrollStatus,
  PayrollTypes,
} from '@/modules/payroll/constants/payroll';
import { useInitPayrollMutation } from '@/gql/generated';
import { getMissedDirectDepositMessage } from '@/modules/payroll/utils/deadlineMessages';
import { getDeadlineStatuses } from '@/modules/payroll/utils/getDeadlineStatuses';
import { sortPayrollsByPayday } from '@/modules/payroll/utils/sorting';
import { formatDate } from '@/utils/date';
import useNotifications from '@bambeehr/use-notifications';
import { computed, useRouter } from '@nuxtjs/composition-api';
import currency from 'currency.js';
import usePlanAccess from '@/hooks/usePlanAccess/usePlanAccess';
import useBambeeUpsell from '@/hooks/useBambeeUpsell/useBambeeUpsell';

export default {
  name: 'ProgressTileList',
  components: {
    EmptyDashboardList,
    ProgressTile,
  },
  props: {
    payrolls: {
      type: Array, // Payroll[]. Matches the Payroll Object from the API response
      required: true,
    },
    emptyMsg: {
      type: String,
      required: false,
      default: '',
    },
    company: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const getBreakdownItems = (payroll) => {
      return {
        deadline: {
          key: 'deadline',
          label: 'Deadline',
          value: formatDate(payroll.approvalDeadline),
          error: false,
        },
        payday: {
          key: 'payday',
          label: 'Pay Date',
          value: formatDate(payroll.payday),
        },
        companyCost: {
          key: 'companyCost',
          label: 'Company Cost',
          value:
            payroll.totals?.liability &&
            currency(payroll.totals?.liability).format(),
        },
        staffCount: {
          key: 'staffCount',
          label: 'Worker Count',
          value: payroll.employeeCount + payroll.contractorCount,
          error: false,
        },
      };
    };

    const { hasLiteOrLower } = usePlanAccess();
    const { togglePlanAction } = useBambeeUpsell();

    const getButtonConfigs = (payroll = {}) => {
      const {
        mutate: initializePayroll,
        onError: onInitError,
        onDone: onPayrollInit,
      } = useApolloMutation(useInitPayrollMutation);
      const { addError } = useNotifications();
      const router = useRouter();

      return {
        runRegularPayroll: {
          label: 'Run Scheduled Payroll',
          handler(isLoading) {
            if (isLoading.value) {
              return;
            }

            if (hasLiteOrLower.value) {
              togglePlanAction(true, undefined, 'payroll');

              return;
            }

            initializePayroll(
              {
                data: {
                  id: payroll.id,
                },
              },
              {
                pending: isLoading,
              }
            );

            onInitError(() => {
              addError(
                `There was an error while trying to run the scheduled payroll.`
              );
            });

            onPayrollInit(({ initializePayroll: res }) => {
              if (res) {
                router.push(`/payroll/${payroll.id}/edit`);
              }
            });
          },
        },
        contactHR: {
          label: 'Contact Us',
          link: `/requests/pay-benefits-payroll`,
        },
        editPayroll: {
          label: 'Continue Payroll',
          handler() {
            if (hasLiteOrLower.value) {
              togglePlanAction(true, undefined, 'payroll');

              return;
            }

            router.push(`/payroll/${payroll.id}/edit`);
          },
        },
        viewSummary: {
          label: 'View Summary',
          link: `/payroll/${payroll.id}/summary`,
        },
        writeChecks: {
          label: 'Write Checks',
          link: `/payroll/${payroll.id}/checks`,
        },
      };
    };

    const BannerConfigs = {
      OVERDUE_PAYROLL: {
        variant: 'error',
        message: 'Missed payroll deadline',
        description:
          '<span>Please contact us to help resolve your overdue payroll.</span>',
        showClose: false,
      },
      MISSED_DIRECT_DEPOSIT: {
        variant: 'warning',
        message: 'Workers can only be paid with manual checks',
        description: getMissedDirectDepositMessage(
          props.company.processingPeriod
        ),
        showClose: false,
      },
      ADJUSTED_DEADLINE: {
        variant: 'explainer',
        message: 'Payroll deadline adjusted due to holiday or weekend.',
        showClose: false,
      },
      FAILED_DEPOSITS: {
        variant: 'error',
        message: 'Failed direct deposit payments',
        description:
          'Please contact your HR Manager to help resolve the issue.',
        showClose: false,
      },
      FAILED: {
        variant: 'error',
        message: 'Failed to transfer funds',
        description:
          'Please contact your HR Manager to help resolve the issue.',
        showClose: false,
      },
    };

    const DEFUALT_TILE_CONFIG = {
      banner: null,
      breakdown: [],
      description: '',
      isCallout: false,
      primaryBtn: null,
      secondaryBtn: null,
      statusText: '',
      tag: null,
    };

    const deadlineStatusDecorator = (
      tileConfig,
      { isOverdue, isDueSoon, isMissedDirectDepositWindow }
    ) => {
      if (isOverdue) {
        const { contactHR } = getButtonConfigs();
        const { OVERDUE_PAYROLL } = BannerConfigs;
        const { breakdown } = tileConfig;
        const deadlineBreakdown = breakdown.find(
          ({ key }) => key === 'deadline'
        );
        deadlineBreakdown.error = true;

        return {
          ...tileConfig,
          statusText: 'Missed Payroll',
          description: '',
          primaryBtn: contactHR,
          banner: OVERDUE_PAYROLL,
          tag: {
            message: 'ACTION REQUIRED',
            error: true,
          },
        };
      }

      if (isMissedDirectDepositWindow) {
        return {
          ...tileConfig,
          statusText: 'Last minute payroll',
          description: '',
          banner: BannerConfigs.MISSED_DIRECT_DEPOSIT,
        };
      }

      if (isDueSoon) {
        return {
          ...tileConfig,
          statusText: `${tileConfig.statusText} due soon`,
          isCallout: true,
        };
      }

      return tileConfig;
    };

    const getInitialPayrollConfig = (payroll) => {
      const breakdown = getBreakdownItems(payroll);
      const { runRegularPayroll } = getButtonConfigs(payroll);
      let config = {
        ...DEFUALT_TILE_CONFIG,
        statusText: 'Scheduled payroll',
        description: 'It’s time to reward your workers for their hard work.',
        breakdown: [breakdown.deadline, breakdown.payday],
        primaryBtn: runRegularPayroll,
      };

      const deadlineStatuses = getDeadlineStatuses(payroll);
      config = deadlineStatusDecorator(config, deadlineStatuses);

      return config;
    };

    const getDraftPayrollConfig = (payroll) => {
      let type = 'Scheduled';
      if (payroll.type === PayrollTypes.OFF_CYCLE) {
        type = 'Off-Cycle';
      }

      const totalEarningsValue = payroll.draftLiability || 0;

      const breakdown = getBreakdownItems(payroll);
      breakdown.totalEarnings = {
        key: 'totalEarnings',
        label: 'Total Earnings',
        value: currency(totalEarningsValue).format(),
      };

      const { editPayroll } = getButtonConfigs(payroll);

      let config = {
        ...DEFUALT_TILE_CONFIG,
        statusText: `${type} draft payroll`,
        description: '',
        breakdown: [
          breakdown.deadline,
          breakdown.payday,
          breakdown.totalEarnings,
          breakdown.staffCount,
        ],
        primaryBtn: editPayroll,
      };

      const deadlineStatuses = getDeadlineStatuses(payroll);
      config = deadlineStatusDecorator(config, deadlineStatuses);

      return config;
    };

    const getProcessingPayrollConfig = (payroll) => {
      const breakdown = getBreakdownItems(payroll);
      const { viewSummary, writeChecks } = getButtonConfigs(payroll);

      const config = {
        ...DEFUALT_TILE_CONFIG,
        statusText: `We’re currently processing your payroll`,
        description: '',
        breakdown: [
          breakdown.payday,
          breakdown.companyCost,
          breakdown.staffCount,
        ],
        primaryBtn: viewSummary,
      };
      const { needsChecksEntered } = payroll;
      if (needsChecksEntered) {
        config.primaryBtn = writeChecks;
        config.secondaryBtn = viewSummary;
      }

      return config;
    };

    const getPartiallyPaidPayrollConfig = (payroll) => {
      const { FAILED_DEPOSITS } = BannerConfigs;
      const breakdown = getBreakdownItems(payroll);
      const { contactHR } = getButtonConfigs(payroll);
      breakdown.staffCount.error = true;

      const config = {
        ...DEFUALT_TILE_CONFIG,
        statusText: `Unable to pay some workers`,
        description: '',
        breakdown: [
          breakdown.payday,
          breakdown.companyCost,
          breakdown.staffCount,
        ],
        banner: FAILED_DEPOSITS,
        primaryBtn: contactHR,
        tag: {
          message: 'ACTION REQUIRED',
          error: true,
        },
      };

      return config;
    };

    const getFailedPayrollConfig = (payroll) => {
      const { FAILED } = BannerConfigs;
      const breakdown = getBreakdownItems(payroll);
      const { contactHR } = getButtonConfigs(payroll);

      const config = {
        ...DEFUALT_TILE_CONFIG,
        statusText: `Unable to process payroll`,
        description: '',
        breakdown: [
          breakdown.payday,
          breakdown.companyCost,
          breakdown.staffCount,
        ],
        banner: FAILED,
        primaryBtn: contactHR,
        tag: {
          message: 'ACTION REQUIRED',
          error: true,
        },
      };

      return config;
    };

    const getPaidPayrollConfig = (payroll) => {
      const breakdown = getBreakdownItems(payroll);
      const { viewSummary, writeChecks } = getButtonConfigs(payroll);

      const config = {
        ...DEFUALT_TILE_CONFIG,
        statusText: `Congratulations, you've paid your workers!`,
        description: '',
        breakdown: [
          breakdown.payday,
          breakdown.companyCost,
          breakdown.staffCount,
        ],
        primaryBtn: viewSummary,
      };

      const { needsChecksEntered } = payroll;
      if (needsChecksEntered) {
        config.primaryBtn = writeChecks;
        config.secondaryBtn = viewSummary;
      }

      return config;
    };

    const getTileConfig = (payroll) => {
      switch (payroll.status) {
        case PayrollStatus.INITIAL:
          return getInitialPayrollConfig(payroll);

        case PayrollStatus.DRAFT:
          return getDraftPayrollConfig(payroll);

        case PayrollStatus.PENDING:
        case PayrollStatus.PROCESSING:
          return getProcessingPayrollConfig(payroll);

        case PayrollStatus.PARTIALLY_PAID:
          return getPartiallyPaidPayrollConfig(payroll);

        case PayrollStatus.FAILED:
          return getFailedPayrollConfig(payroll);

        default:
          return getPaidPayrollConfig(payroll);
      }
    };

    const regularPayrolls = computed(() => {
      return props.payrolls
        .filter((p) => p.type === PayrollTypes.REGULAR)
        .sort(sortPayrollsByPayday);
    });

    const offCyclePayrolls = computed(() => {
      return props.payrolls
        .filter((p) => p.type === PayrollTypes.OFF_CYCLE)
        .sort(sortPayrollsByPayday);
    });

    const sortedPayrolls = computed(() => {
      return [...regularPayrolls.value, ...offCyclePayrolls.value];
    });

    const tileConfigs = computed(() => {
      return sortedPayrolls.value.map((payroll) => {
        const config = getTileConfig(payroll);
        const { periodStart, periodEnd, id } = payroll;

        return {
          ...config,
          id,
          periodStart,
          periodEnd,
          disabled: props.disabled,
        };
      });
    });

    return {
      tileConfigs,
      PayrollTypes,
    };
  },
};
</script>
