import { computed, ref } from '@nuxtjs/composition-api';
import {
  BillingFeature,
  BillingPeriod,
  PricingPackage,
  PricingPackageType,
  ProductKey,
  useCreateEliteAddonSubscriptionMutation,
  useGetLatestPricingPackagesForTierQuery,
  useUpgradeCorePlanMutation,
} from '@/gql/generated';
import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';
import useCurrentPlan from '../useCurrentPlan/useCurrentPlan';
import useNotifications from '@bambeehr/use-notifications';
import bam from '@/lib/bam';
import HrGuidanceEvents from '@/modules/HrGuidance/constants/events';
import useCurrentCompany from '../useCurrentCompany';
import useCurrentUser from '../useCurrentUser';
import { saveInLocalStorage } from '@/utils/localStorage';
import useStaffManagement from '@/modules/StaffManagement/hooks/useStaffManagement';
import usePlanAccess from '../usePlanAccess/usePlanAccess';

export enum PlanKeys {
  LITE = 'LITE',
  GUIDANCE = 'CORE', // Currently Core ("dedicated") is being mapped to Guidance
  ELITE = 'ELITE',
}

export enum PlanLabels {
  LITE = 'Lite',
  GUIDANCE = 'Guidance', // Currently Core ("dedicated") is being mapped to Guidance
  ELITE = 'Elite',
}

let init;

const ELITE_PURCHSED_DURING_THREAD_KEY = 'elite-purchased-during-thread';

const mapEmployeeCountToTier = (employeeCount: number): number => {
  if (employeeCount <= 4) {
    return 1;
  }
  if (employeeCount <= 19) {
    return 2;
  }
  if (employeeCount <= 49) {
    return 3;
  }
  if (employeeCount <= 70) {
    return 4;
  }
  if (employeeCount <= 149) {
    return 5;
  }
  if (employeeCount <= 249) {
    return 6;
  }
  if (employeeCount <= 500) {
    return 7;
  }

  return 8;
};

const showModal = ref(false);
const isLoading = ref(false);
const tierNumber = ref<number>();
const companyId = ref<string>();
const currentPlanId = ref<string>();
const currentUserId = ref<string>();
const allPackagesAtTier = ref<PricingPackage[]>([]);

const setupHook = () => {
  const { tierNumber: tierNumberFromCurrentPlan, currentPlan } =
    useCurrentPlan();
  const { companyId: companyIdFromCurrentCompany } = useCurrentCompany();
  const { currentUser } = useCurrentUser();
  const { allStaff } = useStaffManagement();
  const { isBambeeLite } = usePlanAccess(true);

  companyId.value = companyIdFromCurrentCompany.value;
  tierNumber.value =
    tierNumberFromCurrentPlan.value ??
    mapEmployeeCountToTier(allStaff.value.length);
  currentPlanId.value = currentPlan.value?.value;
  currentUserId.value = currentUser.value?._id;
  isLoading.value = true;

  const productsToShow = isBambeeLite.value
    ? [ProductKey.Lite, ProductKey.Core]
    : [ProductKey.Core, ProductKey.Elite];

  const { onResult, onError } = useApolloQuery(
    useGetLatestPricingPackagesForTierQuery,
    {
      tierNumber: tierNumber.value,
      getLatestPricingPackagesForTierInput: {
        billingPeriods: [BillingPeriod.Month],
        productKeys: productsToShow,
      },
    }
  );

  onResult(({ getLatestPricingPackagesForTier: res }) => {
    allPackagesAtTier.value = res?.packages as PricingPackage[];
  });

  onError((error) => {
    console.error('error', error);
  });
};

interface PricePoint {
  billingPeriod: BillingPeriod;
  monthlyPrice: number;
  priceId: string;
  billingFeature?: BillingFeature;
}

type PlanNameToPricePoints = {
  [key in PlanKeys]: PricePoint[];
};

const mapPlanToPricePoints = computed(() => {
  return allPackagesAtTier.value[0]?.plans.reduce((acc, plan) => {
    if (!plan) {
      return acc;
    }

    if (!acc[plan.productKey]) {
      acc[plan.productKey] = [
        {
          billingPeriod: plan.billingPeriod,
          monthlyPrice: plan.monthlyPrice,
          priceId: plan.priceId,
          billingFeature: plan.billingFeature,
        },
      ];
    } else {
      acc[plan.productKey].push({
        billingPeriod: plan.billingPeriod,
        monthlyPrice: plan.monthlyPrice,
        priceId: plan.priceId,
        billingFeature: plan.billingFeature,
      });
    }

    return acc;
  }, {} as PlanNameToPricePoints);
});

const mapPlanToMonthlyPrice = computed(() => {
  return allPackagesAtTier.value[0]?.plans.reduce((acc, plan) => {
    if (!plan) {
      return acc;
    }

    acc[plan.productKey] = {
      billingPeriod: plan.billingPeriod,
      monthlyPrice: plan.monthlyPrice,
      priceId: plan.priceId,
      planId: plan.value,
      billingFeature: plan.billingFeature,
    };

    return acc;
  }, {});
});

const toggleModal = () => {
  showModal.value = !showModal.value;
};

const isPurchasing = ref(false);
const subscribeToPlan = async (planId: string, threadId?: string) => {
  const { addSuccess, addError } = useNotifications();
  if (!companyId.value) {
    addError('Failed to upgrade plan');

    return false;
  }

  const { mutate, onDone, onError } = useApolloMutation(
    useUpgradeCorePlanMutation,
    {
      pending: isPurchasing,
    }
  );

  mutate({
    data: {
      newPlanId: planId,
      companyId: companyId.value,
      // This is no longer used, but expected in the contract
      // Leaving until we deprecate
      newPricingPackageType: PricingPackageType.Business,
    },
  });

  onDone(({ upgradeCorePlan: res }) => {
    if (res) {
      addSuccess('Your plan has been updated!');

      // TODO: This modal will eventually be used outside of just guidance
      bam.trackEvent(HrGuidanceEvents.SELF_SERVE_PLAN_UPGRADE_SUCCESS, {
        currentPlanId: currentPlanId.value,
        newPlanId: planId,
      });

      if (threadId) {
        saveInLocalStorage(
          `${ELITE_PURCHSED_DURING_THREAD_KEY}:${threadId}`,
          true
        );
      }

      setTimeout(() => {
        window.location.reload();
      }, 500);
    }

    return false;
  });

  onError((error) => {
    bam.trackEvent(HrGuidanceEvents.SELF_SERVE_PLAN_UPGRADE_ERROR, {
      currentPlanId: currentPlanId.value,
      newPlanId: planId,
    });
    addError(`Failed to upgrade plan : ${error}`);
  });

  return false;
};

const subscribeToEliteAddon = async (doNotRefreshPage = false) => {
  const { addSuccess, addError } = useNotifications();
  if (!companyId.value) {
    addError('Failed to upgrade plan');

    return false;
  }

  const { mutate, onDone, onError } = useApolloMutation(
    useCreateEliteAddonSubscriptionMutation,
    {
      pending: isPurchasing,
    }
  );

  mutate({
    input: {
      companyId: companyId.value,
    },
  });

  onDone(({ createEliteAddonSubscription: res }) => {
    if (res) {
      addSuccess('Your plan has been updated!');
      bam.trackEvent(HrGuidanceEvents.SELF_SERVE_PLAN_UPGRADE_SUCCESS, {
        currentPlanId: currentPlanId.value,
        newPlanId: ProductKey.Elite,
      });

      if (doNotRefreshPage) {
        return;
      }

      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  });

  onError((error) => {
    addError(`Failed to upgrade plan : ${error}`);
  });

  return false;
};

const usePlanUpgrade = (force = false) => {
  if (!init || force) {
    setupHook();
    init = true;
  }

  return {
    isLoading,
    allPackagesAtTier,
    mapPlanToPricePoints,
    mapPlanToMonthlyPrice,
    showModal,
    toggleModal,
    subscribeToPlan,
    subscribeToEliteAddon,
  };
};

export default usePlanUpgrade;
