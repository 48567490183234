export enum WizardEvents {
  ENTERED = 'ENTERED',
  EXITED = 'EXITED',
  PROGRESS = 'PROGRESS',
  COMPLETED = 'COMPLETED',
  HELP_CLICKED = 'HELP_CLICKED',
}

export enum AIChatModalEvents {
  OPEN = 'AI_CHAT_OPEN',
  CLOSE = 'AI_CHAT_CLOSE',
}

export enum AIConversationEvents {
  MESSAGE_RECEIVED = 'AI_CONVERSATION_MESSAGE_RECEIVED',
  MESSAGE_SENT = 'AI_CONVERSATION_MESSAGE_SENT',
  CONVERSATION_ENDED = 'AI_CONVERSATION_ENDED',
}

export enum AIUpsellEvents {
  PREFIX = 'APP_AI_',
  AI_OPT_IN_INFO_VIEW = 'AI_OPT_IN_INFO_VIEW',
  AI_OPT_IN_START = 'AI_OPT_IN_START',
  AI_OPT_IN_SUCCESS = 'AI_OPT_IN_SUCCESS',
  AI_OPT_IN_ERROR = 'AI_OPT_IN_ERROR',
  AI_REQUEST_START = 'AI_REQUEST_START',
  AI_REQUEST_ERROR = 'AI_REQUEST_ERROR',
  AI_REQUEST_COMPLETE = 'AI_REQUEST_COMPLETE',
  AI_REQUEST_CANCEL = 'AI_REQUEST_CANCEL',
  AI_REQUEST_HELP = 'AI_REQUEST_HELP',
  AI_REQUEST_HELP_CLOSED = 'AI_REQUEST_HELP_CLOSED',
  AI_REQUEST_SCHEDULE_CALL = 'AI_REQUEST_SCHEDULE_CALL',
  AI_REQUEST_APPROVE_ARTIFACT = 'AI_REQUEST_APPROVE_ARTIFACT',
  AI_REQUEST_ABANDONED_ARTIFACT = 'AI_REQUEST_ABANDONED_ARTIFACT',
  OPEN = 'APP_AI_MODAL_OPEN',
  CLOSE = 'APP_AI_MODAL_CLOSE',
  DISMISSED_BANNER = 'APP_AI_DISMISSED_BANNER',
  VIEWED_FRAMER_PAGE = 'APP_AI_VIEWED_FRAMER_PAGE',
}

export enum HrGuidanceInfoModalEvents {
  OPEN = 'APP_HR_GUIDANCE_INFO_MODAL_OPEN',
  EXIT = 'APP_HR_GUIDANCE_INFO_MODAL_EXIT',
  RE_OPEN = 'APP_HR_GUIDANCE_INFO_MODAL_RE_OPEN',
  GO_TO_SECOND_PANEL = 'APP_HR_GUIDANCE_INFO_MODAL_GO_TO_SECOND_PANEL',
  GO_TO_THIRD_PANEL = 'APP_HR_GUIDANCE_INFO_MODAL_GO_TO_THIRD_PANEL',
  GO_TO_FOURTH_PANEL = 'APP_HR_GUIDANCE_INFO_MODAL_GO_TO_FOURTH_PANEL',
  FINISH = 'APP_HR_GUIDANCE_INFO_MODAL_FINISH',
  LEARN_MORE = 'APP_HR_GUIDANCE_INFO_MODAL_LEARN_MORE',
}

export enum HrGuidanceDetailsInfoModalEvents {
  OPEN = 'APP_HR_GUIDANCE_DETAILS_INFO_MODAL_OPEN',
  EXIT = 'APP_HR_GUIDANCE_DETAILS_INFO_MODAL_EXIT',
  RE_OPEN = 'APP_HR_GUIDANCE_DETAILS_INFO_MODAL_RE_OPEN',
  FINISH = 'APP_HR_GUIDANCE_DETAILS_INFO_MODAL_FINISH',
}

export enum HrGuidanceDelegateTaskUpsellModalEvents {
  OPEN = 'APP_HR_GUIDANCE_DELEGATE_TASK_UPSELL_MODAL_OPEN',
  EXIT = 'APP_HR_GUIDANCE_DELEGATE_TASK_UPSELL_MODAL_EXIT',
}
export default WizardEvents;
