



























































import {
  useRouter,
  defineComponent,
  computed,
  getCurrentInstance,
  ref,
} from '@nuxtjs/composition-api';
import { DataTable, TabSet, AnnularThrobber } from '@bambeehr/pollen';
import useCurrentUser from '@/hooks/useCurrentUser';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import useTrainingCatalog from '@/modules/Training/hooks/useTrainingCatalog';
import TrainingEnrollmentModal from '@/modules/Training/components/TrainingEnrollmentModal';
import { RequestStatus } from '@/gql/generated';
import bam from '@/lib/bam';
import useEmployerTrainingRequests from '@/modules/Training/hooks/useEmployerTrainingRequests';
import useEmployeeTrainingRequests from '@/modules/Training/hooks/useEmployeeTrainingRequests';
import useNotifications from '@bambeehr/use-notifications';
import CatalogCard from './CatalogCard.vue';
import useState from '@/hooks/useState/useState';
import TrainingRequests from '@/modules/Training/pages/TrainingRequests/TrainingRequests';
import {
  getContentHeader,
  getRequestHeader,
  mapContentToRow,
  mapRequestToRow,
  getColCount,
  mapRequestsToTrainingItem,
} from './utils';
import useTrainingRecommendations from '@/modules/Training/hooks/useTrainingRecommendations/useTrainingRecommendations';
import useBambeeUpsell from '@/hooks/useBambeeUpsell/useBambeeUpsell';
import usePlanAccess from '@/hooks/usePlanAccess/usePlanAccess';
import usePlanUpgrade from '@/hooks/usePlanUpgrade/usePlanUpgrade';

const tabOptions = ['Courses', 'Requests']; // 'Programs'

// TODO replace with real filters
const filters = [
  // {
  //   id: 'RECOMMENDED',
  //   label: 'Recommended',
  //   method: (item) => item.meta.isRecommended,
  // },
];

const dataTableDefaults = {
  selectable: false,
  denseView: false,
  hideFiltersWhenEmpty: true,
  maxItemsPerPage: 50,
};

const TABS = {
  COURSES: 'Courses',
  REQUESTS: 'Requests',
};

enum TableViewType {
  CARD = 'card',
  COMFORTABLE = 'comfortable',
}

export interface ModalInfo {
  bundleId: string;
  topicId: string;
  title: string;
  selectedIds: string[];
}

const requestActions = [
  {
    id: 'PRIMARY',
    attrs: {
      label: 'Approve',
      buttonType: 'primary',
    },
    handler: (idModel) => console.log('Primary', idModel),
  },
  {
    id: 'SECONDARY',
    attrs: {
      label: 'Decline',
      buttonType: 'tertiary',
      level: '3',
    },
    handler: (idModel) => console.log('Secondary', idModel),
  },
];

export default defineComponent({
  name: 'CatalogCatalog',
  components: {
    DataTable,
    TabSet,
    TrainingEnrollmentModal,
    AnnularThrobber,
    TrainingRequests,
  },
  setup() {
    const { addSuccess, addInfo } = useNotifications();
    const { isOwnerAdmin, currentUserId } = useCurrentUser();
    const { companyId } = useCurrentCompany();
    const { isBambeeLite } = usePlanAccess(true);
    const { toggleModal } = usePlanUpgrade(true);

    const { availableBundles, availableCourses, isReady } =
      useTrainingCatalog();
    const router = useRouter();
    const instance = getCurrentInstance();
    const screen = instance?.proxy.$screen;

    const cardCount = computed(() => getColCount(screen));
    const { hydrateFromLocalStorage, state: defaultView } = useState(
      { view: TableViewType.COMFORTABLE },
      'trainingCatalogViewType'
    );

    const { pendingRecommendations } = useTrainingRecommendations();

    hydrateFromLocalStorage();

    const showPurchaseModal = ref(false);
    const purchaseModalInfo = ref({} as ModalInfo);
    const handleEnrollClick = (
      isBundle,
      content,
      selectedIds: string[] = []
    ) => {
      const { isBambeeLite } = usePlanAccess();
      const { togglePlanAction } = useBambeeUpsell();

      if (isBambeeLite.value) {
        togglePlanAction(true, undefined, 'training');
      } else {
        showPurchaseModal.value = true;
        purchaseModalInfo.value = {
          bundleId: isBundle ? content.id : '',
          topicId: isBundle ? '' : content.id,
          title: content.name,
          selectedIds,
        };

        bam.track('course_catalog_opened_purchase_modal', {
          content: purchaseModalInfo.value,
        });
      }
    };

    const {
      requests,
      rejectRequest,
      approveRequest,
      refreshCompanyRequests,
      isLoading: isLoadingERRequests,
    } = useEmployerTrainingRequests();

    const {
      myRequests,
      makeRequest,
      cancelRequest,
      refreshUserRequests,
      isLoading: isLoadingEERequests,
    } = useEmployeeTrainingRequests();

    const isLoadingRequests = computed(() =>
      isOwnerAdmin.value ? isLoadingERRequests.value : isLoadingEERequests.value
    );

    const handleRequest = (isBundle, content) => {
      const { onDone } = makeRequest({
        ...(isBundle ? { bundleId: content.id } : { topicId: content.id }),
        userId: currentUserId.value,
        companyId: companyId.value,
      });

      onDone(() => {
        addSuccess(`Request sent for ${content.name}`);
        refreshUserRequests(true);
      });
    };

    const goTo = (to: string) => {
      router.push(to);
    };

    const tableHeader = computed(() => getContentHeader());
    const courseRows = computed(() =>
      availableCourses.value
        // Format for data table
        .map((i) =>
          mapContentToRow(
            i,
            myRequests.value,
            pendingRecommendations.value,
            isOwnerAdmin.value,
            handleEnrollClick,
            handleRequest,
            goTo,
            isBambeeLite.value,
            toggleModal
          )
        )
        // Show recommended courses first
        .sort((a) => (a.recommended ? -1 : 1))
    );

    const bundleRows = computed(() =>
      availableBundles.value
        // Format for data table
        .map((i) =>
          mapContentToRow(
            i,
            myRequests.value,
            pendingRecommendations.value,
            isOwnerAdmin.value,
            handleEnrollClick,
            handleRequest,
            goTo,
            isBambeeLite.value,
            toggleModal
          )
        )
        // Show recommended courses first
        .sort((a) => (a.recommended ? -1 : 1))
    );

    const onRequestEnrollmentComplete = ref();
    // ER Approvals
    const approve = (idsToApprove: string[], isBundle, content) => {
      if (idsToApprove.length === 1) {
        handleEnrollClick(isBundle, content, [content.userId]);
      } else {
        // TODO trigger new bulk purchase modal
        // Will not be released in MVP
      }

      onRequestEnrollmentComplete.value = () => {
        // Only supporting approving single requests in MVP
        const { onDone } = approveRequest(idsToApprove[0]);

        onDone(() => {
          addSuccess(`Request approved for ${content.name}`);
          refreshCompanyRequests();
        });
      };
    };

    // ER Rejections
    const reject = (idsToReject: string[], contentName: string) => {
      // Only supporting rejecting single requests in MVP
      const { onDone } = rejectRequest(idsToReject[0]);

      onDone(() => {
        addInfo(`Request rejected for ${contentName}`);
        refreshCompanyRequests();
      });
    };

    // EE Cancellations
    const cancel = (idsToCancel: string[], contentName: string) => {
      // Only supporting rejecting single requests in MVP
      const { onDone } = cancelRequest({ requestId: idsToCancel[0] });

      onDone(() => {
        addInfo(`Request cancelled for ${contentName}`);
        refreshUserRequests();
      });
    };

    // Request table data
    const requestTableHeader = computed(() =>
      getRequestHeader(isOwnerAdmin.value)
    );

    const requestTableRows = computed(() => {
      const userRequests = isOwnerAdmin.value
        ? requests.value.filter((r) => r.status === RequestStatus.Pending)
        : myRequests.value.filter((r) =>
            [
              RequestStatus.Pending,
              RequestStatus.Approved,
              RequestStatus.Rejected,
            ].includes(r.status)
          );

      const mapped = mapRequestsToTrainingItem(
        availableCourses.value,
        userRequests
      );

      return mapped.map((i) =>
        mapRequestToRow(i, isOwnerAdmin.value, approve, reject, cancel)
      );
    });

    const handleEnrollmentCompleted = () => {
      bam.track('course_catalog_purchased', {
        content: purchaseModalInfo.value,
      });

      router.push(
        `/training/${
          purchaseModalInfo.value?.topicId || purchaseModalInfo.value?.bundleId
        }`
      );
    };

    const handleEnrollment = () => {
      (purchaseModalInfo.value.selectedIds.length
        ? onRequestEnrollmentComplete.value
        : handleEnrollmentCompleted)?.();
    };

    const reportTabChange = (tab) => {
      bam.track('course_catalog_tab_change', {
        tab,
      });
    };

    const handleViewChange = (view) => {
      defaultView.view = view;
    };

    const handleTopicRowClick = (topic) => {
      router.push(
        `/${isOwnerAdmin.value ? 'training' : 'my-training'}/catalog/${
          topic.id
        }`
      );
    };

    bam.track('course_catalog_viewed');

    return {
      tableHeader,
      filters,
      courseRows,
      bundleRows,
      tabOptions,
      dataTableDefaults,
      showPurchaseModal,
      purchaseModalInfo,
      handleEnrollment,
      isReady,
      requestTableHeader,
      requestTableRows,
      isOwnerAdmin,
      requestActions,
      CatalogCard,
      cardCount,
      reportTabChange,
      defaultView,
      handleViewChange,
      handleTopicRowClick,
      isLoadingRequests,
    };
  },
});
