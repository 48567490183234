export enum HrGuidanceEvents {
  ANNUAL_PLAN_REQUESTED = 'hr-guidance-annual-plan-requested',

  START = 'hr-guidance-start',
  FINALIZE = 'hr-guidance-finalize',

  DELEGATE_TASK_CLICKED = 'hr-guidance-delegate-task-clicked',
  DELEGATE_TASK_SUCCESS = 'hr-guidance-delegate-task-success',
  DELEGATE_TASK_ERROR = 'hr-guidance-delegate-task-error',
  SELF_SERVE_PLAN_UPGRADE_MODAL_SHOWN = 'self-serve-plan-upgrade-modal-shown',
  SELF_SERVE_PLAN_UPGRADE_SUCCESS = 'self-serve-plan-upgrade-success',
  SELF_SERVE_PLAN_UPGRADE_ERROR = 'self-serve-plan-upgrade-error',

  SCHEDULE_CALL_CLICKED = 'hr-guidance-schedule-call-clicked',

  // LITE specific events
  BLACKLISTED_OPTION_SELECTED = 'hr-guidance-blacklisted-option-selected',
  HANDLE_THIS_FOR_ME_PAYWALL_SHOWN = 'hr-guidance-handle-this-for-me-paywall-shown',
  UNLOCK_ACTION_PLAN_CLICKED = 'hr-guidance-unlock-action-plan-clicked',
}

export default HrGuidanceEvents;
