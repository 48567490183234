export const TASK_CENTER = Object.freeze({
  path: '/task-center',
  label: 'Task Center',
});

export const DASHBOARD = Object.freeze({
  path: '/dashboard',
  label: 'Dashboard',
});

export const BAMBEE_PLUS = Object.freeze({
  path: '/payroll-activation',
  label: 'Bambee Plus',
});

export const ABOUT_BAMBEE = Object.freeze({
  path: '/about-bambee/welcome',
  label: 'About Bambee',
});

export const HR_GUIDANCE = Object.freeze({
  path: '/guidance',
  label: 'HR Guidance',
});

export const CABINET = '/cabinet/active';

export const BILLING = '/settings#plans';

export const MY_TEAM = '/my-team';

export const DefaultLandingRoutes = Object.freeze({
  USER: DASHBOARD,
  EMPLOYEE: DASHBOARD,
  LEAD: ABOUT_BAMBEE,
});

export default DefaultLandingRoutes;
