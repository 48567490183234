








import { defineComponent, useRouter } from '@nuxtjs/composition-api';
import { HrGuidanceInfoModalEvents } from '@/modules/AI/constants/events';
import bam from '@/lib/bam';
import AIInfoModal from '@/components/AIInfoModal.vue';
import useAIGuidanceDetailsModal from '@/modules/AI/hooks/useAIGuidanceDetailsModal';

export default defineComponent({
  name: 'GuidanceInfoModal',
  components: {
    AIInfoModal,
  },
  setup() {
    const router = useRouter();
    const { currentCopy, closeGuidanceDetailsInfoModal } =
      useAIGuidanceDetailsModal();

    const handlePrimary = () => {};

    const handleSecondary = () => {
      bam.trackEvent(HrGuidanceInfoModalEvents.LEARN_MORE);

      closeGuidanceDetailsInfoModal();
      router.push('/hr-intelligence/hr-guidance');
    };

    return {
      currentCopy,
      handlePrimary,
      handleSecondary,
      closeGuidanceDetailsInfoModal,
    };
  },
});
