export interface Activation {
  companyId: string;
  createdAt: string;
  key: string;
  updatedAt: string;
  updatedBy: string;
  enabled: boolean;
}

export interface SetActivationInput {
  companyId: string;
  key: string;
  enabled: boolean;
}

export const setActivation = async (
  axios: any,
  input: Partial<SetActivationInput>
): Promise<any> => {
  try {
    const res = await axios.post(`/core/activations`, input);

    console.log('res', res);

    return res;
  } catch (error) {
    console.log('err', error);

    throw error;
  }
};

export const getActivations = async (axios: any): Promise<Activation[]> => {
  try {
    const res = await axios.get(`/core/activations`);

    console.log('res', res);

    return res.data;
  } catch (error) {
    console.log('err', error);

    throw error;
  }
};

export const toggleActivation = async (
  axios: any,
  input: { key: string; enabled: boolean }
): Promise<any> => {
  try {
    const res = await axios.post(`/core/activations${input.key}/toggle`, {
      enabled: input.enabled,
    });

    console.log('res', res);

    return res;
  } catch (error) {
    console.log('err', error);

    throw error;
  }
};

export const bulkActivation = async (
  axios: any,
  input: { keys: string[]; enabled: boolean }
): Promise<any> => {
  try {
    const res = await axios.post(`/core/activations/toggle/bulk`, {
      keys: input.keys,
      enabled: input.enabled,
    });

    console.log('res', res);

    return res;
  } catch (error) {
    console.log('err', error);

    throw error;
  }
};
