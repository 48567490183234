











import { defineComponent, useRouter } from '@nuxtjs/composition-api';
import useAIGuidanceModal from '@/modules/AI/hooks/useAIGuidanceModal';
import { HrGuidanceInfoModalEvents } from '@/modules/AI/constants/events';
import bam from '@/lib/bam';
import AIInfoModal from '@/components/AIInfoModal.vue';

export default defineComponent({
  name: 'GuidanceInfoModal',
  components: {
    AIInfoModal,
  },
  setup() {
    const router = useRouter();
    const {
      currentCopy,
      totalPanelCount,
      currentProgress,
      closeGuidanceInfoModal,
    } = useAIGuidanceModal();

    const handlePrimary = () => {};

    const handleSecondary = () => {
      bam.trackEvent(HrGuidanceInfoModalEvents.LEARN_MORE);

      closeGuidanceInfoModal();
      router.push('/hr-intelligence/hr-guidance');
    };

    return {
      currentCopy,
      handlePrimary,
      handleSecondary,
      closeGuidanceInfoModal,
      totalPanelCount,
      currentProgress,
    };
  },
});
