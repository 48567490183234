<template>
  <div>
    <ContentBanner
      :entry-id="ContentfulBannerEntryIDs.ER_PAYROLL_OVERVIEW"
      :ld-flag-id="FeatureFlags.CONTENT_BANNERS.ER_PAYROLL_OVERVIEW"
      class="mt-6"
    />
    <AnnularThrobber v-if="isLoading" class="mx-auto my-32" />
    <div v-else>
      <WelcomeVideoModal
        v-if="showIntroVideo"
        header="Welcome to Bambee Payroll"
        video-url="https://player.vimeo.com/video/868458073?h=d765cfcac4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        submit-label="Go to Payroll"
        cancel-label="Watch Later"
        @submit="handleVideoSubmit"
        @cancel="toggleIntroVideo"
        @close="handleVideoSubmit"
      >
        <template #post-video>
          <TypeBody class="text-base-700" variant="small">
            Take a few minutes and watch an expert walkthrough on Bambee
            Payroll.
          </TypeBody>
        </template>
      </WelcomeVideoModal>
      <PayrollDelinquentBanner />
      <BaseBanner
        v-if="missingStaffInfoBanner"
        class="mt-4"
        :show-close="false"
        variant="warning"
        :message="missingStaffInfoBanner.message"
        :description="missingStaffInfoBanner.description"
      />
      <BaseBanner
        v-if="missingTaxInfo"
        class="mt-4"
        :show-close="false"
        variant="warning"
        message="Tax Compliance Alert"
      >
        <template #description>
          <div class="mb-4">
            We're missing important tax setup information that may put you at
            risk. Please submit your tax info as soon as you have it available
            to avoid any negative tax issues.
          </div>
          <BaseButton
            size="small"
            variant="secondary"
            @click="sendToOnboarding"
          >
            Submit Now
          </BaseButton>
        </template>
      </BaseBanner>
      <BaseBanner
        v-if="isPayrollLegacy && contentIsReady && isPayrollTierFeatureEnabled"
        class="mt-4"
        :show-close="false"
      >
        <div class="flex justify-start flex-col space-y-2">
          <ContentfulRichText :content="contentfulContent.text" />
          <div>
            <BaseButton
              size="small"
              variant="secondary"
              @click="handleLearnPayrollChanges"
            >
              Learn More
            </BaseButton>
          </div>
        </div>
      </BaseBanner>
      <BaseBanner
        v-if="showDeletedDraftBanner"
        class="mt-4"
        :show-close="true"
        variant="error"
        message="The payroll draft has been deleted"
        @close="handleDeletedDraftBannerClose"
      >
        <template #description>
          If you did not make this request, please
          <nuxt-link
            class="underline text-black"
            to="/requests/pay-benefits-payroll"
          >
            submit an HR Request
          </nuxt-link>
          .
        </template>
      </BaseBanner>
      <BaseBanner
        v-if="companyHasBlockedStatus"
        class="mt-4"
        variant="warning"
        message="Company Setup Info Missing"
        :show-close="false"
      >
        <template #description>
          <div>
            You can get started on a payroll, but to ensure you remain
            compliant, you won't be able to submit until the following steps are
            complete:
          </div>
          <ul class="list-disc pl-4">
            <li class="pt-1">
              Connect your company's bank account to pay workers
            </li>
            <li class="pt-1">
              Set up required company identifiers and tax setup
            </li>
            <li class="pt-1">
              Review and sign forms to grant authorization to process payroll
            </li>
          </ul>
        </template>
      </BaseBanner>

      <BaseBanner
        v-if="showBenefitsBanner"
        class="mt-4"
        :show-close="false"
        :variant="companyBenefitStatusInfo.variant"
        :message="companyBenefitStatusInfo.title"
      >
        <template #description>
          <div>
            {{ companyBenefitStatusInfo.description }}
          </div>
        </template>
      </BaseBanner>

      <ProgressTileList
        id="payroll-list"
        class="mt-6"
        :company="company"
        :payrolls="payrolls"
        :is-loading="false"
        :disabled="isPayrollLocked"
        empty-msg="No current payrolls"
      />

      <PayrollCard
        v-if="paydayOptions && paydayOptions.length"
        id="payroll-offcycle"
        full-bleed
        class="p-4 mt-6 create-payroll-card"
      >
        <DateInput
          v-model="periodStart"
          label="Pay Period Start Date"
          class="max-w-2xl mb-4"
          :error="periodStartErr"
        />

        <DateInput
          v-model="periodEnd"
          label="Pay Period End Date"
          class="max-w-2xl mb-4"
          :error="periodEndErr"
        />

        <SelectInput
          v-model="payday"
          label="Pay Date"
          class="max-w-2xl mb-4"
          :options="paydayOptions"
          :show-reset="false"
        />

        <div class="flex justify-between items-center max-w-2xl mb-6">
          <TypeBody variant="link-x-small-tight" tag="div" class="font-bold">
            Payroll Deadline
          </TypeBody>
          <TypeBody
            v-if="payrollDeadline"
            variant="link-x-small-tight"
            tag="div"
            class="font-bold"
          >
            {{ payrollDeadline.formatted }}
          </TypeBody>
          <TypeBody
            v-else
            variant="link-x-small-tight"
            tag="div"
            class="font-bold"
          >
            Select a desired Pay Day
          </TypeBody>
        </div>

        <BaseBanner
          v-if="impactedByWeekendOrHoliday"
          class="max-w-2xl mb-6"
          variant="explainer"
          message="Payroll deadline adjusted because of weekend or holiday"
          :show-close="false"
        />

        <BaseBanner
          v-if="isMissedDirectDepositWindow"
          class="max-w-2xl mb-6"
          variant="warning"
          message="Workers can only be paid with manual checks"
          :description="missedDirectDepositMessage"
          :show-close="false"
        />

        <div class="flex items-center schedule-payroll-actions">
          <BaseButton
            size="large"
            variant="inverted"
            :disabled="
              !paydayFormIsValid || isPendingCreatePayroll || isPayrollLocked
            "
            @click="toggleOffCycleWizard"
          >
            Run Off-Cycle Payroll
          </BaseButton>
        </div>
      </PayrollCard>

      <OffCycleWizard
        v-if="isStartingOffCycle"
        @close="toggleOffCycleWizard"
        @expert-withholding-click="createOffcyclePayroll"
      />

      <BaseBanner
        message="Take a tour of Bambee Payroll"
        variant="explainer"
        class="mt-6"
        :show-close="false"
      >
        <template #actions>
          <BaseButton size="small" @click="toggleIntroVideo">
            Watch
          </BaseButton>
        </template>
      </BaseBanner>

      <LoadingModal v-if="isPendingCreatePayroll">
        <template #header> Loading workers &amp; earnings </template>
        Please do not leave this page or close your browser, while we load your
        payroll. You will be redirected automatically once complete.
      </LoadingModal>
    </div>
  </div>
</template>

<script>
import {
  ref,
  computed,
  useRoute,
  useRouter,
  watch,
  onMounted,
} from '@nuxtjs/composition-api';
import {
  AnnularThrobber,
  DateInput,
  BaseBanner,
  BaseButton,
  SelectInput,
  TypeBody,
} from '@bambeehr/pollen';
import {
  isBefore,
  isAfter,
  isValid,
  isWeekend,
  startOfToday,
  startOfDay,
  format,
  isPast,
  subWeeks,
} from 'date-fns';
import LoadingModal from '@/modules/payroll/components/LoadingModal/LoadingModal.vue';
import useNotifications from '@bambeehr/use-notifications';
import ProgressTileList from '@/modules/payroll/components/ProgressTile/ProgressTileList';
import WelcomeVideoModal from '@/components/WelcomeVideoModal';
import useCommunicationState from '@/hooks/useCommunicationState';
import {
  PayrollTypes,
  PayrollStatus,
} from '@/modules/payroll/constants/payroll';
import { formatDate, formatTime } from '@/utils/date';
import PayrollCard from '@/modules/payroll/components/PayrollCard/PayrollCard';
import OffCycleWizard from '@/modules/payroll/components/OffCycleWizard/OffCycleWizard.vue';
import { getDeadlineStatuses } from '@/modules/payroll/utils/getDeadlineStatuses';
import { getMissedDirectDepositMessage } from '@/modules/payroll/utils/deadlineMessages';
import { PayrollTierSegmentEvents } from '@/components/ProductSelector/const/segmentEvents';

import useCurrentCompany from '@/hooks/useCurrentCompany';
import useCurrentUser from '@/hooks/useCurrentUser';
import { CheckOnboardingStatus } from '@/modules/payroll/constants/company';
import FeatureFlags from '@/constants/FeatureFlags';
import {
  OrderByDirection,
  useListPayrollQuery,
  useCreatePayrollMutation,
  useGetCompanyOnboardingStatusQuery,
  useGenerateCompanyOnboardingLinkMutation,
  BenefitOnboardStatus,
  CheckCompanyOnboardingSteps,
} from '@/gql/generated';
import ContentfulRichText from '@/components/contentful/ContentfulRichText';

import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';
import { LIST_PAYROLL } from '@/gql/queries/payroll_queries.gql';
import EmployerOnboarding from '@/modules/Benefits/const/employerOnboarding';
import PayrollDelinquentBanner from '@/components/banners/PayrollDelinquentBanner.vue';
import useDelinquency from '@/modules/Billing/hooks/useDelinquency';
import ContentBanner from '@/components/ContentBanner';
import {
  ContentfulBannerEntryIDs,
  ContentfulEntryIDs,
} from '@/lib/globals/ContentfulEntryIDs';
import usePlanAccess from '@/hooks/usePlanAccess/usePlanAccess';
import useBambeeUpsell from '@/hooks/useBambeeUpsell/useBambeeUpsell';
import useContentful from '@/hooks/useContentful/useContentful';
import useCompanyBillingInfo from '@/hooks/useCompanyBillingInfo';
import usePayrollRootModal from '@/modules/payroll/components/PayrollRootModal/usePayrollRootModal';
import {
  payrollPlanSelectJourney,
  payrollMigrationExplainer,
} from '@/modules/payroll/components/PayrollRootModal/constants/componentConfig';
import bam from '@/lib/bam';

export default {
  name: 'PayrollOverview',

  components: {
    PayrollDelinquentBanner,
    AnnularThrobber,
    BaseButton,
    BaseBanner,
    DateInput,
    LoadingModal,
    OffCycleWizard,
    PayrollCard,
    ProgressTileList,
    SelectInput,
    TypeBody,
    ContentBanner,
    WelcomeVideoModal,
    ContentfulRichText,
  },

  props: {
    companyId: {
      type: [String, Number],
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const isDrawerActive = ref(false);
    const isPayrollListEmpty = ref(true);
    const isStartingOffCycle = ref(false);
    const payrollStatus = ref(null);
    const payrollStatusDate = ref(null);
    const showLoadingModal = ref(false);
    const actionType = ref('');
    const approvalDeadlineDate = ref(null);
    const payrollStartDate = ref(null);
    const payrollEndDate = ref(null);
    const periodStart = ref('');
    const periodEnd = ref('');
    const isValidating = ref(false);
    const payday = ref(null);
    const paydayOptions = ref([]);
    const { isPayrollLegacy, isPayrollTierFeatureEnabled, payrollTrackEvent } =
      useCompanyBillingInfo();
    const { loadJourney, state, hasSeenMigrationExplainer } =
      usePayrollRootModal();

    watch(
      isPayrollLegacy,
      () => {
        if (!isPayrollLegacy.value || !isPayrollTierFeatureEnabled.value) {
          return;
        }
        if (!hasSeenMigrationExplainer.value) {
          loadJourney(payrollMigrationExplainer);
        } else {
          loadJourney(payrollPlanSelectJourney);
        }
        payrollTrackEvent(
          PayrollTierSegmentEvents.PAYROLL_TIER_PLANS_MODAL_SHOWN
        );
      },
      {
        immediate: true,
      }
    );

    // Prepare to fetch Contentful content
    const contentIsReady = ref(false);
    const { fetchContent } = useContentful();
    const contentfulContent = ref({});

    const { currentUser } = useCurrentUser();
    const { company } = useCurrentCompany();
    const { isPayrollLocked } = useDelinquency();

    const { mutate: getOnboardingLink, onDone: onOnboardingLinkClick } =
      useApolloMutation(useGenerateCompanyOnboardingLinkMutation);

    const paydayErrorMsg = computed(() => {
      return isWeekend(new Date(payday.value))
        ? 'OOOPS! Payday cannot be on a weekend'
        : '';
    });

    const paydayFormIsValid = computed(() => {
      const dateStrs = [periodStart.value, periodEnd.value, payday.value];
      const noBlanks = dateStrs.every((dateStr) => !!dateStr);
      const allValidDates = dateStrs.every((dateStr) =>
        isValid(new Date(dateStr))
      );

      return noBlanks && allValidDates && !paydayErrorMsg.value;
    });

    const { addFatal, addError } = useNotifications();

    const periodStartErr = computed(() => {
      if (isValidating.value && !isPast(new Date(periodStart.value))) {
        return 'Must be a date before today';
      }

      return '';
    });

    const periodEndErr = computed(() => {
      const isAfterStart = isBefore(
        startOfDay(new Date(periodStart.value)),
        startOfDay(new Date(periodEnd.value))
      );

      if (isValidating.value) {
        if (!isAfterStart) {
          return 'Must fall after Pay Period Start Date';
        }
      }

      return '';
    });

    const selectedPaydayOption = computed(() => {
      return props.company?.paydays?.find((d) => d.payday === payday.value);
    });

    const isMissedDirectDepositWindow = computed(() =>
      selectedPaydayOption.value
        ? getDeadlineStatuses({
            status: PayrollStatus.INITIAL,
            approvalDeadline: selectedPaydayOption.value.approvalDeadline,
            payday: selectedPaydayOption.value.payday,
          }).isMissedDirectDepositWindow
        : false
    );

    const payrollDeadline = computed(() => {
      if (selectedPaydayOption.value) {
        const { approvalDeadline, payday: paydayDeadline } =
          selectedPaydayOption.value;

        const dueDate = isMissedDirectDepositWindow.value
          ? paydayDeadline
          : approvalDeadline;

        // If the user is past the approvalDate we should use the payday as the approval date for manual checks
        return {
          raw: dueDate,
          formatted: `${formatTime(dueDate)} ${formatDate(dueDate)}`,
        };
      }

      return null;
    });

    const missedDirectDepositMessage = getMissedDirectDepositMessage(
      props.company?.processingPeriod
    );

    const impactedByWeekendOrHoliday = computed(
      () => selectedPaydayOption.value?.impactedByHoliday
    );

    const firstDirectDepositPayday = props.company?.paydays?.find(
      (paydayItem) =>
        // We're using the below statuses helper to find the first pay day that doesn't require a manual check
        !getDeadlineStatuses({
          status: PayrollStatus.INITIAL,
          approvalDeadline: paydayItem.approvalDeadline,
          payday: paydayItem.payday,
        })?.isMissedDirectDepositWindow
    );

    paydayOptions.value = props.company?.paydays?.map((item) => {
      return {
        label: formatDate(item.payday),
        value: item.payday,
      };
    });

    payday.value = firstDirectDepositPayday?.payday;
    const {
      result: payrollListResult,
      loading: isFetching,
      onError: onPayrollListError,
    } = useApolloQuery(useListPayrollQuery, {
      companyId: props.companyId,
      orderBy: {
        key: 'approvalDeadline',
        direction: OrderByDirection.Desc,
      },
      advancedWhere: {
        NOT: {
          status: {
            equals: PayrollStatus.PAID,
          },
        },
      },
    });

    const { result: companyOnboarding } = useApolloQuery(
      useGetCompanyOnboardingStatusQuery,
      {
        id: props.companyId,
      }
    );

    onPayrollListError((errors) => {
      const err = [...errors.responseErrors, errors.networkError];
      addFatal(err[0].message);
    });

    const payrolls = computed(() => {
      const list = payrollListResult.value?.listPayroll?.results;

      if (!list) {
        return [];
      }

      // Only list payrolls that are unpaid or have only been paid for 1 day
      return list.filter((payroll) => {
        const isComplete =
          payroll.status.toUpperCase() === PayrollStatus.PAID.toUpperCase();

        // If the approval deadline was over a week ago, we shouldn't show the "missed payroll tile anymore";
        const isWeekPastDeadline = isBefore(
          new Date(payroll.approvalDeadline),
          subWeeks(new Date(), 1)
        );

        // Sometimes the list cache includes a just deleted payroll due to slow connections
        // This ensures it's explicitly removed based on query params provided
        const justDeleted =
          parseInt(route.value.query?.deletedId, 10) === payroll.id;

        return (
          ((!isComplete && !isWeekPastDeadline) ||
            isAfter(new Date(payroll.statusChangedAt), startOfToday())) &&
          !justDeleted
        );
      });
    });

    const companyHasBlockedStatus = computed(
      () =>
        props.company?.checkOnboarding?.status ===
        CheckOnboardingStatus.BLOCKING
    );

    const benefitStatus = props.company.benefitOnboarding?.status;

    const companyBenefitStatusInfo = computed(() => {
      const thisCompany = companyOnboarding.value?.getCompany;

      if (!thisCompany) {
        return null;
      }

      const variant = 'explainer';
      const { employees = [] } = thisCompany;
      const enrolled = employees.filter(
        (e) => e.benefitOnboarding?.status === BenefitOnboardStatus.Enrolled
      );
      const enrolling = employees.filter(
        (e) => e.benefitOnboard?.status === BenefitOnboardStatus.Enrolling
      );

      const enrolledText = `You will be charged for ${enrolled.length} worker${
        enrolled.length !== 1 ? 's who have' : ' who has'
      } enrolled in benefits.`;
      const enrollingText = `${enrolling.length} worker${
        enrolling.length !== 1 ? 's have' : ' has'
      } not responded.`;

      const effectiveDate = thisCompany.benefitOnboarding?.targetEffectiveStart;

      const formattedEffectiveDate = effectiveDate
        ? formatDate(new Date(effectiveDate))
        : '';

      const benefitsHaveStarted = isPast(new Date(effectiveDate));

      const isBor = [
        EmployerOnboarding.BOR_COMPLETED,
        EmployerOnboarding.BOR_SUBMITTED,
      ].includes(benefitStatus);

      switch (benefitStatus) {
        case EmployerOnboarding.PENDING_EMPLOYEE_ENROLLMENT:
          return {
            title: `Some staff members have not responded to benefits enrollment`,
            description: enrollingText,
            variant: 'warning',
          };

        case EmployerOnboarding.FINISHED_EMPLOYEE_ENROLLMENT:
          return {
            title: `Your benefits application is processing`,
            description: `You can run payroll, but no benefits will be applied to your payroll.`,
            variant,
          };

        case EmployerOnboarding.SUBMITTED:
        case EmployerOnboarding.BOR_SUBMITTED:
          if (!formattedEffectiveDate) {
            return null;
          }

          return {
            title: `Your benefits ${
              isBor ? 'transfer request' : 'application'
            } was submitted`,
            description: `Your benefits ${
              isBor ? 'broker of record transfer request' : 'application'
            }  was successfully submitted and is expected to be effective on ${formattedEffectiveDate}.`,
            variant,
          };

        case EmployerOnboarding.COMPLETED:
        case EmployerOnboarding.BOR_COMPLETED:
          if (!formattedEffectiveDate || benefitsHaveStarted) {
            return null;
          }

          return {
            title: `Your ${
              isBor ? 'transferred' : ''
            } benefits coverage will start on the ${formattedEffectiveDate}`,
            description: enrolledText,
            variant: 'confirmation',
          };

        default:
          return null;
      }
    });
    const showBenefitsBanner = computed(() => !!companyBenefitStatusInfo.value);
    const companyHasPendingBenefitsStatus = computed(
      () => benefitStatus === EmployerOnboarding.PENDING_EMPLOYEE_ENROLLMENT
    );

    const hasMissingSSNs = computed(() =>
      payrolls.value.some((p) => p.hasEmployeesWithMissingSSN)
    );

    const missingTaxInfo = computed(() =>
      props.company?.checkOnboarding?.remainingSteps?.includes(
        CheckCompanyOnboardingSteps.SetupParameters
      )
    );

    const missingStaffInfoBanner = computed(() => {
      if (companyHasBlockedStatus.value && hasMissingSSNs.value) {
        return {
          message: 'Missing required worker information',
          description:
            'You can get started on a payroll, but to ensure you remain compliant you won’t be able to submit until all workers have entered in their social security number.',
        };
      }

      if (hasMissingSSNs.value) {
        return {
          message: 'Missing required worker information',
          description:
            'You can get started on a payroll, but to ensure you remain compliant you won’t be able to pay any workers that are missing a social security number.',
        };
      }

      return null;
    });

    const createPayrollError = ref(null);
    const isPendingCreatePayroll = ref(false);

    const preventCreatePayroll = computed(
      () =>
        isPendingCreatePayroll.value ||
        periodStartErr.value ||
        periodEndErr.value
    );

    const { mutate: createPayroll, onDone: onCreatePayroll } =
      useApolloMutation(useCreatePayrollMutation, {
        pending: isPendingCreatePayroll,
      });

    const createNewPayroll = (payrollType, offCycleOptions = null) => {
      isValidating.value = true;
      if (preventCreatePayroll.value) {
        return;
      }

      const payload = {
        companyId: props.companyId,
        payrollType,
        offCycleOptions,
        payday: format(new Date(payday.value), 'yyyy-MM-dd'),
        periodStart: format(new Date(periodStart.value), 'yyyy-MM-dd'),
        periodEnd: format(new Date(periodEnd.value), 'yyyy-MM-dd'),
        approvalDeadline: selectedPaydayOption.value?.approvalDeadline,
      };

      createPayroll(
        {
          data: payload,
        },
        {
          refetchQueries: [
            {
              query: LIST_PAYROLL,
              variables: {
                companyId: props.companyId,
              },
            },
          ],
        }
      );

      onCreatePayroll(({ createPayroll: res }) => {
        const payrollId = res.id;
        if (payrollId) {
          router.push(`/payroll/${payrollId}/edit`);
        }
      });
    };

    const toggleOffCycleWizard = () => {
      const { hasLiteOrLower } = usePlanAccess();
      const { togglePlanAction } = useBambeeUpsell();
      if (hasLiteOrLower.value) {
        togglePlanAction(true, undefined, 'payroll');

        return;
      }

      isValidating.value = true;
      if (preventCreatePayroll.value) {
        return;
      }

      isStartingOffCycle.value = !isStartingOffCycle.value;
    };

    const createOffcyclePayroll = (event) => {
      const offCycleOptions = { ...event };
      toggleOffCycleWizard();
      createNewPayroll(PayrollTypes.OFF_CYCLE, offCycleOptions);
    };

    watch(createPayrollError, (err) => {
      addFatal(err);
    });

    const isLoading = computed(() => {
      return isFetching.value;
    });

    const showDeletedDraftBanner = ref(false);
    const deletedDraftId = ref(null);
    const { deletedDraftRedirect, deletedId } = route.value.query;
    if (deletedDraftRedirect) {
      showDeletedDraftBanner.value = true;
    }
    if (deletedId) {
      deletedDraftId.value = parseInt(deletedId, 10);
    }

    function handleDeletedDraftBannerClose() {
      showDeletedDraftBanner.value = false;
    }

    function goToOnboardingLink(link) {
      window.open(link, '_blank')?.focus();
    }

    const onboardingLink = ref('');

    function sendToOnboarding() {
      if (onboardingLink.value) {
        goToOnboardingLink(onboardingLink.value);
      } else if (currentUser.value) {
        getOnboardingLink({
          data: {
            companyId: company.value.id,
            email: currentUser.value?._auth.email,
            signerName: currentUser.value?.profile.full_name,
            signerTitle: currentUser.value?.profile.role,
            sendEmail: false,
          },
        });
      }
    }

    onOnboardingLinkClick((res) => {
      const link = res.generateCompanyOnboardingLink;
      if (link) {
        onboardingLink.value = link;
        goToOnboardingLink(link);
      }
    });

    const { communicationState, CommunicationName } = useCommunicationState();
    const showIntroVideo = ref(
      route.value.query?.intro &&
        communicationState[CommunicationName.PAYROLL_WELCOME]
    );
    const toggleIntroVideo = () => {
      showIntroVideo.value = !showIntroVideo.value;
    };

    const handleVideoSubmit = () => {
      toggleIntroVideo();
      communicationState[CommunicationName.PAYROLL_WELCOME] = false;
    };

    // Fetch the content when component is mounted
    (async () => {
      const content = await fetchContent(
        ContentfulEntryIDs.PAYROLL_PLAN_CHANGES_BANNER
      );
      contentfulContent.value = content || {};
      contentIsReady.value = true;
    })();

    const handleLearnPayrollChanges = () => {
      payrollTrackEvent(
        PayrollTierSegmentEvents.PAYROLL_TIER_PLANS_BANNER_CLICKED
      );
      state.hasSeenExplainer = false;
      loadJourney(payrollMigrationExplainer);
    };

    return {
      actionType,
      approvalDeadlineDate,
      companyBenefitStatusInfo,
      companyHasBlockedStatus,
      companyHasPendingBenefitsStatus,
      createOffcyclePayroll,
      handleDeletedDraftBannerClose,
      impactedByWeekendOrHoliday,
      isDrawerActive,
      isLoading,
      isMissedDirectDepositWindow,
      isPayrollListEmpty,
      isPayrollLocked,
      isPendingCreatePayroll,
      isStartingOffCycle,
      isValidating,
      missedDirectDepositMessage,
      missingStaffInfoBanner,
      missingTaxInfo,
      payday,
      paydayErrorMsg,
      paydayFormIsValid,
      paydayOptions,
      payrollDeadline,
      payrollEndDate,
      payrolls,
      payrollStartDate,
      payrollStatus,
      payrollStatusDate,
      periodEnd,
      periodEndErr,
      periodStart,
      periodStartErr,
      selectedPaydayOption,
      sendToOnboarding,
      showBenefitsBanner,
      showDeletedDraftBanner,
      showLoadingModal,
      toggleOffCycleWizard,
      ContentfulBannerEntryIDs,
      FeatureFlags,
      toggleIntroVideo,
      handleVideoSubmit,
      showIntroVideo,
      isPayrollLegacy,
      contentfulContent,
      contentIsReady,
      payrollMigrationExplainer,
      loadJourney,
      handleLearnPayrollChanges,
      isPayrollTierFeatureEnabled,
    };
  },
};
</script>
