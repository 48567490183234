import FeatureFlags from '@/constants/FeatureFlags';
import Store from '@/constants/Store';
import launchDarkly from '@bambeehr/vue-launch-darkly';
import useMiddlewareContext from './hooks/useMiddlewareContext';

export default () => {
  const { store, route, redirect } = useMiddlewareContext();

  const featureFlagEnabled =
    launchDarkly.getFlags()[FeatureFlags.ENABLE_HR_GUIDANCE];
  const isCompanyAdmin = store.getters[Store.auth.Getters.IS_COMPANY_ADMIN];

  // employees will continue to see the requests page
  if (!isCompanyAdmin || !featureFlagEnabled) {
    if (route.path.includes('/guidance')) {
      const newPath = route.path.replace('/guidance', '/requests');
      redirect(newPath);
    }

    return;
  }

  // workaround for specific page that kicks off policy creation flow
  if (route.path.includes('/policies-new-policy')) {
    return;
  }

  // whitelist some legacy hr request routes, including terminating contractors
  if (
    route.path.includes('/requests/ending-employment-resignation') ||
    route.path.includes('/requests/ending-employment-firing-termination') ||
    route.path.includes('/requests/detail') ||
    route.path.includes('/requests/pay-benefits-payroll')
  ) {
    return;
  }

  if (route.path.includes('/requests')) {
    const newPath = route.path.replace('/requests', '/guidance');
    redirect(newPath);
  }
};
