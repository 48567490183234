const getBase64File = (file: unknown): Promise<string> =>
  new Promise((resolve, reject) => {
    let fileToProcess: File | Blob;

    // Type guard function to check if object is a File or Blob
    const isFileOrBlob = (value: unknown): value is File | Blob => {
      return value instanceof File || value instanceof Blob;
    };

    // Handle different file input formats
    if (isFileOrBlob(file)) {
      fileToProcess = file;
    } else if (file && typeof file === 'object') {
      const fileObj = file as { file?: unknown; raw?: unknown };
      if (fileObj.file && isFileOrBlob(fileObj.file)) {
        fileToProcess = fileObj.file;
      } else if (fileObj.raw && isFileOrBlob(fileObj.raw)) {
        fileToProcess = fileObj.raw;
      } else {
        reject(new Error('Invalid file object'));

        return;
      }
    } else {
      reject(new Error('Invalid file object'));

      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(fileToProcess);

    reader.onload = () => {
      if (!reader.result || typeof reader.result !== 'string') {
        reject(new Error('Failed to read file'));

        return;
      }

      const base64String = reader.result.split(',')[1];
      if (base64String) {
        resolve(base64String);
      } else {
        reject(new Error('Failed to convert file to base64'));
      }
    };

    reader.onerror = () => {
      reject(new Error('Error reading file'));
    };
  });

export default getBase64File;
