import { getEmployerOnboardingStatus } from '@/gql/services/payroll.service';

let hasStarted;

const hasStartedNewOnboarding = async (userId: string) => {
  if (hasStarted !== undefined) {
    return hasStarted;
  }

  const { data: onboardingForm } = await getEmployerOnboardingStatus(
    {
      userId,
    },
    { fetchPolicy: 'network-only' }
  );

  hasStarted = !!onboardingForm;

  return hasStarted;
};

export const setHasStartedNewOnboarding = (value: boolean) => {
  hasStarted = value;
};

export default hasStartedNewOnboarding;
